(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.dl.ar === region.cr.ar)
	{
		return 'on line ' + region.dl.ar;
	}
	return 'on lines ' + region.dl.ar + ' through ' + region.cr.ar;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cE,
		impl.dD,
		impl.ds,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		cP: func(record.cP),
		dq: record.dq,
		dc: record.dc
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.cP;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.dq;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.dc) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cE,
		impl.dD,
		impl.ds,
		function(sendToApp, initialModel) {
			var view = impl.dF;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cE,
		impl.dD,
		impl.ds,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.a0 && impl.a0(sendToApp)
			var view = impl.dF;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.ci);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.b8) && (_VirtualDom_doc.title = title = doc.b8);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.c4;
	var onUrlRequest = impl.c5;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		a0: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.bL === next.bL
							&& curr.bo === next.bo
							&& curr.bH.a === next.bH.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		cE: function(flags)
		{
			return A3(impl.cE, flags, _Browser_getUrl(), key);
		},
		dF: impl.dF,
		dD: impl.dD,
		ds: impl.ds
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { cA: 'hidden', cl: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { cA: 'mozHidden', cl: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { cA: 'msHidden', cl: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { cA: 'webkitHidden', cl: 'webkitvisibilitychange' }
		: { cA: 'hidden', cl: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bV: _Browser_getScene(),
		dG: {
			dI: _Browser_window.pageXOffset,
			aO: _Browser_window.pageYOffset,
			ap: _Browser_doc.documentElement.clientWidth,
			ah: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ap: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ah: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bV: {
				ap: node.scrollWidth,
				ah: node.scrollHeight
			},
			dG: {
				dI: node.scrollLeft,
				aO: node.scrollTop,
				ap: node.clientWidth,
				ah: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bV: _Browser_getScene(),
			dG: {
				dI: x,
				aO: y,
				ap: _Browser_doc.documentElement.clientWidth,
				ah: _Browser_doc.documentElement.clientHeight
			},
			cq: {
				dI: x + rect.left,
				aO: y + rect.top,
				ap: rect.width,
				ah: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.ct.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.ct.b, xhr)); });
		$elm$core$Maybe$isJust(request.b9) && _Http_track(router, xhr, request.b9.a);

		try {
			xhr.open(request.cQ, request.dE, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.dE));
		}

		_Http_configureRequest(xhr, request);

		request.ci.a && xhr.setRequestHeader('Content-Type', request.ci.a);
		xhr.send(request.ci.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.bl; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.dz.a || 0;
	xhr.responseType = request.ct.d;
	xhr.withCredentials = request.ce;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		dE: xhr.responseURL,
		$7: xhr.status,
		dp: xhr.statusText,
		bl: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			dj: event.loaded,
			bY: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			dd: event.loaded,
			bY: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.cV) { flags += 'm'; }
	if (options.ck) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $author$project$Main$UrlChange = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$UrlRequest = function (a) {
	return {$: 18, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.h) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.k),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.k);
		} else {
			var treeLen = builder.h * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.l) : builder.l;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.h);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.k) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.k);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{l: nodeList, h: (len / $elm$core$Array$branchFactor) | 0, k: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {bi: fragment, bo: host, c9: path, bH: port_, bL: protocol, bM: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Data$Overlay$AddOverlay = function (a) {
	return {$: 0, a: a};
};
var $author$project$Data$Page$Fixed = 0;
var $author$project$Data$Overlay$HttpingOverlay = 0;
var $author$project$Data$BoundedInteger$LowerBound = $elm$core$Basics$identity;
var $author$project$Data$Page$Mobile = 1;
var $author$project$Data$BoundedInteger$UpperBound = $elm$core$Basics$identity;
var $author$project$Data$BoundedInteger$Value = $elm$core$Basics$identity;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Data$BoundedInteger$BoundedInteger = $elm$core$Basics$identity;
var $author$project$Data$BoundedInteger$build = F3(
	function (_v0, _v1, _v2) {
		var lowerBound = _v0;
		var upperBound = _v1;
		var value = _v2;
		return {aW: lowerBound, a5: upperBound, aN: value};
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Sensor$Sensor = F4(
	function (name, parameter, unit, sessionCount) {
		return {cW: name, c8: parameter, bX: sessionCount, dB: unit};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$map4 = _Json_map4;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Sensor$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Sensor$Sensor,
	A2($elm$json$Json$Decode$field, 'sensor_name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'measurement_type', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'unit_symbol', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'session_count', $elm$json$Json$Decode$int));
var $author$project$Popup$None = 3;
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$Data$Status$Active = 0;
var $author$project$Data$Status$default = 0;
var $author$project$Data$Theme$Default = 0;
var $author$project$Data$Theme$default = 0;
var $author$project$Data$EmailForm$defaultEmailForm = {af: _List_Nil, ag: $elm$core$Maybe$Nothing, aN: ''};
var $author$project$TimeRange$TimeRange = $elm$core$Basics$identity;
var $author$project$TimeRange$defaultTimeRange = {aK: 1, aL: 1};
var $author$project$Data$Path$Path = $elm$core$Basics$identity;
var $author$project$Data$Path$empty = '';
var $author$project$LabelsInput$Model = $elm$core$Basics$identity;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$LabelsInput$fromList = function (labels) {
	return {
		ay: '',
		ai: $elm$core$Set$fromList(labels)
	};
};
var $author$project$LabelsInput$empty = $author$project$LabelsInput$fromList(_List_Nil);
var $author$project$Data$Theme$Icons = $elm$core$Basics$identity;
var $author$project$Data$Theme$emptyIcons = {aP: $author$project$Data$Path$empty, z: $author$project$Data$Path$empty};
var $author$project$Data$Overlay$Overlays = $elm$core$Basics$identity;
var $author$project$Data$Overlay$none = _List_Nil;
var $author$project$Main$defaultModel = {
	O: false,
	y: A3($author$project$Data$BoundedInteger$build, 1, 40, 20),
	ad: 0,
	u: $author$project$Data$EmailForm$defaultEmailForm,
	aB: 0,
	T: $author$project$Data$Path$empty,
	s: $krisajenkins$remotedata$RemoteData$NotAsked,
	I: false,
	V: false,
	br: false,
	aD: false,
	J: false,
	G: false,
	W: $elm$core$Maybe$Nothing,
	K: $author$project$Data$Path$empty,
	bx: '',
	Y: $author$project$Data$Path$empty,
	d: $author$project$Data$Overlay$none,
	m: 1,
	v: 3,
	F: $author$project$LabelsInput$empty,
	_: $author$project$Data$Path$empty,
	M: $author$project$Data$Path$empty,
	R: 0,
	i: 'Particulate Matter-airbeam-pm2.5 (µg/m³)',
	g: $krisajenkins$remotedata$RemoteData$NotAsked,
	n: _List_Nil,
	am: _List_Nil,
	aa: $author$project$Data$Status$default,
	H: $author$project$LabelsInput$empty,
	E: $author$project$Data$Theme$default,
	aJ: $author$project$Data$Theme$emptyIcons,
	N: $author$project$TimeRange$defaultTimeRange,
	ax: false,
	ac: A3($author$project$Data$BoundedInteger$build, 3, 22, 5)
};
var $author$project$Main$UpdateHeatMapThresholds = function (a) {
	return {$: 29, a: a};
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Data$HeatMapThresholds$maybeIntDecoder = function (m) {
	if (m.$ === 1) {
		return $elm$json$Json$Decode$fail('threshold must be an Int');
	} else {
		var i = m.a;
		return $elm$json$Json$Decode$succeed(i);
	}
};
var $author$project$Data$HeatMapThresholds$intAsStringDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Data$HeatMapThresholds$maybeIntDecoder,
	A2($elm$json$Json$Decode$map, $elm$core$String$toInt, $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Data$HeatMapThresholds$decoder = function (yellow) {
	return A6(
		$elm$json$Json$Decode$map5,
		yellow,
		A2($elm$json$Json$Decode$index, 0, $author$project$Data$HeatMapThresholds$intAsStringDecoder),
		A2($elm$json$Json$Decode$index, 1, $author$project$Data$HeatMapThresholds$intAsStringDecoder),
		A2($elm$json$Json$Decode$index, 2, $author$project$Data$HeatMapThresholds$intAsStringDecoder),
		A2($elm$json$Json$Decode$index, 3, $author$project$Data$HeatMapThresholds$intAsStringDecoder),
		A2($elm$json$Json$Decode$index, 4, $author$project$Data$HeatMapThresholds$intAsStringDecoder));
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.$7));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {bO: reqs, b5: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.b9;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.bO));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.b5)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					ce: r.ce,
					ci: r.ci,
					ct: A2(_Http_mapExpect, func, r.ct),
					bl: r.bl,
					cQ: r.cQ,
					dz: r.dz,
					b9: r.b9,
					dE: r.dE
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{ce: false, ci: r.ci, ct: r.ct, bl: r.bl, cQ: r.cQ, dz: r.dz, b9: r.b9, dE: r.dE}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{ci: $elm$http$Http$emptyBody, ct: r.ct, bl: _List_Nil, cQ: 'GET', dz: $elm$core$Maybe$Nothing, b9: $elm$core$Maybe$Nothing, dE: r.dE});
};
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$toLower = _String_toLower;
var $author$project$Sensor$toId = function (sensor) {
	return sensor.c8 + ('-' + ($elm$core$String$toLower(sensor.cW) + (' (' + (sensor.dB + ')'))));
};
var $author$project$Sensor$nameForSensorId = F2(
	function (id, sensors) {
		return A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.cW;
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (sensor) {
						return _Utils_eq(
							$author$project$Sensor$toId(sensor),
							id);
					},
					sensors)));
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $author$project$Sensor$unitForSensorId = F2(
	function (id, sensors) {
		return A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.dB;
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (sensor) {
						return _Utils_eq(
							$author$project$Sensor$toId(sensor),
							id);
					},
					sensors)));
	});
var $author$project$Data$HeatMapThresholds$fetch_ = F4(
	function (decoder_, sensors, sensorId, toCmd) {
		var maybeUnit = A2($author$project$Sensor$unitForSensorId, sensorId, sensors);
		var maybeSensorName = A2($author$project$Sensor$nameForSensorId, sensorId, sensors);
		var http_request = F2(
			function (sensorName, unit) {
				return $elm$http$Http$get(
					{
						ct: A2($elm$http$Http$expectJson, toCmd, decoder_),
						dE: '/api/thresholds/' + (sensorName + ('?unit_symbol=' + $elm$url$Url$percentEncode(unit)))
					});
			});
		return A3($elm$core$Maybe$map2, http_request, maybeSensorName, maybeUnit);
	});
var $author$project$Data$HeatMapThresholds$HeatMapThresholds = F5(
	function (threshold1, threshold2, threshold3, threshold4, threshold5) {
		return {dw: threshold1, o: threshold2, p: threshold3, q: threshold4, dx: threshold5};
	});
var $author$project$Data$HeatMapThresholds$toHeatMapThresholdsWithDefaults = F6(
	function (values, t1, t2, t3, t4, t5) {
		return A5(
			$author$project$Data$HeatMapThresholds$HeatMapThresholds,
			{z: t1, aN: values.dw},
			{z: t2, aN: values.o},
			{z: t3, aN: values.p},
			{z: t4, aN: values.q},
			{z: t5, aN: values.dx});
	});
var $author$project$Data$HeatMapThresholds$fetchDefaults = F4(
	function (sensors, sensorId, toCmd, heatMapThresholdValues) {
		return A4(
			$author$project$Data$HeatMapThresholds$fetch_,
			$author$project$Data$HeatMapThresholds$decoder(
				$author$project$Data$HeatMapThresholds$toHeatMapThresholdsWithDefaults(heatMapThresholdValues)),
			sensors,
			sensorId,
			toCmd);
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Main$fetchHeatMapThresholdDefaults = F3(
	function (sensors, selectedSensorId, heatMapThresholdsValues) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A4(
				$author$project$Data$HeatMapThresholds$fetchDefaults,
				sensors,
				selectedSensorId,
				A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Main$UpdateHeatMapThresholds),
				heatMapThresholdsValues));
	});
var $author$project$Data$HeatMapThresholds$toHeatMapThresholds = F5(
	function (t1, t2, t3, t4, t5) {
		return A5(
			$author$project$Data$HeatMapThresholds$HeatMapThresholds,
			{z: t1, aN: t1},
			{z: t2, aN: t2},
			{z: t3, aN: t3},
			{z: t4, aN: t4},
			{z: t5, aN: t5});
	});
var $author$project$Data$HeatMapThresholds$fetch = F3(
	function (sensors, sensorId, toCmd) {
		return A4(
			$author$project$Data$HeatMapThresholds$fetch_,
			$author$project$Data$HeatMapThresholds$decoder($author$project$Data$HeatMapThresholds$toHeatMapThresholds),
			sensors,
			sensorId,
			toCmd);
	});
var $author$project$Main$fetchHeatMapThresholds = F2(
	function (sensors, selectedSensorId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A3(
				$author$project$Data$HeatMapThresholds$fetch,
				sensors,
				selectedSensorId,
				A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Main$UpdateHeatMapThresholds)));
	});
var $author$project$Main$ExecCmd = function (a) {
	return {$: 49, a: a};
};
var $author$project$Main$GotSession = function (a) {
	return {$: 27, a: a};
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Data$SelectedSession$SelectedSession = function (title) {
	return function (username) {
		return function (sensorName) {
			return function (measurements) {
				return function (fetchedStartTime) {
					return function (startTime) {
						return function (endTime) {
							return function (id) {
								return function (streamId) {
									return function (selectedTimeRange) {
										return function (sensorUnit) {
											return function (averageValue) {
												return function (latitude) {
													return function (longitude) {
														return function (maxLatitude) {
															return function (maxLongitude) {
																return function (minLatitude) {
																	return function (minLongitude) {
																		return function (startLatitude) {
																			return function (startLongitude) {
																				return function (notes) {
																					return function (isIndoor) {
																						return function (lastMeasurementValue) {
																							return {a8: averageValue, aR: endTime, aS: fetchedStartTime, cB: id, br: isIndoor, bv: lastMeasurementValue, cH: latitude, cK: longitude, by: maxLatitude, bz: maxLongitude, bA: measurements, bB: minLatitude, bC: minLongitude, aF: notes, al: selectedTimeRange, a_: sensorName, a$: sensorUnit, b$: startLatitude, b0: startLongitude, a1: startTime, b2: streamId, b8: title, cb: username};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Data$Measurements$Measurement = F4(
	function (value, time, latitude, longitude) {
		return {cH: latitude, cK: longitude, dy: time, aN: value};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Data$Measurements$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'longitude',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'latitude',
		$elm$json$Json$Decode$float,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'time',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'value',
				$elm$json$Json$Decode$float,
				$elm$json$Json$Decode$succeed($author$project$Data$Measurements$Measurement)))));
var $author$project$Data$Note$Note = F6(
	function (text, longitude, latitude, date, photo, photo_thumbnail) {
		return {co: date, cH: latitude, cK: longitude, da: photo, db: photo_thumbnail, dv: text};
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Data$Note$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'photo_thumbnail',
	A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'photo',
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'date',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'latitude',
				$elm$json$Json$Decode$float,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'longitude',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'text',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Data$Note$Note)))))));
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$Data$SelectedSession$millisToPosixDecoder = A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int);
var $author$project$Data$SelectedSession$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'lastMeasurementValue',
	$elm$json$Json$Decode$float,
	0,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'isIndoor',
		$elm$json$Json$Decode$bool,
		false,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'notes',
			$elm$json$Json$Decode$list($author$project$Data$Note$decoder),
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'startLongitude',
				$elm$json$Json$Decode$float,
				0,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'startLatitude',
					$elm$json$Json$Decode$float,
					0,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'minLongitude',
						$elm$json$Json$Decode$float,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'minLatitude',
							$elm$json$Json$Decode$float,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'maxLongitude',
								$elm$json$Json$Decode$float,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'maxLatitude',
									$elm$json$Json$Decode$float,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'longitude',
										$elm$json$Json$Decode$float,
										0,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'latitude',
											$elm$json$Json$Decode$float,
											0,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'averageValue',
												$elm$json$Json$Decode$float,
												0,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'sensorUnit',
													$elm$json$Json$Decode$string,
													A2(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
														{cr: 0, dl: 0},
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'streamId',
															$elm$json$Json$Decode$int,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'id',
																$elm$json$Json$Decode$int,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'endTime',
																	$author$project$Data$SelectedSession$millisToPosixDecoder,
																	A3(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																		'startTime',
																		$author$project$Data$SelectedSession$millisToPosixDecoder,
																		A2(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
																			$elm$core$Maybe$Nothing,
																			A3(
																				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																				'measurements',
																				$elm$json$Json$Decode$list($author$project$Data$Measurements$decoder),
																				A3(
																					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																					'sensorName',
																					$elm$json$Json$Decode$string,
																					A3(
																						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																						'username',
																						$elm$json$Json$Decode$string,
																						A3(
																							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																							'title',
																							$elm$json$Json$Decode$string,
																							$elm$json$Json$Decode$succeed($author$project$Data$SelectedSession$SelectedSession))))))))))))))))))))))));
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $author$project$Data$SelectedSession$fetch = F3(
	function (page, streamId, toCmd) {
		return $elm$http$Http$get(
			{
				ct: A2($elm$http$Http$expectJson, toCmd, $author$project$Data$SelectedSession$decoder),
				dE: (page === 1) ? A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						[
							'api',
							'mobile',
							'streams',
							$elm$core$String$fromInt(streamId) + '.json'
						]),
					_List_Nil) : A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						[
							'api',
							'fixed',
							'streams',
							$elm$core$String$fromInt(streamId) + '.json'
						]),
					_List_fromArray(
						[
							A2($elm$url$Url$Builder$int, 'measurements_limit', 1440)
						]))
			});
	});
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Main$fetchSelectedStream = F2(
	function (maybeId, page) {
		if (maybeId.$ === 1) {
			return $elm$core$Platform$Cmd$none;
		} else {
			var id = maybeId.a;
			return A2(
				$elm$core$Task$perform,
				function (_v1) {
					return $author$project$Main$ExecCmd(
						A3(
							$author$project$Data$SelectedSession$fetch,
							page,
							id,
							A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Main$GotSession)));
				},
				$elm$core$Process$sleep(1500));
		}
	});
var $author$project$Data$Path$fromString = function (path) {
	return path;
};
var $author$project$Data$Overlay$IndoorOverlay = 1;
var $author$project$Data$Overlay$init = function (isIndoor) {
	return isIndoor ? _List_fromArray(
		[1]) : $author$project$Data$Overlay$none;
};
var $author$project$LabelsInput$init = $author$project$LabelsInput$fromList;
var $author$project$Data$Theme$toIcons = F2(
	function (defaultIcon, blueIcon) {
		return {
			aP: $author$project$Data$Path$fromString(blueIcon),
			z: $author$project$Data$Path$fromString(defaultIcon)
		};
	});
var $author$project$Data$Status$Dormant = 1;
var $author$project$Data$Status$toStatus = function (isActive) {
	if (isActive) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Data$Theme$Blue = 1;
var $author$project$Data$Theme$toTheme = function (theme) {
	if (theme === 'blue') {
		return 1;
	} else {
		return 0;
	}
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Data$Overlay$update = F2(
	function (operation, model) {
		var _v0 = _Utils_Tuple2(operation, model);
		if (!_v0.a.$) {
			var overlay = _v0.a.a;
			var overlays = _v0.b;
			return A2($elm$core$List$cons, overlay, overlays);
		} else {
			var overlay = _v0.a.a;
			var overlays = _v0.b;
			return A2(
				$elm$core$List$filter,
				$elm$core$Basics$neq(overlay),
				overlays);
		}
	});
var $author$project$TimeRange$Range = F2(
	function (timeFrom, timeTo) {
		return {aK: timeFrom, aL: timeTo};
	});
var $author$project$TimeRange$timeRangeDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$TimeRange$Range,
	A2($elm$json$Json$Decode$field, 'timeFrom', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'timeTo', $elm$json$Json$Decode$int));
var $author$project$TimeRange$update = F2(
	function (timeRange, newValue) {
		var result = A2($elm$json$Json$Decode$decodeValue, $author$project$TimeRange$timeRangeDecoder, newValue);
		if (!result.$) {
			var decodedValue = result.a;
			return {aK: decodedValue.aK, aL: decodedValue.aL};
		} else {
			return timeRange;
		}
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var sensors = A2(
			$elm$core$Result$withDefault,
			_List_Nil,
			A2(
				$elm$json$Json$Decode$decodeValue,
				$elm$json$Json$Decode$list($author$project$Sensor$decoder),
				flags.n));
		var page = function () {
			var _v2 = url.c9;
			if (_v2 === '/fixed_map') {
				return 0;
			} else {
				return 1;
			}
		}();
		var overlay = function () {
			var _v1 = flags.aI;
			if (_v1.$ === 1) {
				return $author$project$Data$Overlay$init(flags.br);
			} else {
				return A2(
					$author$project$Data$Overlay$update,
					$author$project$Data$Overlay$AddOverlay(0),
					$author$project$Data$Overlay$init(flags.br));
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				$author$project$Main$defaultModel,
				{
					O: flags.aV,
					y: A3($author$project$Data$BoundedInteger$build, 1, 40, 51 - flags.y),
					T: $author$project$Data$Path$fromString(flags.T),
					I: flags.I,
					V: flags.V,
					br: flags.br,
					G: flags.G,
					W: $elm$core$Maybe$Just(key),
					K: $author$project$Data$Path$fromString(flags.K),
					bx: flags.bx,
					Y: $author$project$Data$Path$fromString(flags.Y),
					d: overlay,
					m: page,
					F: $author$project$LabelsInput$init(flags.F),
					_: $author$project$Data$Path$fromString(flags._),
					M: $author$project$Data$Path$fromString(flags.M),
					R: flags.R,
					i: flags.i,
					n: sensors,
					aa: $author$project$Data$Status$toStatus(flags.aU),
					H: $author$project$LabelsInput$init(flags.H),
					E: $author$project$Data$Theme$toTheme(flags.E),
					aJ: A2($author$project$Data$Theme$toIcons, flags.a4, flags.a3),
					N: A2($author$project$TimeRange$update, $author$project$Main$defaultModel.N, flags.N)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($author$project$Main$fetchSelectedStream, flags.aI, page),
						function () {
						var _v0 = flags.aT;
						if (_v0.$ === 1) {
							return A2($author$project$Main$fetchHeatMapThresholds, sensors, flags.i);
						} else {
							var values = _v0.a;
							return A3($author$project$Main$fetchHeatMapThresholdDefaults, sensors, flags.i, values);
						}
					}()
					])));
	});
var $author$project$Main$ClosePopup = {$: 14};
var $author$project$Main$GraphRangeSelected = function (a) {
	return {$: 39, a: a};
};
var $author$project$Main$MapMoved = {$: 36};
var $author$project$Main$ProfileLabels = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$SaveZoomValue = function (a) {
	return {$: 50, a: a};
};
var $author$project$Main$SetScrollPosition = {$: 42};
var $author$project$Main$TagsLabels = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$ToggleSessionSelectionFromJavaScript = function (a) {
	return {$: 25, a: a};
};
var $author$project$Main$UpdateHeatMapThresholdsFromJavaScript = function (a) {
	return {$: 34, a: a};
};
var $author$project$Main$UpdateIsHttping = function (a) {
	return {$: 21, a: a};
};
var $author$project$Main$UpdateIsShowingTimeRangeFilter = function (a) {
	return {$: 40, a: a};
};
var $author$project$Main$UpdateLocationInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$UpdateSessions = function (a) {
	return {$: 19, a: a};
};
var $author$project$Main$UpdateTimeRange = function (a) {
	return {$: 6, a: a};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Ports$graphRangeSelected = _Platform_incomingPort(
	'graphRangeSelected',
	A2(
		$elm$json$Json$Decode$andThen,
		function (start) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (end) {
					return $elm$json$Json$Decode$succeed(
						{cr: end, dl: start});
				},
				A2($elm$json$Json$Decode$field, 'end', $elm$json$Json$Decode$float));
		},
		A2($elm$json$Json$Decode$field, 'start', $elm$json$Json$Decode$float)));
var $author$project$Ports$isShowingTimeRangeFilter = _Platform_incomingPort('isShowingTimeRangeFilter', $elm$json$Json$Decode$bool);
var $author$project$Ports$locationCleared = _Platform_incomingPort(
	'locationCleared',
	$elm$json$Json$Decode$null(0));
var $author$project$Ports$locationUpdated = _Platform_incomingPort('locationUpdated', $elm$json$Json$Decode$string);
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Ports$mapMoved = _Platform_incomingPort(
	'mapMoved',
	$elm$json$Json$Decode$null(0));
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {bG: pids, b5: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {bf: event, W: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.bG,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.W;
		var event = _v0.bf;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.b5);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, 0, 'click');
var $author$project$Ports$profileSelected = _Platform_incomingPort('profileSelected', $elm$json$Json$Decode$string);
var $author$project$Ports$setScroll = _Platform_incomingPort(
	'setScroll',
	$elm$json$Json$Decode$null(0));
var $author$project$LabelsInput$Add = function (a) {
	return {$: 1, a: a};
};
var $author$project$LabelsInput$subscriptions = function (toSub) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				toSub($author$project$LabelsInput$Add)
			]));
};
var $author$project$Ports$tagSelected = _Platform_incomingPort('tagSelected', $elm$json$Json$Decode$string);
var $author$project$Ports$timeRangeSelected = _Platform_incomingPort('timeRangeSelected', $elm$json$Json$Decode$value);
var $author$project$Ports$toggleSessionSelection = _Platform_incomingPort(
	'toggleSessionSelection',
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int)
			])));
var $author$project$Ports$updateHeatMapThresholdsFromJavaScript = _Platform_incomingPort(
	'updateHeatMapThresholdsFromJavaScript',
	A2(
		$elm$json$Json$Decode$andThen,
		function (threshold5) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (threshold4) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (threshold3) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (threshold2) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (threshold1) {
											return $elm$json$Json$Decode$succeed(
												{dw: threshold1, o: threshold2, p: threshold3, q: threshold4, dx: threshold5});
										},
										A2($elm$json$Json$Decode$field, 'threshold1', $elm$json$Json$Decode$int));
								},
								A2($elm$json$Json$Decode$field, 'threshold2', $elm$json$Json$Decode$int));
						},
						A2($elm$json$Json$Decode$field, 'threshold3', $elm$json$Json$Decode$int));
				},
				A2($elm$json$Json$Decode$field, 'threshold4', $elm$json$Json$Decode$int));
		},
		A2($elm$json$Json$Decode$field, 'threshold5', $elm$json$Json$Decode$int)));
var $author$project$Ports$updateIsHttping = _Platform_incomingPort('updateIsHttping', $elm$json$Json$Decode$bool);
var $author$project$Ports$updateSessions = _Platform_incomingPort('updateSessions', $elm$json$Json$Decode$value);
var $author$project$Ports$zoomChanged = _Platform_incomingPort('zoomChanged', $elm$json$Json$Decode$int);
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$ProfileLabels,
				$author$project$LabelsInput$subscriptions($author$project$Ports$profileSelected)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$TagsLabels,
				$author$project$LabelsInput$subscriptions($author$project$Ports$tagSelected)),
				$author$project$Ports$timeRangeSelected($author$project$Main$UpdateTimeRange),
				$author$project$Ports$locationCleared(
				$elm$core$Basics$always(
					$author$project$Main$UpdateLocationInput(''))),
				$author$project$Ports$locationUpdated($author$project$Main$UpdateLocationInput),
				$elm$browser$Browser$Events$onClick(
				$elm$json$Json$Decode$succeed($author$project$Main$ClosePopup)),
				$author$project$Ports$updateSessions($author$project$Main$UpdateSessions),
				$author$project$Ports$updateIsHttping($author$project$Main$UpdateIsHttping),
				$author$project$Ports$toggleSessionSelection($author$project$Main$ToggleSessionSelectionFromJavaScript),
				$author$project$Ports$updateHeatMapThresholdsFromJavaScript($author$project$Main$UpdateHeatMapThresholdsFromJavaScript),
				$author$project$Ports$mapMoved(
				$elm$core$Basics$always($author$project$Main$MapMoved)),
				$author$project$Ports$graphRangeSelected($author$project$Main$GraphRangeSelected),
				$author$project$Ports$isShowingTimeRangeFilter($author$project$Main$UpdateIsShowingTimeRangeFilter),
				$author$project$Ports$setScroll(
				$elm$core$Basics$always($author$project$Main$SetScrollPosition)),
				$author$project$Ports$zoomChanged($author$project$Main$SaveZoomValue)
			]));
};
var $author$project$Main$CloseEmailForm = {$: 15};
var $author$project$Popup$EmailForm = 2;
var $author$project$Main$GotMeasurements = function (a) {
	return {$: 28, a: a};
};
var $author$project$Main$NoOp = {$: 43};
var $author$project$Data$Overlay$PopupOverlay = 2;
var $author$project$Data$Overlay$RemoveOverlay = function (a) {
	return {$: 1, a: a};
};
var $author$project$Data$Overlay$TimeFrameOverlay = 3;
var $author$project$Data$EmailForm$addFlashMessage = F2(
	function (emailForm, flashMessage) {
		return _Utils_update(
			emailForm,
			{
				ag: $elm$core$Maybe$Just(flashMessage)
			});
	});
var $author$project$Data$EmailForm$clearFlash = function (emailForm) {
	return _Utils_update(
		emailForm,
		{ag: $elm$core$Maybe$Nothing});
};
var $author$project$Main$Timeout = function (a) {
	return {$: 44, a: a};
};
var $author$project$Main$debounce = F2(
	function (updateResolution, debouncable) {
		var newCounter = debouncable.ad + 1;
		return _Utils_Tuple2(
			_Utils_update(
				debouncable,
				{
					y: updateResolution(debouncable.y),
					ad: newCounter
				}),
			A2(
				$elm$core$Task$perform,
				function (_v0) {
					return $author$project$Main$Timeout(newCounter);
				},
				$elm$core$Process$sleep(1000)));
	});
var $author$project$Data$Session$Session = F9(
	function (title, id, startTime, endTime, username, shortTypes, average, location, streamId) {
		return {cg: average, aR: endTime, cB: id, bx: location, dk: shortTypes, a1: startTime, b2: streamId, b8: title, cb: username};
	});
var $author$project$Data$Session$Location = F2(
	function (lat, lng) {
		return {cG: lat, cJ: lng};
	});
var $author$project$Data$Session$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Data$Session$Location,
	A2($elm$json$Json$Decode$field, 'lat', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'lng', $elm$json$Json$Decode$float));
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Data$Session$ShortType = F2(
	function (name, type_) {
		return {cW: name, dA: type_};
	});
var $author$project$Data$Session$shortTypeDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Data$Session$ShortType,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'type_', $elm$json$Json$Decode$string));
var $author$project$Data$Session$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'streamId',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'location',
		$author$project$Data$Session$locationDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'average',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'shortTypes',
				$elm$json$Json$Decode$list($author$project$Data$Session$shortTypeDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'username',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'endTime',
						A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'startTime',
							A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'title',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Data$Session$Session))))))))));
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$deselectSession = _Platform_outgoingPort(
	'deselectSession',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$observeSessionsList = _Platform_outgoingPort(
	'observeSessionsList',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Main$deselectSession = function (selectable) {
	var _v0 = selectable.g;
	if (_v0.$ === 3) {
		return _Utils_Tuple2(
			_Utils_update(
				selectable,
				{g: $krisajenkins$remotedata$RemoteData$NotAsked}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Ports$deselectSession(0),
						$author$project$Ports$observeSessionsList(0)
					])));
	} else {
		return _Utils_Tuple2(selectable, $elm$core$Platform$Cmd$none);
	}
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$Api$exportPath = '/api/sessions/export.json';
var $author$project$Api$exportLink = F2(
	function (email, sessions) {
		var query = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$join('&'),
			$elm$core$List$map(
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						$elm$core$Basics$append('session_ids[]='),
						$elm$core$String$fromInt),
					function ($) {
						return $.cB;
					})));
		return $author$project$Api$exportPath + ('?' + (query(sessions) + ('&email=' + email)));
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Data$Measurements$fetch = F4(
	function (streamId, toCmd, startTime, endTime) {
		return $elm$http$Http$get(
			{
				ct: A2(
					$elm$http$Http$expectJson,
					toCmd,
					$elm$json$Json$Decode$list($author$project$Data$Measurements$decoder)),
				dE: A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						['api', 'measurements']),
					_List_fromArray(
						[
							A2(
							$elm$url$Url$Builder$string,
							'stream_ids',
							$elm$core$String$fromInt(streamId)),
							A2(
							$elm$url$Url$Builder$string,
							'start_time',
							$elm$core$String$fromFloat(startTime)),
							A2(
							$elm$url$Url$Builder$string,
							'end_time',
							$elm$core$String$fromFloat(endTime))
						]))
			});
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Data$SelectedSession$times = function (_v0) {
	var startTime = _v0.a1;
	var endTime = _v0.aR;
	return {
		cr: $elm$time$Time$posixToMillis(endTime),
		dl: $elm$time$Time$posixToMillis(startTime)
	};
};
var $author$project$Data$SelectedSession$fetchMeasurements = F3(
	function (session, toCmd, cmd) {
		var newStartTime = session.al.dl;
		var _v0 = session.aS;
		if (_v0.$ === 1) {
			return A4($author$project$Data$Measurements$fetch, session.b2, toCmd, newStartTime, session.al.cr);
		} else {
			var fetchedStartTime = _v0.a;
			return (_Utils_cmp(newStartTime, fetchedStartTime) < 0) ? A4($author$project$Data$Measurements$fetch, session.b2, toCmd, newStartTime, fetchedStartTime) : cmd(
				{
					bA: session.bA,
					b7: $author$project$Data$SelectedSession$times(session)
				});
		}
	});
var $author$project$Ports$fetchSessions = _Platform_outgoingPort(
	'fetchSessions',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Data$HeatMapThresholds$biggest = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $author$project$Data$HeatMapThresholds$updateThresholdValue = F2(
	function (value, threshold) {
		return _Utils_update(
			threshold,
			{aN: value});
	});
var $author$project$Data$HeatMapThresholds$updateFromValues = F2(
	function (values, heatMapThresholds) {
		return _Utils_update(
			heatMapThresholds,
			{
				dw: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, values.dw, heatMapThresholds.dw),
				o: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, values.o, heatMapThresholds.o),
				p: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, values.p, heatMapThresholds.p),
				q: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, values.q, heatMapThresholds.q),
				dx: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, values.dx, heatMapThresholds.dx)
			});
	});
var $author$project$Data$HeatMapThresholds$fitThresholds = F2(
	function (maybeBounds, heatMapThresholds) {
		if (!maybeBounds.$) {
			var bounds = maybeBounds.a;
			var min = $elm$core$Basics$floor(bounds.cR);
			var max = $elm$core$Basics$ceiling(bounds.cM);
			var step = A2($author$project$Data$HeatMapThresholds$biggest, 1, ((max - min) / 4) | 0);
			var threshold2 = min + step;
			var threshold3 = threshold2 + step;
			var threshold4 = threshold3 + step;
			var threshold5 = A2($author$project$Data$HeatMapThresholds$biggest, max, threshold4 + step);
			return A2(
				$author$project$Data$HeatMapThresholds$updateFromValues,
				{dw: min, o: threshold2, p: threshold3, q: threshold4, dx: threshold5},
				heatMapThresholds);
		} else {
			return heatMapThresholds;
		}
	});
var $author$project$Data$SelectedSession$formatForJavaScript = function (session) {
	return {
		cB: session.cB,
		bs: session.br,
		bw: session.bv,
		cH: session.cH,
		cK: session.cK,
		aF: session.aF,
		b1: {ch: session.a8, cB: session.b2, cN: session.by, cO: session.bz, bA: session.bA, cS: session.bB, cT: session.bC, di: session.a_, dm: session.b$, dn: session.b0, dC: session.a$}
	};
};
var $author$project$Main$SaveScrollPosition = function (a) {
	return {$: 41, a: a};
};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Main$getScrollPosition = A2(
	$elm$core$Task$attempt,
	function (result) {
		return $author$project$Main$SaveScrollPosition(
			A2(
				$elm$core$Result$withDefault,
				0,
				A2(
					$elm$core$Result$map,
					function (viewport) {
						return viewport.dG.dI;
					},
					result)));
	},
	$elm$browser$Browser$Dom$getViewportOf('session-cards-container'));
var $author$project$Data$BoundedInteger$getValue = function (_v0) {
	var existingValue = _v0;
	return existingValue.aN;
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Ports$drawFixed = _Platform_outgoingPort(
	'drawFixed',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'heat',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'levels',
									$elm$json$Json$Encode$list(
										function ($) {
											return $elm$json$Json$Encode$object(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'className',
														$elm$json$Json$Encode$string($.az)),
														_Utils_Tuple2(
														'from',
														$elm$json$Json$Encode$int($.aC)),
														_Utils_Tuple2(
														'to',
														$elm$json$Json$Encode$int($.aM))
													]));
										})($.cI)),
									_Utils_Tuple2(
									'threshold1',
									$elm$json$Json$Encode$int($.dw)),
									_Utils_Tuple2(
									'threshold5',
									$elm$json$Json$Encode$int($.dx))
								]));
					}($.cz)),
					_Utils_Tuple2(
					'measurements',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'latitude',
										$elm$json$Json$Encode$float($.cH)),
										_Utils_Tuple2(
										'longitude',
										$elm$json$Json$Encode$float($.cK)),
										_Utils_Tuple2(
										'time',
										$elm$json$Json$Encode$int($.dy)),
										_Utils_Tuple2(
										'value',
										$elm$json$Json$Encode$float($.aN))
									]));
						})($.bA)),
					_Utils_Tuple2(
					'sensor',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'parameter',
									$elm$json$Json$Encode$string($.c8)),
									_Utils_Tuple2(
									'unit',
									$elm$json$Json$Encode$string($.dB))
								]));
					}($.dh)),
					_Utils_Tuple2(
					'times',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'end',
									$elm$json$Json$Encode$int($.cr)),
									_Utils_Tuple2(
									'start',
									$elm$json$Json$Encode$int($.dl))
								]));
					}($.b7))
				]));
	});
var $author$project$Ports$drawMobile = _Platform_outgoingPort(
	'drawMobile',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'heat',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'levels',
									$elm$json$Json$Encode$list(
										function ($) {
											return $elm$json$Json$Encode$object(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'className',
														$elm$json$Json$Encode$string($.az)),
														_Utils_Tuple2(
														'from',
														$elm$json$Json$Encode$int($.aC)),
														_Utils_Tuple2(
														'to',
														$elm$json$Json$Encode$int($.aM))
													]));
										})($.cI)),
									_Utils_Tuple2(
									'threshold1',
									$elm$json$Json$Encode$int($.dw)),
									_Utils_Tuple2(
									'threshold5',
									$elm$json$Json$Encode$int($.dx))
								]));
					}($.cz)),
					_Utils_Tuple2(
					'measurements',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'latitude',
										$elm$json$Json$Encode$float($.cH)),
										_Utils_Tuple2(
										'longitude',
										$elm$json$Json$Encode$float($.cK)),
										_Utils_Tuple2(
										'time',
										$elm$json$Json$Encode$int($.dy)),
										_Utils_Tuple2(
										'value',
										$elm$json$Json$Encode$float($.aN))
									]));
						})($.bA)),
					_Utils_Tuple2(
					'sensor',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'parameter',
									$elm$json$Json$Encode$string($.c8)),
									_Utils_Tuple2(
									'unit',
									$elm$json$Json$Encode$string($.dB))
								]));
					}($.dh)),
					_Utils_Tuple2(
					'times',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'end',
									$elm$json$Json$Encode$int($.cr)),
									_Utils_Tuple2(
									'start',
									$elm$json$Json$Encode$int($.dl))
								]));
					}($.b7))
				]));
	});
var $author$project$Sensor$parameterForId = F2(
	function (sensors, sensorId) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.c8;
				},
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (sensor) {
							return _Utils_eq(
								$author$project$Sensor$toId(sensor),
								sensorId);
						},
						sensors))));
	});
var $author$project$Data$HeatMapThresholds$toValues = function (_v0) {
	var threshold1 = _v0.dw;
	var threshold2 = _v0.o;
	var threshold3 = _v0.p;
	var threshold4 = _v0.q;
	var threshold5 = _v0.dx;
	return {dw: threshold1.aN, o: threshold2.aN, p: threshold3.aN, q: threshold4.aN, dx: threshold5.aN};
};
var $author$project$Main$toGraphHeatParams = function (thresholds) {
	var _v0 = $author$project$Data$HeatMapThresholds$toValues(thresholds);
	var threshold1 = _v0.dw;
	var threshold2 = _v0.o;
	var threshold3 = _v0.p;
	var threshold4 = _v0.q;
	var threshold5 = _v0.dx;
	var levels = _List_fromArray(
		[
			{az: 'first-band', aC: threshold1, aM: threshold2},
			{az: 'second-band', aC: threshold2, aM: threshold3},
			{az: 'third-band', aC: threshold3, aM: threshold4},
			{az: 'fourth-band', aC: threshold4, aM: threshold5}
		]);
	return {cI: levels, dw: threshold1, dx: threshold5};
};
var $author$project$Main$toGraphParams = F4(
	function (thresholds, selectedSession, sensors, selectedSensorId) {
		var unit = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($author$project$Sensor$unitForSensorId, selectedSensorId, sensors));
		var parameter = A2($author$project$Sensor$parameterForId, sensors, selectedSensorId);
		return {
			cz: $author$project$Main$toGraphHeatParams(thresholds),
			bA: selectedSession.bA,
			dh: {c8: parameter, dB: unit},
			b7: $author$project$Data$SelectedSession$times(selectedSession)
		};
	});
var $author$project$Main$graphDrawCmd = F5(
	function (thresholds, session, sensors, selectedSensorId, page) {
		var params = A4($author$project$Main$toGraphParams, thresholds, session, sensors, selectedSensorId);
		if (page === 1) {
			return $author$project$Ports$drawMobile(params);
		} else {
			return $author$project$Ports$drawFixed(params);
		}
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Sensor$fixedDefaultSensorId = 'Particulate Matter-openaq-pm2.5 (µg/m³)';
var $author$project$Sensor$mobileDefaultSensorId = 'Particulate Matter-airbeam-pm2.5 (µg/m³)';
var $author$project$Sensor$defaultSensorIdByParameter = function (page) {
	var common = $elm$core$Dict$fromList(
		_List_fromArray(
			[
				_Utils_Tuple2('Humidity', 'Humidity-airbeam-rh (%)'),
				_Utils_Tuple2('Sound Level', 'Sound Level-phone microphone (dB)'),
				_Utils_Tuple2('Temperature', 'Temperature-airbeam-f (F)')
			]));
	if (page === 1) {
		return A3($elm$core$Dict$insert, 'Particulate Matter', $author$project$Sensor$mobileDefaultSensorId, common);
	} else {
		return A3(
			$elm$core$Dict$insert,
			'Ozone',
			'Ozone-openaq-o3 (ppb)',
			A3($elm$core$Dict$insert, 'Particulate Matter', $author$project$Sensor$fixedDefaultSensorId, common));
	}
};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Sensor$toLabel = function (sensor) {
	return sensor.cW + (' (' + (sensor.dB + ')'));
};
var $author$project$Sensor$idForParameterOrLabel = F4(
	function (page, parameterOrLabel, oldSensorId, sensors) {
		var maybeByParameter = A2(
			$elm$core$Maybe$map,
			$author$project$Sensor$toId,
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.bX;
						},
						A2(
							$elm$core$List$filter,
							function (sensor) {
								return _Utils_eq(sensor.c8, parameterOrLabel);
							},
							sensors)))));
		var maybeByLabel = A2(
			$elm$core$Maybe$map,
			$author$project$Sensor$toId,
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (sensor) {
						return _Utils_eq(
							$author$project$Sensor$toLabel(sensor),
							parameterOrLabel) && _Utils_eq(
							sensor.c8,
							A2($author$project$Sensor$parameterForId, sensors, oldSensorId));
					},
					sensors)));
		var byId = function (id) {
			return A2(
				$elm$core$Maybe$map,
				$author$project$Sensor$toId,
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (sensor) {
							return _Utils_eq(
								$author$project$Sensor$toId(sensor),
								id);
						},
						sensors)));
		};
		var maybeDefault = A2(
			$elm$core$Maybe$andThen,
			byId,
			A2(
				$elm$core$Dict$get,
				parameterOrLabel,
				$author$project$Sensor$defaultSensorIdByParameter(page)));
		var _v0 = _Utils_Tuple3(maybeDefault, maybeByParameter, maybeByLabel);
		if (!_v0.a.$) {
			var _default = _v0.a.a;
			return _default;
		} else {
			if (!_v0.b.$) {
				var byParameter = _v0.b.a;
				return byParameter;
			} else {
				if (!_v0.c.$) {
					var byLabel = _v0.c.a;
					return byLabel;
				} else {
					if (page === 1) {
						return $author$project$Sensor$mobileDefaultSensorId;
					} else {
						return $author$project$Sensor$fixedDefaultSensorId;
					}
				}
			}
		}
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Ports$loadMoreSessions = _Platform_outgoingPort(
	'loadMoreSessions',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Data$SelectedSession$selectedMeasurements = F2(
	function (allMeasurements, selectedTimeRange) {
		return A2(
			$elm$core$List$map,
			function (measurement) {
				return measurement.aN;
			},
			A2(
				$elm$core$List$filter,
				function (measurement) {
					return (_Utils_cmp(measurement.dy, selectedTimeRange.dl) > -1) && (_Utils_cmp(measurement.dy, selectedTimeRange.cr) < 1);
				},
				allMeasurements));
	});
var $author$project$Data$SelectedSession$measurementBounds = function (session) {
	var maybeMin = $elm$core$List$minimum(
		A2($author$project$Data$SelectedSession$selectedMeasurements, session.bA, session.al));
	var maybeMax = $elm$core$List$maximum(
		A2($author$project$Data$SelectedSession$selectedMeasurements, session.bA, session.al));
	var _v0 = _Utils_Tuple2(maybeMin, maybeMax);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var min = _v0.a.a;
		var max = _v0.b.a;
		return $elm$core$Maybe$Just(
			{cM: max, cR: min});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$not = _Basics_not;
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $author$project$Ports$pulseSessionMarker = _Platform_outgoingPort(
	'pulseSessionMarker',
	function ($) {
		return A3(
			$elm$core$Maybe$destruct,
			$elm$json$Json$Encode$null,
			function ($) {
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'heatLevel',
							$elm$json$Json$Encode$int($.bm)),
							_Utils_Tuple2(
							'location',
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'lat',
											$elm$json$Json$Encode$float($.cG)),
											_Utils_Tuple2(
											'lng',
											$elm$json$Json$Encode$float($.cJ))
										]));
							}($.bx)),
							_Utils_Tuple2(
							'streamId',
							$elm$json$Json$Encode$int($.b2))
						]));
			},
			$);
	});
var $author$project$Ports$refreshTimeRange = _Platform_outgoingPort(
	'refreshTimeRange',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$saveScrollPosition = _Platform_outgoingPort('saveScrollPosition', $elm$json$Json$Encode$float);
var $author$project$Ports$selectSensorId = _Platform_outgoingPort('selectSensorId', $elm$json$Json$Encode$string);
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Ports$selectSession = _Platform_outgoingPort(
	'selectSession',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int($.cB)),
					_Utils_Tuple2(
					'is_indoor',
					$elm$json$Json$Encode$bool($.bs)),
					_Utils_Tuple2(
					'last_measurement_value',
					$elm$json$Json$Encode$float($.bw)),
					_Utils_Tuple2(
					'latitude',
					$elm$json$Json$Encode$float($.cH)),
					_Utils_Tuple2(
					'longitude',
					$elm$json$Json$Encode$float($.cK)),
					_Utils_Tuple2(
					'notes',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'date',
										$elm$json$Json$Encode$string($.co)),
										_Utils_Tuple2(
										'latitude',
										$elm$json$Json$Encode$float($.cH)),
										_Utils_Tuple2(
										'longitude',
										$elm$json$Json$Encode$float($.cK)),
										_Utils_Tuple2(
										'photo',
										function ($) {
											return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
										}($.da)),
										_Utils_Tuple2(
										'photo_thumbnail',
										function ($) {
											return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
										}($.db)),
										_Utils_Tuple2(
										'text',
										$elm$json$Json$Encode$string($.dv))
									]));
						})($.aF)),
					_Utils_Tuple2(
					'stream',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'average_value',
									$elm$json$Json$Encode$float($.ch)),
									_Utils_Tuple2(
									'id',
									$elm$json$Json$Encode$int($.cB)),
									_Utils_Tuple2(
									'max_latitude',
									$elm$json$Json$Encode$float($.cN)),
									_Utils_Tuple2(
									'max_longitude',
									$elm$json$Json$Encode$float($.cO)),
									_Utils_Tuple2(
									'measurements',
									$elm$json$Json$Encode$list(
										function ($) {
											return $elm$json$Json$Encode$object(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'latitude',
														$elm$json$Json$Encode$float($.cH)),
														_Utils_Tuple2(
														'longitude',
														$elm$json$Json$Encode$float($.cK)),
														_Utils_Tuple2(
														'time',
														$elm$json$Json$Encode$int($.dy)),
														_Utils_Tuple2(
														'value',
														$elm$json$Json$Encode$float($.aN))
													]));
										})($.bA)),
									_Utils_Tuple2(
									'min_latitude',
									$elm$json$Json$Encode$float($.cS)),
									_Utils_Tuple2(
									'min_longitude',
									$elm$json$Json$Encode$float($.cT)),
									_Utils_Tuple2(
									'sensor_name',
									$elm$json$Json$Encode$string($.di)),
									_Utils_Tuple2(
									'start_latitude',
									$elm$json$Json$Encode$float($.dm)),
									_Utils_Tuple2(
									'start_longitude',
									$elm$json$Json$Encode$float($.dn)),
									_Utils_Tuple2(
									'unit_symbol',
									$elm$json$Json$Encode$string($.dC))
								]));
					}($.b1))
				]));
	});
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $author$project$Main$setScrollPosition = function (value) {
	return A2(
		$elm$core$Task$attempt,
		function (_v0) {
			return $author$project$Main$NoOp;
		},
		A3($elm$browser$Browser$Dom$setViewportOf, 'session-cards-container', value, 0));
};
var $author$project$Data$BoundedInteger$updateValue = F2(
	function (update, _v0) {
		var existingValue = _v0;
		var updatedValue = update(existingValue.aN);
		return (_Utils_cmp(updatedValue, existingValue.a5) > 0) ? existingValue : ((_Utils_cmp(updatedValue, existingValue.aW) < 0) ? existingValue : _Utils_update(
			existingValue,
			{aN: updatedValue}));
	});
var $author$project$Data$BoundedInteger$setValue = F2(
	function (value, existingValue) {
		return A2(
			$author$project$Data$BoundedInteger$updateValue,
			function (_v0) {
				return value;
			},
			existingValue);
	});
var $author$project$Ports$setZoom = _Platform_outgoingPort('setZoom', $elm$json$Json$Encode$int);
var $author$project$Ports$showCopyLinkTooltip = _Platform_outgoingPort('showCopyLinkTooltip', $elm$json$Json$Encode$string);
var $author$project$Data$Status$toBool = function (status) {
	if (!status) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Data$HeatMapThresholds$resetThresholdValueToDefault = function (threshold) {
	return _Utils_update(
		threshold,
		{aN: threshold.z});
};
var $author$project$Data$HeatMapThresholds$toDefaults = function (heatMapThresholds) {
	return {
		dw: $author$project$Data$HeatMapThresholds$resetThresholdValueToDefault(heatMapThresholds.dw),
		o: $author$project$Data$HeatMapThresholds$resetThresholdValueToDefault(heatMapThresholds.o),
		p: $author$project$Data$HeatMapThresholds$resetThresholdValueToDefault(heatMapThresholds.p),
		q: $author$project$Data$HeatMapThresholds$resetThresholdValueToDefault(heatMapThresholds.q),
		dx: $author$project$Data$HeatMapThresholds$resetThresholdValueToDefault(heatMapThresholds.dx)
	};
};
var $rtfeldman$elm_validate$Validate$fromValid = function (_v0) {
	var subject = _v0;
	return subject;
};
var $author$project$Data$EmailForm$toEmail = function (emailForm) {
	return $rtfeldman$elm_validate$Validate$fromValid(emailForm).aN;
};
var $author$project$Data$SelectedSession$toStreamId = function (_v0) {
	var streamId = _v0.b2;
	return streamId;
};
var $author$project$Data$Theme$toString = function (theme) {
	if (!theme) {
		return 'default';
	} else {
		return 'blue';
	}
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.bL;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.bi,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.bM,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.bH,
					_Utils_ap(http, url.bo)),
				url.c9)));
};
var $author$project$Data$Theme$toggle = function (theme) {
	if (!theme) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Ports$toggleActive = _Platform_outgoingPort('toggleActive', $elm$json$Json$Encode$bool);
var $author$project$Ports$toggleCrowdMap = _Platform_outgoingPort('toggleCrowdMap', $elm$json$Json$Encode$bool);
var $author$project$Ports$toggleIndoor = _Platform_outgoingPort('toggleIndoor', $elm$json$Json$Encode$bool);
var $author$project$Ports$toggleIsSearchOn = _Platform_outgoingPort('toggleIsSearchOn', $elm$json$Json$Encode$bool);
var $author$project$Ports$toggleTheme = _Platform_outgoingPort('toggleTheme', $elm$json$Json$Encode$string);
var $author$project$Data$EmailForm$updateErrors = F2(
	function (emailForm, errors) {
		return _Utils_update(
			emailForm,
			{af: errors});
	});
var $author$project$Data$SelectedSession$updateFetchedTimeRange = function (session) {
	return _Utils_update(
		session,
		{
			aS: A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$toFloat,
				$elm$core$List$minimum(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.dy;
						},
						session.bA)))
		});
};
var $author$project$Data$EmailForm$updateFormValue = function (value) {
	return {af: _List_Nil, ag: $elm$core$Maybe$Nothing, aN: value};
};
var $author$project$Ports$updateGraphData = _Platform_outgoingPort(
	'updateGraphData',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'measurements',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'latitude',
										$elm$json$Json$Encode$float($.cH)),
										_Utils_Tuple2(
										'longitude',
										$elm$json$Json$Encode$float($.cK)),
										_Utils_Tuple2(
										'time',
										$elm$json$Json$Encode$int($.dy)),
										_Utils_Tuple2(
										'value',
										$elm$json$Json$Encode$float($.aN))
									]));
						})($.bA)),
					_Utils_Tuple2(
					'times',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'end',
									$elm$json$Json$Encode$int($.cr)),
									_Utils_Tuple2(
									'start',
									$elm$json$Json$Encode$int($.dl))
								]));
					}($.b7))
				]));
	});
var $author$project$Ports$updateGraphYAxis = _Platform_outgoingPort(
	'updateGraphYAxis',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'levels',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'className',
										$elm$json$Json$Encode$string($.az)),
										_Utils_Tuple2(
										'from',
										$elm$json$Json$Encode$int($.aC)),
										_Utils_Tuple2(
										'to',
										$elm$json$Json$Encode$int($.aM))
									]));
						})($.cI)),
					_Utils_Tuple2(
					'threshold1',
					$elm$json$Json$Encode$int($.dw)),
					_Utils_Tuple2(
					'threshold5',
					$elm$json$Json$Encode$int($.dx))
				]));
	});
var $author$project$Ports$updateHeatMapThresholds = _Platform_outgoingPort(
	'updateHeatMapThresholds',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'threshold1',
					$elm$json$Json$Encode$int($.dw)),
					_Utils_Tuple2(
					'threshold2',
					$elm$json$Json$Encode$int($.o)),
					_Utils_Tuple2(
					'threshold3',
					$elm$json$Json$Encode$int($.p)),
					_Utils_Tuple2(
					'threshold4',
					$elm$json$Json$Encode$int($.q)),
					_Utils_Tuple2(
					'threshold5',
					$elm$json$Json$Encode$int($.dx))
				]));
	});
var $author$project$Main$updateHeatMapExtreme = F3(
	function (model, str, updateExtreme) {
		var _v0 = _Utils_Tuple2(
			$elm$core$String$toInt(str),
			model.s);
		if ((!_v0.a.$) && (_v0.b.$ === 3)) {
			var i = _v0.a.a;
			var thresholds = _v0.b.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						s: $krisajenkins$remotedata$RemoteData$Success(
							A2(updateExtreme, i, thresholds))
					}),
				$author$project$Ports$updateHeatMapThresholds(
					$author$project$Data$HeatMapThresholds$toValues(
						A2(updateExtreme, i, thresholds))));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$LabelsInput$add = F2(
	function (label, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				ay: '',
				ai: A2($elm$core$Set$insert, label, model.ai)
			});
	});
var $author$project$LabelsInput$asList = function (_v0) {
	var labels = _v0.ai;
	return $elm$core$Set$toList(labels);
};
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$LabelsInput$remove = F2(
	function (label, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				ai: A2($elm$core$Set$remove, label, model.ai)
			});
	});
var $author$project$LabelsInput$withCandidate = F2(
	function (candidate, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{ay: candidate});
	});
var $author$project$LabelsInput$update = F3(
	function (msg, model, toCmd) {
		switch (msg.$) {
			case 0:
				var str = msg.a;
				return _Utils_Tuple2(
					A2($author$project$LabelsInput$withCandidate, str, model),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				if (str === '') {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var newModel = A2($author$project$LabelsInput$add, str, model);
					return _Utils_Tuple2(
						newModel,
						toCmd(
							$author$project$LabelsInput$asList(newModel)));
				}
			default:
				var str = msg.a;
				var newModel = A2($author$project$LabelsInput$remove, str, model);
				return _Utils_Tuple2(
					newModel,
					toCmd(
						$author$project$LabelsInput$asList(newModel)));
		}
	});
var $author$project$Main$updateLabels = F5(
	function (msg, model, toSubCmd, mapper, updateModel) {
		var _v0 = A3($author$project$LabelsInput$update, msg, model, toSubCmd);
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			updateModel(subModel),
			A2($elm$core$Platform$Cmd$map, mapper, subCmd));
	});
var $author$project$Data$HeatMapThresholds$updateMaximum = F2(
	function (value, heatMapThresholds) {
		return (_Utils_cmp(heatMapThresholds.dw.aN, value - 4) > 0) ? {
			dw: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value - 4, heatMapThresholds.dw),
			o: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value - 3, heatMapThresholds.o),
			p: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value - 2, heatMapThresholds.p),
			q: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value - 1, heatMapThresholds.q),
			dx: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value, heatMapThresholds.dx)
		} : _Utils_update(
			heatMapThresholds,
			{
				dx: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value, heatMapThresholds.dx)
			});
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $author$project$Data$SelectedSession$updateMeasurements = F2(
	function (measurements, session) {
		return $author$project$Data$SelectedSession$updateFetchedTimeRange(
			_Utils_update(
				session,
				{
					bA: A2($elm$core$List$append, measurements, session.bA)
				}));
	});
var $author$project$Data$HeatMapThresholds$updateMinimum = F2(
	function (value, heatMapThresholds) {
		return (_Utils_cmp(value + 4, heatMapThresholds.dx.aN) > 0) ? {
			dw: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value, heatMapThresholds.dw),
			o: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value + 1, heatMapThresholds.o),
			p: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value + 2, heatMapThresholds.p),
			q: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value + 3, heatMapThresholds.q),
			dx: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value + 4, heatMapThresholds.dx)
		} : _Utils_update(
			heatMapThresholds,
			{
				dw: A2($author$project$Data$HeatMapThresholds$updateThresholdValue, value, heatMapThresholds.dw)
			});
	});
var $author$project$Ports$updateParams = _Platform_outgoingPort(
	'updateParams',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'key',
					$elm$json$Json$Encode$string($.W)),
					_Utils_Tuple2(
					'value',
					$elm$json$Json$Encode$bool($.aN))
				]));
	});
var $author$project$Ports$updateProfiles = _Platform_outgoingPort(
	'updateProfiles',
	$elm$json$Json$Encode$list($elm$json$Json$Encode$string));
var $author$project$Ports$updateResolution = _Platform_outgoingPort('updateResolution', $elm$json$Json$Encode$int);
var $author$project$Ports$updateTags = _Platform_outgoingPort(
	'updateTags',
	$elm$json$Json$Encode$list($elm$json$Json$Encode$string));
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var newLocation = msg.a;
				if (newLocation === '') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bx: newLocation}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bx: newLocation}),
						$author$project$Ports$fetchSessions(0));
				}
			case 1:
				var subMsg = msg.a;
				var _v2 = $author$project$Main$deselectSession(model);
				var subModel = _v2.a;
				var subCmd1 = _v2.b;
				var _v3 = A5(
					$author$project$Main$updateLabels,
					subMsg,
					subModel.H,
					$author$project$Ports$updateTags,
					$author$project$Main$TagsLabels,
					function (tags) {
						return _Utils_update(
							subModel,
							{H: tags});
					});
				var subModel2 = _v3.a;
				var subCmd2 = _v3.b;
				return _Utils_Tuple2(
					subModel2,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[subCmd1, subCmd2])));
			case 2:
				var subMsg = msg.a;
				var _v4 = $author$project$Main$deselectSession(model);
				var subModel = _v4.a;
				var subCmd1 = _v4.b;
				var _v5 = A5(
					$author$project$Main$updateLabels,
					subMsg,
					subModel.F,
					$author$project$Ports$updateProfiles,
					$author$project$Main$ProfileLabels,
					function (profiles) {
						return _Utils_update(
							subModel,
							{F: profiles});
					});
				var subModel2 = _v5.a;
				var subCmd2 = _v5.b;
				return _Utils_Tuple2(
					subModel2,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[subCmd1, subCmd2])));
			case 3:
				var newValue = msg.a;
				return _Utils_eq(model.I, newValue) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{I: newValue}),
					$author$project$Ports$toggleCrowdMap(newValue));
			case 4:
				var resolution = msg.a;
				var updatedResolution = A2($author$project$Data$BoundedInteger$setValue, resolution, model.y);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{y: updatedResolution}),
					$author$project$Ports$updateResolution(
						51 - $author$project$Data$BoundedInteger$getValue(updatedResolution)));
			case 5:
				var zoomLevel = msg.a;
				var updatedInt = A2($author$project$Data$BoundedInteger$setValue, zoomLevel, model.ac);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: updatedInt}),
					$author$project$Ports$setZoom(
						$author$project$Data$BoundedInteger$getValue(updatedInt)));
			case 50:
				var zoomLevel = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ac: A2($author$project$Data$BoundedInteger$setValue, zoomLevel, model.ac)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var value = msg.a;
				var _v6 = $author$project$Main$deselectSession(model);
				var subModel = _v6.a;
				var subCmd = _v6.b;
				var newTimeRange = A2($author$project$TimeRange$update, subModel.N, value);
				return _Utils_Tuple2(
					_Utils_update(
						subModel,
						{N: newTimeRange}),
					subCmd);
			case 7:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$refreshTimeRange(0));
			case 8:
				var tooltipId = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$showCopyLinkTooltip(tooltipId));
			case 9:
				var popup = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							J: false,
							d: A2(
								$author$project$Data$Overlay$update,
								$author$project$Data$Overlay$AddOverlay(2),
								model.d),
							v: popup
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var limit = 100;
				var message = 'You can\'t export more than ' + ($elm$core$String$fromInt(limit) + ' sessions at a time. Use the time frame filter to chunk your exports or use our API.');
				return (_Utils_cmp(
					$elm$core$List$length(model.am),
					limit) > 0) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{
							u: A2($author$project$Data$EmailForm$addFlashMessage, model.u, message),
							v: 2
						}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Main$CloseEmailForm),
						$elm$core$Process$sleep(5000))) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: A2(
								$author$project$Data$Overlay$update,
								$author$project$Data$Overlay$RemoveOverlay(2),
								model.d),
							v: 2
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var emailFormResult = msg.a;
				var toExport = function () {
					var _v9 = model.g;
					if (_v9.$ === 3) {
						var session = _v9.a;
						return _List_fromArray(
							[
								{cB: session.cB}
							]);
					} else {
						return A2(
							$elm$core$List$map,
							function (session) {
								return {cB: session.cB};
							},
							model.am);
					}
				}();
				if (!emailFormResult.$) {
					var emailForm = emailFormResult.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								u: A2($author$project$Data$EmailForm$addFlashMessage, model.u, 'Exported sessions will be emailed within minutes. The email may end up in your spam folder.')
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$elm$http$Http$get(
									{
										ct: $elm$http$Http$expectWhatever(
											function (_v8) {
												return $author$project$Main$NoOp;
											}),
										dE: A2(
											$author$project$Api$exportLink,
											$author$project$Data$EmailForm$toEmail(emailForm),
											toExport)
									}),
									A2(
									$elm$core$Task$perform,
									$elm$core$Basics$always($author$project$Main$CloseEmailForm),
									$elm$core$Process$sleep(3000))
								])));
				} else {
					var errors = emailFormResult.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								u: A2($author$project$Data$EmailForm$updateErrors, model.u, errors)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 12:
				var emailForm = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							u: $author$project$Data$EmailForm$updateFormValue(emailForm)
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: A2(
								$author$project$Data$Overlay$update,
								$author$project$Data$Overlay$RemoveOverlay(2),
								model.d),
							v: 3
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							u: $author$project$Data$EmailForm$clearFlash(model.u),
							v: 3
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: !model.J}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var value = msg.a;
				var _v10 = $author$project$Main$deselectSession(model);
				var subModel = _v10.a;
				var subCmd = _v10.b;
				var selectedSensorId = A4($author$project$Sensor$idForParameterOrLabel, subModel.m, value, subModel.i, subModel.n);
				return _Utils_Tuple2(
					_Utils_update(
						subModel,
						{i: selectedSensorId}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$selectSensorId(selectedSensorId),
								A2($author$project$Main$fetchHeatMapThresholds, subModel.n, selectedSensorId),
								subCmd
							])));
			case 17:
				var _v11 = model.W;
				if (!_v11.$) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 18:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					var _v13 = model.W;
					if (!_v13.$) {
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(
								$elm$url$Url$toString(url)));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(url));
				}
			case 19:
				var value = msg.a;
				var decoder = A3(
					$elm$json$Json$Decode$map2,
					$elm$core$Tuple$pair,
					A2(
						$elm$json$Json$Decode$field,
						'fetched',
						$elm$json$Json$Decode$list($author$project$Data$Session$decoder)),
					A2($elm$json$Json$Decode$field, 'fetchableSessionsCount', $elm$json$Json$Decode$int));
				var _v14 = A2(
					$elm$core$Result$withDefault,
					_Utils_Tuple2(_List_Nil, 0),
					A2($elm$json$Json$Decode$decodeValue, decoder, value));
				var fetched = _v14.a;
				var fetchableSessionsCount = _v14.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aB: fetchableSessionsCount, am: fetched, ax: false}),
					$elm$core$Platform$Cmd$none);
			case 20:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$loadMoreSessions(0));
			case 21:
				var isHttpingNow = msg.a;
				var overlay = isHttpingNow ? $author$project$Data$Overlay$AddOverlay(0) : $author$project$Data$Overlay$RemoveOverlay(0);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: A2($author$project$Data$Overlay$update, overlay, model.d)
						}),
					$elm$core$Platform$Cmd$none);
			case 22:
				var newValue = msg.a;
				var _v15 = $author$project$Main$deselectSession(model);
				var subModel = _v15.a;
				var subCmd = _v15.b;
				return _Utils_eq(subModel.br, newValue) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : (newValue ? _Utils_Tuple2(
					_Utils_update(
						subModel,
						{
							br: true,
							d: A2(
								$author$project$Data$Overlay$update,
								$author$project$Data$Overlay$AddOverlay(1),
								model.d),
							F: $author$project$LabelsInput$empty
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$toggleIndoor(true),
								$author$project$Ports$updateProfiles(_List_Nil),
								subCmd
							]))) : _Utils_Tuple2(
					_Utils_update(
						subModel,
						{
							br: false,
							d: A2(
								$author$project$Data$Overlay$update,
								$author$project$Data$Overlay$RemoveOverlay(1),
								model.d)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$toggleIndoor(false),
								subCmd
							]))));
			case 23:
				var newStatus = msg.a;
				var _v16 = $author$project$Main$deselectSession(model);
				var subModel = _v16.a;
				var subCmd = _v16.b;
				return _Utils_eq(subModel.aa, newStatus) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						subModel,
						{aa: newStatus}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$toggleActive(
								$author$project$Data$Status$toBool(newStatus)),
								subCmd
							])));
			case 24:
				return $author$project$Main$deselectSession(model);
			case 25:
				var maybeStreamId = msg.a;
				var _v17 = _Utils_Tuple2(model.g, maybeStreamId);
				if (!_v17.b.$) {
					if (_v17.a.$ === 3) {
						var session = _v17.a.a;
						var streamId = _v17.b.a;
						if (_Utils_eq(
							$author$project$Data$SelectedSession$toStreamId(session),
							streamId)) {
							return $author$project$Main$deselectSession(model);
						} else {
							var _v18 = $author$project$Main$deselectSession(model);
							var subModel = _v18.a;
							var subCmd = _v18.b;
							return _Utils_Tuple2(
								_Utils_update(
									subModel,
									{
										d: A2(
											$author$project$Data$Overlay$update,
											$author$project$Data$Overlay$AddOverlay(0),
											model.d)
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A3(
											$author$project$Data$SelectedSession$fetch,
											model.m,
											streamId,
											A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Main$GotSession)),
											$author$project$Main$getScrollPosition,
											subCmd
										])));
						}
					} else {
						var streamId = _v17.b.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: A2(
										$author$project$Data$Overlay$update,
										$author$project$Data$Overlay$AddOverlay(0),
										model.d)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A3(
										$author$project$Data$SelectedSession$fetch,
										model.m,
										streamId,
										A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Main$GotSession)),
										$author$project$Main$getScrollPosition
									])));
					}
				} else {
					var _v19 = _v17.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g: $krisajenkins$remotedata$RemoteData$NotAsked}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Ports$deselectSession(0),
									$author$project$Ports$observeSessionsList(0)
								])));
				}
			case 26:
				var streamId = msg.a;
				var _v20 = model.g;
				if (!_v20.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: A2(
									$author$project$Data$Overlay$update,
									$author$project$Data$Overlay$AddOverlay(0),
									model.d)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A3(
									$author$project$Data$SelectedSession$fetch,
									model.m,
									streamId,
									A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Main$GotSession)),
									$author$project$Ports$pulseSessionMarker($elm$core$Maybe$Nothing),
									$author$project$Main$getScrollPosition
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 41:
				var position = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{R: position}),
					$author$project$Ports$saveScrollPosition(position));
			case 27:
				var response = msg.a;
				var _v21 = _Utils_Tuple2(model.s, response);
				if ((_v21.a.$ === 3) && (_v21.b.$ === 3)) {
					var thresholds = _v21.a.a;
					var selectedSession = _v21.b.a;
					var newSession = $author$project$Data$SelectedSession$updateFetchedTimeRange(selectedSession);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: A2(
									$author$project$Data$Overlay$update,
									$author$project$Data$Overlay$RemoveOverlay(0),
									model.d),
								g: $krisajenkins$remotedata$RemoteData$Success(newSession)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A5($author$project$Main$graphDrawCmd, thresholds, newSession, model.n, model.i, model.m),
									$author$project$Ports$selectSession(
									$author$project$Data$SelectedSession$formatForJavaScript(newSession))
								])));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: A2(
									$author$project$Data$Overlay$update,
									$author$project$Data$Overlay$RemoveOverlay(0),
									model.d),
								g: response
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 28:
				var response = msg.a;
				var _v22 = _Utils_Tuple3(model.s, model.g, response);
				if (((_v22.a.$ === 3) && (_v22.b.$ === 3)) && (_v22.c.$ === 3)) {
					var session = _v22.b.a;
					var measurements = _v22.c.a;
					var updatedSession = A2($author$project$Data$SelectedSession$updateMeasurements, measurements, session);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: $krisajenkins$remotedata$RemoteData$Success(updatedSession)
							}),
						$author$project$Ports$updateGraphData(
							{
								bA: updatedSession.bA,
								b7: $author$project$Data$SelectedSession$times(updatedSession)
							}));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 29:
				var heatMapThresholds = msg.a;
				var cmd = function () {
					if (heatMapThresholds.$ === 3) {
						var thresholds = heatMapThresholds.a;
						return $author$project$Ports$updateHeatMapThresholds(
							$author$project$Data$HeatMapThresholds$toValues(thresholds));
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{s: heatMapThresholds}),
					cmd);
			case 30:
				var value = msg.a;
				return A3($author$project$Main$updateHeatMapExtreme, model, value, $author$project$Data$HeatMapThresholds$updateMinimum);
			case 31:
				var value = msg.a;
				return A3($author$project$Main$updateHeatMapExtreme, model, value, $author$project$Data$HeatMapThresholds$updateMaximum);
			case 32:
				var _v24 = model.s;
				if (_v24.$ === 3) {
					var thresholds = _v24.a;
					var newThresholds = $author$project$Data$HeatMapThresholds$toDefaults(thresholds);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								s: $krisajenkins$remotedata$RemoteData$Success(newThresholds)
							}),
						$author$project$Ports$updateHeatMapThresholds(
							$author$project$Data$HeatMapThresholds$toValues(newThresholds)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 33:
				var _v25 = _Utils_Tuple2(model.s, model.g);
				if ((_v25.a.$ === 3) && (_v25.b.$ === 3)) {
					var thresholds = _v25.a.a;
					var session = _v25.b.a;
					var newThresholds = A2(
						$author$project$Data$HeatMapThresholds$fitThresholds,
						$author$project$Data$SelectedSession$measurementBounds(session),
						thresholds);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								s: $krisajenkins$remotedata$RemoteData$Success(newThresholds)
							}),
						$author$project$Ports$updateHeatMapThresholds(
							$author$project$Data$HeatMapThresholds$toValues(newThresholds)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 34:
				var values = msg.a;
				var updateThresholdsInModel = function (thresholds) {
					return _Utils_update(
						model,
						{
							s: $krisajenkins$remotedata$RemoteData$Success(
								A2($author$project$Data$HeatMapThresholds$updateFromValues, values, thresholds))
						});
				};
				var updateThresholdsCmd = function (thresholds) {
					return (!_Utils_eq(
						values,
						$author$project$Data$HeatMapThresholds$toValues(thresholds))) ? $author$project$Ports$updateHeatMapThresholds(values) : $elm$core$Platform$Cmd$none;
				};
				var _v26 = _Utils_Tuple2(model.s, model.g);
				if (_v26.a.$ === 3) {
					if (_v26.b.$ === 3) {
						var thresholds = _v26.a.a;
						return _Utils_Tuple2(
							updateThresholdsInModel(thresholds),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										updateThresholdsCmd(thresholds),
										$author$project$Ports$updateGraphYAxis(
										$author$project$Main$toGraphHeatParams(
											A2($author$project$Data$HeatMapThresholds$updateFromValues, values, thresholds)))
									])));
					} else {
						var thresholds = _v26.a.a;
						return _Utils_Tuple2(
							updateThresholdsInModel(thresholds),
							updateThresholdsCmd(thresholds));
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 35:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{G: !model.G}),
					$author$project$Ports$toggleIsSearchOn(!model.G));
			case 36:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ax: true}),
					$elm$core$Platform$Cmd$none);
			case 37:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$fetchSessions(0));
			case 38:
				var sessionMarkerData = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$pulseSessionMarker(sessionMarkerData));
			case 39:
				var times = msg.a;
				var _v27 = model.g;
				if (_v27.$ === 3) {
					var session = _v27.a;
					var newSession = _Utils_update(
						session,
						{al: times});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: $krisajenkins$remotedata$RemoteData$Success(newSession)
							}),
						A3(
							$author$project$Data$SelectedSession$fetchMeasurements,
							newSession,
							A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Main$GotMeasurements),
							$author$project$Ports$updateGraphData));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 40:
				var isShown = msg.a;
				var overlay = isShown ? $author$project$Data$Overlay$AddOverlay(3) : $author$project$Data$Overlay$RemoveOverlay(3);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: A2($author$project$Data$Overlay$update, overlay, model.d)
						}),
					$elm$core$Platform$Cmd$none);
			case 42:
				return _Utils_Tuple2(
					model,
					$author$project$Main$setScrollPosition(model.R));
			case 43:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 44:
				var _int = msg.a;
				return _Utils_eq(_int, model.ad) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{ad: 0}),
					$author$project$Ports$updateResolution(
						51 - $author$project$Data$BoundedInteger$getValue(model.y))) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 45:
				var updateResolution = msg.a;
				return A2($author$project$Main$debounce, updateResolution, model);
			case 46:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: !model.O, aD: false}),
					$author$project$Ports$updateParams(
						{W: 'keepFiltersExpanded', aN: false}));
			case 47:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: false}),
					$author$project$Ports$updateParams(
						{W: 'keepFiltersExpanded', aN: false}));
			case 48:
				var newTheme = $author$project$Data$Theme$toggle(model.E);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{E: newTheme}),
					$author$project$Ports$toggleTheme(
						$author$project$Data$Theme$toString(newTheme)));
			default:
				var cmd = msg.a;
				return _Utils_Tuple2(model, cmd);
		}
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Main$snippetGoogleTagManager = A3(
	$elm$html$Html$node,
	'noscript',
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$elm$html$Html$iframe,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'height', '0'),
					$elm$html$Html$Attributes$src('https://www.googletagmanager.com/ns.html?id=GTM-T948MNX'),
					A2($elm$html$Html$Attributes$attribute, 'style', 'display:none;visibility:hidden'),
					A2($elm$html$Html$Attributes$attribute, 'width', '0')
				]),
			_List_Nil)
		]));
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$virtual_dom$VirtualDom$lazy6 = _VirtualDom_lazy6;
var $elm$html$Html$Lazy$lazy6 = $elm$virtual_dom$VirtualDom$lazy6;
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $author$project$Main$RefreshTimeRange = {$: 7};
var $author$project$Main$ToggleIndoor = function (a) {
	return {$: 22, a: a};
};
var $author$project$Main$ToggleStatus = function (a) {
	return {$: 23, a: a};
};
var $author$project$Tooltip$TooltipText = $elm$core$Basics$identity;
var $author$project$Tooltip$activeToggleFilter = 'Fixed sessions that have submitted a measurement in the past 24 hours are considered active. Fixed sessions that have not submitted measurements in the past 24 hours are considered dormant.';
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$virtual_dom$VirtualDom$lazy5 = _VirtualDom_lazy5;
var $elm$html$Html$Lazy$lazy5 = $elm$virtual_dom$VirtualDom$lazy5;
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Tooltip$profilesFilter = 'Enter a profile name or names to filter the sessions by profile name.';
var $author$project$Tooltip$tagsFilter = 'Enter a tag or tags to filter the sessions by tags. Tags are keywords assigned to sessions.';
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Tooltip$typeToggleFilter = 'Outdoor sessions are recorded by instruments located outdoors. Indoor measurements are recorded by instruments located indoors. Indoor sessions do not include geocoordinates and are therefore not geolocated on the map.';
var $author$project$LabelsInput$UpdateCandidate = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $author$project$LabelsInput$getCandidate = function (_v0) {
	var candidate = _v0.ay;
	return candidate;
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm_community$html_extra$Html$Events$Extra$onEnter = function (onEnterAction) {
	return A2(
		$elm$html$Html$Events$on,
		'keyup',
		A2(
			$elm$json$Json$Decode$andThen,
			function (keyCode) {
				return (keyCode === 13) ? $elm$json$Json$Decode$succeed(onEnterAction) : $elm$json$Json$Decode$fail(
					$elm$core$String$fromInt(keyCode));
			},
			$elm$html$Html$Events$keyCode));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Tooltip$view = function (_v0) {
	var tooltipText = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'data-tippy-content', tooltipText)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tooltip-icon')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('?')
					]))
			]));
};
var $author$project$LabelsInput$Remove = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$LabelsInput$viewLabel = function (label) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tag')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id(label),
						$elm$html$Html$Attributes$class('tag-close'),
						$elm$html$Html$Events$onClick(
						$author$project$LabelsInput$Remove(label))
					]),
				_List_Nil)
			]));
};
var $author$project$LabelsInput$view = F7(
	function (model, text_, inputId, jsClass, placeholderText, isDisabled, tooltipText) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('filters__input-group')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tag-container')
						]),
					A2(
						$elm$core$List$map,
						$author$project$LabelsInput$viewLabel,
						$author$project$LabelsInput$asList(model))),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(inputId),
							$elm$html$Html$Attributes$class(
							A2($elm$core$String$append, jsClass, ' input input--dark input--filters')),
							$elm$html$Html$Attributes$placeholder(placeholderText),
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$name(inputId),
							$elm_community$html_extra$Html$Events$Extra$onEnter(
							$author$project$LabelsInput$Add(
								$author$project$LabelsInput$getCandidate(model))),
							$elm$html$Html$Events$onInput($author$project$LabelsInput$UpdateCandidate),
							$elm$html$Html$Attributes$value(
							$author$project$LabelsInput$getCandidate(model)),
							$elm$html$Html$Attributes$disabled(isDisabled)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label label--filters'),
							$elm$html$Html$Attributes$for(inputId)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(text_)
						])),
					$author$project$Tooltip$view(tooltipText),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('autocomplete-spinner')
						]),
					_List_Nil)
				]));
	});
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel = $elm$html$Html$Attributes$attribute('aria-label');
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Tooltip$timeRangeFilter = 'Enter a time frame to view sessions that include measurements with corresponding timestamps.';
var $author$project$Data$Path$toString = function (_v0) {
	var path = _v0;
	return path;
};
var $author$project$TimeRange$view = F2(
	function (refreshTimeRange, resetIcon) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('filters__input-group')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('time-range'),
							A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
							$elm$html$Html$Attributes$class('input input--dark input--filters input--time js--time-range'),
							$elm$html$Html$Attributes$name('time-range')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('time-range-button'),
							A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
							$elm$html$Html$Attributes$class('input input--dark input--filters input--time button--input js--time-range-button'),
							$elm$html$Html$Attributes$name('time-range-button')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label label--filters u--hide-on-mobile'),
							$elm$html$Html$Attributes$for('time-range')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('time frame:')
						])),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label label--filters u--show-on-mobile')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('time frame:')
						])),
					$author$project$Tooltip$view($author$project$Tooltip$timeRangeFilter),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('Reset time frame'),
							$elm$html$Html$Attributes$class('refresh-timerange-button'),
							$elm$html$Html$Events$onClick(refreshTimeRange)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									$author$project$Data$Path$toString(resetIcon)),
									$elm$html$Html$Attributes$alt('Reset icon')
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Tooltip$locationFilter = 'Enter an address, intersection, or postal code to pan and zoom the map to that location.';
var $author$project$Main$viewLocationFilter = F2(
	function (location, isIndoor) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('filters__input-group')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('location'),
							$elm$html$Html$Attributes$value(location),
							$elm$html$Html$Attributes$class('input input--dark input--filters js--location'),
							$elm$html$Html$Attributes$placeholder('location'),
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$name('location'),
							$elm$html$Html$Attributes$disabled(isIndoor)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label label--filters'),
							$elm$html$Html$Attributes$for('location')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('location:')
						])),
					$author$project$Tooltip$view($author$project$Tooltip$locationFilter)
				]));
	});
var $author$project$Popup$ParameterList = 0;
var $author$project$Main$ShowListPopup = function (a) {
	return {$: 9, a: a};
};
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Popup$preventDefault = function (msg) {
	return {cP: msg, dc: true, dq: true};
};
var $author$project$Popup$clickWithoutDefault = function (msg) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		A2(
			$elm$json$Json$Decode$map,
			$author$project$Popup$preventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $author$project$Popup$isParameterPopupShown = function (popup) {
	if (!popup) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Tooltip$parameterFilter = 'The parameter field describes the broad category of environmental or physiological measurements being recorded. The AirCasting platform is device agnostic i.e. it will ingest and display data from any instrument that formats and communicates data according to our formatting and communications protocol.';
var $elm$core$String$foldl = _String_foldl;
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $kuon$elm_string_normalize$String$Normalize$Diacritics$lookupList = _Utils_ap(
	_List_fromArray(
		[
			_Utils_Tuple2('A', 'A'),
			_Utils_Tuple2('Ⓐ', 'A'),
			_Utils_Tuple2('Ａ', 'A'),
			_Utils_Tuple2('À', 'A'),
			_Utils_Tuple2('Á', 'A'),
			_Utils_Tuple2('Â', 'A'),
			_Utils_Tuple2('Ầ', 'A'),
			_Utils_Tuple2('Ấ', 'A'),
			_Utils_Tuple2('Ẫ', 'A'),
			_Utils_Tuple2('Ẩ', 'A')
		]),
	_Utils_ap(
		_List_fromArray(
			[
				_Utils_Tuple2('Ã', 'A'),
				_Utils_Tuple2('Ā', 'A'),
				_Utils_Tuple2('Ă', 'A'),
				_Utils_Tuple2('Ằ', 'A'),
				_Utils_Tuple2('Ắ', 'A'),
				_Utils_Tuple2('Ẵ', 'A'),
				_Utils_Tuple2('Ẳ', 'A'),
				_Utils_Tuple2('Ȧ', 'A'),
				_Utils_Tuple2('Ǡ', 'A'),
				_Utils_Tuple2('Ä', 'A')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('Ǟ', 'A'),
					_Utils_Tuple2('Ả', 'A'),
					_Utils_Tuple2('Å', 'A'),
					_Utils_Tuple2('Ǻ', 'A'),
					_Utils_Tuple2('Ǎ', 'A'),
					_Utils_Tuple2('Ȁ', 'A'),
					_Utils_Tuple2('Ȃ', 'A'),
					_Utils_Tuple2('Ạ', 'A'),
					_Utils_Tuple2('Ậ', 'A'),
					_Utils_Tuple2('Ặ', 'A')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2('Ḁ', 'A'),
						_Utils_Tuple2('Ą', 'A'),
						_Utils_Tuple2('Ⱥ', 'A'),
						_Utils_Tuple2('Ɐ', 'A'),
						_Utils_Tuple2('Ꜳ', 'AA'),
						_Utils_Tuple2('Æ', 'AE'),
						_Utils_Tuple2('Ǽ', 'AE'),
						_Utils_Tuple2('Ǣ', 'AE'),
						_Utils_Tuple2('Ꜵ', 'AO'),
						_Utils_Tuple2('Ꜷ', 'AU')
					]),
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('Ꜹ', 'AV'),
							_Utils_Tuple2('Ꜻ', 'AV'),
							_Utils_Tuple2('Ꜽ', 'AY'),
							_Utils_Tuple2('B', 'B'),
							_Utils_Tuple2('Ⓑ', 'B'),
							_Utils_Tuple2('Ｂ', 'B'),
							_Utils_Tuple2('Ḃ', 'B'),
							_Utils_Tuple2('Ḅ', 'B'),
							_Utils_Tuple2('Ḇ', 'B'),
							_Utils_Tuple2('Ƀ', 'B')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2('Ƃ', 'B'),
								_Utils_Tuple2('Ɓ', 'B'),
								_Utils_Tuple2('C', 'C'),
								_Utils_Tuple2('Ⓒ', 'C'),
								_Utils_Tuple2('Ｃ', 'C'),
								_Utils_Tuple2('Ć', 'C'),
								_Utils_Tuple2('Ĉ', 'C'),
								_Utils_Tuple2('Ċ', 'C'),
								_Utils_Tuple2('Č', 'C'),
								_Utils_Tuple2('Ç', 'C')
							]),
						_Utils_ap(
							_List_fromArray(
								[
									_Utils_Tuple2('Ḉ', 'C'),
									_Utils_Tuple2('Ƈ', 'C'),
									_Utils_Tuple2('Ȼ', 'C'),
									_Utils_Tuple2('Ꜿ', 'C'),
									_Utils_Tuple2('D', 'D'),
									_Utils_Tuple2('Ⓓ', 'D'),
									_Utils_Tuple2('Ｄ', 'D'),
									_Utils_Tuple2('Ḋ', 'D'),
									_Utils_Tuple2('Ď', 'D'),
									_Utils_Tuple2('Ḍ', 'D')
								]),
							_Utils_ap(
								_List_fromArray(
									[
										_Utils_Tuple2('Ḑ', 'D'),
										_Utils_Tuple2('Ḓ', 'D'),
										_Utils_Tuple2('Ḏ', 'D'),
										_Utils_Tuple2('Đ', 'D'),
										_Utils_Tuple2('Ƌ', 'D'),
										_Utils_Tuple2('Ɗ', 'D'),
										_Utils_Tuple2('Ɖ', 'D'),
										_Utils_Tuple2('Ꝺ', 'D'),
										_Utils_Tuple2('Ǳ', 'DZ'),
										_Utils_Tuple2('Ǆ', 'DZ')
									]),
								_Utils_ap(
									_List_fromArray(
										[
											_Utils_Tuple2('ǲ', 'Dz'),
											_Utils_Tuple2('ǅ', 'Dz'),
											_Utils_Tuple2('E', 'E'),
											_Utils_Tuple2('Ⓔ', 'E'),
											_Utils_Tuple2('Ｅ', 'E'),
											_Utils_Tuple2('È', 'E'),
											_Utils_Tuple2('É', 'E'),
											_Utils_Tuple2('Ê', 'E'),
											_Utils_Tuple2('Ề', 'E'),
											_Utils_Tuple2('Ế', 'E')
										]),
									_Utils_ap(
										_List_fromArray(
											[
												_Utils_Tuple2('Ễ', 'E'),
												_Utils_Tuple2('Ể', 'E'),
												_Utils_Tuple2('Ẽ', 'E'),
												_Utils_Tuple2('Ē', 'E'),
												_Utils_Tuple2('Ḕ', 'E'),
												_Utils_Tuple2('Ḗ', 'E'),
												_Utils_Tuple2('Ĕ', 'E'),
												_Utils_Tuple2('Ė', 'E'),
												_Utils_Tuple2('Ë', 'E'),
												_Utils_Tuple2('Ẻ', 'E')
											]),
										_Utils_ap(
											_List_fromArray(
												[
													_Utils_Tuple2('Ě', 'E'),
													_Utils_Tuple2('Ȅ', 'E'),
													_Utils_Tuple2('Ȇ', 'E'),
													_Utils_Tuple2('Ẹ', 'E'),
													_Utils_Tuple2('Ệ', 'E'),
													_Utils_Tuple2('Ȩ', 'E'),
													_Utils_Tuple2('Ḝ', 'E'),
													_Utils_Tuple2('Ę', 'E'),
													_Utils_Tuple2('Ḙ', 'E'),
													_Utils_Tuple2('Ḛ', 'E')
												]),
											_Utils_ap(
												_List_fromArray(
													[
														_Utils_Tuple2('Ɛ', 'E'),
														_Utils_Tuple2('Ǝ', 'E'),
														_Utils_Tuple2('F', 'F'),
														_Utils_Tuple2('Ⓕ', 'F'),
														_Utils_Tuple2('Ｆ', 'F'),
														_Utils_Tuple2('Ḟ', 'F'),
														_Utils_Tuple2('Ƒ', 'F'),
														_Utils_Tuple2('Ꝼ', 'F'),
														_Utils_Tuple2('G', 'G'),
														_Utils_Tuple2('Ⓖ', 'G')
													]),
												_Utils_ap(
													_List_fromArray(
														[
															_Utils_Tuple2('Ｇ', 'G'),
															_Utils_Tuple2('Ǵ', 'G'),
															_Utils_Tuple2('Ĝ', 'G'),
															_Utils_Tuple2('Ḡ', 'G'),
															_Utils_Tuple2('Ğ', 'G'),
															_Utils_Tuple2('Ġ', 'G'),
															_Utils_Tuple2('Ǧ', 'G'),
															_Utils_Tuple2('Ģ', 'G'),
															_Utils_Tuple2('Ǥ', 'G'),
															_Utils_Tuple2('Ɠ', 'G')
														]),
													_Utils_ap(
														_List_fromArray(
															[
																_Utils_Tuple2('Ꞡ', 'G'),
																_Utils_Tuple2('Ᵹ', 'G'),
																_Utils_Tuple2('Ꝿ', 'G'),
																_Utils_Tuple2('H', 'H'),
																_Utils_Tuple2('Ⓗ', 'H'),
																_Utils_Tuple2('Ｈ', 'H'),
																_Utils_Tuple2('Ĥ', 'H'),
																_Utils_Tuple2('Ḣ', 'H'),
																_Utils_Tuple2('Ḧ', 'H'),
																_Utils_Tuple2('Ȟ', 'H')
															]),
														_Utils_ap(
															_List_fromArray(
																[
																	_Utils_Tuple2('Ḥ', 'H'),
																	_Utils_Tuple2('Ḩ', 'H'),
																	_Utils_Tuple2('Ḫ', 'H'),
																	_Utils_Tuple2('Ħ', 'H'),
																	_Utils_Tuple2('Ⱨ', 'H'),
																	_Utils_Tuple2('Ⱶ', 'H'),
																	_Utils_Tuple2('Ɥ', 'H'),
																	_Utils_Tuple2('I', 'I'),
																	_Utils_Tuple2('Ⓘ', 'I'),
																	_Utils_Tuple2('Ｉ', 'I')
																]),
															_Utils_ap(
																_List_fromArray(
																	[
																		_Utils_Tuple2('Ì', 'I'),
																		_Utils_Tuple2('Í', 'I'),
																		_Utils_Tuple2('Î', 'I'),
																		_Utils_Tuple2('Ĩ', 'I'),
																		_Utils_Tuple2('Ī', 'I'),
																		_Utils_Tuple2('Ĭ', 'I'),
																		_Utils_Tuple2('İ', 'I'),
																		_Utils_Tuple2('Ï', 'I'),
																		_Utils_Tuple2('Ḯ', 'I'),
																		_Utils_Tuple2('Ỉ', 'I')
																	]),
																_Utils_ap(
																	_List_fromArray(
																		[
																			_Utils_Tuple2('Ǐ', 'I'),
																			_Utils_Tuple2('Ȉ', 'I'),
																			_Utils_Tuple2('Ȋ', 'I'),
																			_Utils_Tuple2('Ị', 'I'),
																			_Utils_Tuple2('Į', 'I'),
																			_Utils_Tuple2('Ḭ', 'I'),
																			_Utils_Tuple2('Ɨ', 'I'),
																			_Utils_Tuple2('J', 'J'),
																			_Utils_Tuple2('Ⓙ', 'J'),
																			_Utils_Tuple2('Ｊ', 'J')
																		]),
																	_Utils_ap(
																		_List_fromArray(
																			[
																				_Utils_Tuple2('Ĵ', 'J'),
																				_Utils_Tuple2('Ɉ', 'J'),
																				_Utils_Tuple2('K', 'K'),
																				_Utils_Tuple2('Ⓚ', 'K'),
																				_Utils_Tuple2('Ｋ', 'K'),
																				_Utils_Tuple2('Ḱ', 'K'),
																				_Utils_Tuple2('Ǩ', 'K'),
																				_Utils_Tuple2('Ḳ', 'K'),
																				_Utils_Tuple2('Ķ', 'K'),
																				_Utils_Tuple2('Ḵ', 'K')
																			]),
																		_Utils_ap(
																			_List_fromArray(
																				[
																					_Utils_Tuple2('Ƙ', 'K'),
																					_Utils_Tuple2('Ⱪ', 'K'),
																					_Utils_Tuple2('Ꝁ', 'K'),
																					_Utils_Tuple2('Ꝃ', 'K'),
																					_Utils_Tuple2('Ꝅ', 'K'),
																					_Utils_Tuple2('Ꞣ', 'K'),
																					_Utils_Tuple2('L', 'L'),
																					_Utils_Tuple2('Ⓛ', 'L'),
																					_Utils_Tuple2('Ｌ', 'L'),
																					_Utils_Tuple2('Ŀ', 'L')
																				]),
																			_Utils_ap(
																				_List_fromArray(
																					[
																						_Utils_Tuple2('Ĺ', 'L'),
																						_Utils_Tuple2('Ľ', 'L'),
																						_Utils_Tuple2('Ḷ', 'L'),
																						_Utils_Tuple2('Ḹ', 'L'),
																						_Utils_Tuple2('Ļ', 'L'),
																						_Utils_Tuple2('Ḽ', 'L'),
																						_Utils_Tuple2('Ḻ', 'L'),
																						_Utils_Tuple2('Ł', 'L'),
																						_Utils_Tuple2('Ƚ', 'L'),
																						_Utils_Tuple2('Ɫ', 'L')
																					]),
																				_Utils_ap(
																					_List_fromArray(
																						[
																							_Utils_Tuple2('Ⱡ', 'L'),
																							_Utils_Tuple2('Ꝉ', 'L'),
																							_Utils_Tuple2('Ꝇ', 'L'),
																							_Utils_Tuple2('Ꞁ', 'L'),
																							_Utils_Tuple2('Ǉ', 'LJ'),
																							_Utils_Tuple2('ǈ', 'Lj'),
																							_Utils_Tuple2('M', 'M'),
																							_Utils_Tuple2('Ⓜ', 'M'),
																							_Utils_Tuple2('Ｍ', 'M'),
																							_Utils_Tuple2('Ḿ', 'M')
																						]),
																					_Utils_ap(
																						_List_fromArray(
																							[
																								_Utils_Tuple2('Ṁ', 'M'),
																								_Utils_Tuple2('Ṃ', 'M'),
																								_Utils_Tuple2('Ɱ', 'M'),
																								_Utils_Tuple2('Ɯ', 'M'),
																								_Utils_Tuple2('N', 'N'),
																								_Utils_Tuple2('Ⓝ', 'N'),
																								_Utils_Tuple2('Ｎ', 'N'),
																								_Utils_Tuple2('Ǹ', 'N'),
																								_Utils_Tuple2('Ń', 'N'),
																								_Utils_Tuple2('Ñ', 'N')
																							]),
																						_Utils_ap(
																							_List_fromArray(
																								[
																									_Utils_Tuple2('Ṅ', 'N'),
																									_Utils_Tuple2('Ň', 'N'),
																									_Utils_Tuple2('Ṇ', 'N'),
																									_Utils_Tuple2('Ņ', 'N'),
																									_Utils_Tuple2('Ṋ', 'N'),
																									_Utils_Tuple2('Ṉ', 'N'),
																									_Utils_Tuple2('Ƞ', 'N'),
																									_Utils_Tuple2('Ɲ', 'N'),
																									_Utils_Tuple2('Ꞑ', 'N'),
																									_Utils_Tuple2('Ꞥ', 'N')
																								]),
																							_Utils_ap(
																								_List_fromArray(
																									[
																										_Utils_Tuple2('Ǌ', 'NJ'),
																										_Utils_Tuple2('ǋ', 'Nj'),
																										_Utils_Tuple2('O', 'O'),
																										_Utils_Tuple2('Ⓞ', 'O'),
																										_Utils_Tuple2('Ｏ', 'O'),
																										_Utils_Tuple2('Ò', 'O'),
																										_Utils_Tuple2('Ó', 'O'),
																										_Utils_Tuple2('Ô', 'O'),
																										_Utils_Tuple2('Ồ', 'O'),
																										_Utils_Tuple2('Ố', 'O')
																									]),
																								_Utils_ap(
																									_List_fromArray(
																										[
																											_Utils_Tuple2('Ỗ', 'O'),
																											_Utils_Tuple2('Ổ', 'O'),
																											_Utils_Tuple2('Õ', 'O'),
																											_Utils_Tuple2('Ṍ', 'O'),
																											_Utils_Tuple2('Ȭ', 'O'),
																											_Utils_Tuple2('Ṏ', 'O'),
																											_Utils_Tuple2('Ō', 'O'),
																											_Utils_Tuple2('Ṑ', 'O'),
																											_Utils_Tuple2('Ṓ', 'O'),
																											_Utils_Tuple2('Ŏ', 'O')
																										]),
																									_Utils_ap(
																										_List_fromArray(
																											[
																												_Utils_Tuple2('Ȯ', 'O'),
																												_Utils_Tuple2('Ȱ', 'O'),
																												_Utils_Tuple2('Ö', 'O'),
																												_Utils_Tuple2('Ȫ', 'O'),
																												_Utils_Tuple2('Ỏ', 'O'),
																												_Utils_Tuple2('Ő', 'O'),
																												_Utils_Tuple2('Ǒ', 'O'),
																												_Utils_Tuple2('Ȍ', 'O'),
																												_Utils_Tuple2('Ȏ', 'O'),
																												_Utils_Tuple2('Ơ', 'O')
																											]),
																										_Utils_ap(
																											_List_fromArray(
																												[
																													_Utils_Tuple2('Ờ', 'O'),
																													_Utils_Tuple2('Ớ', 'O'),
																													_Utils_Tuple2('Ỡ', 'O'),
																													_Utils_Tuple2('Ở', 'O'),
																													_Utils_Tuple2('Ợ', 'O'),
																													_Utils_Tuple2('Ọ', 'O'),
																													_Utils_Tuple2('Ộ', 'O'),
																													_Utils_Tuple2('Ǫ', 'O'),
																													_Utils_Tuple2('Ǭ', 'O'),
																													_Utils_Tuple2('Ø', 'O')
																												]),
																											_Utils_ap(
																												_List_fromArray(
																													[
																														_Utils_Tuple2('Ǿ', 'O'),
																														_Utils_Tuple2('Ɔ', 'O'),
																														_Utils_Tuple2('Ɵ', 'O'),
																														_Utils_Tuple2('Ꝋ', 'O'),
																														_Utils_Tuple2('Ꝍ', 'O'),
																														_Utils_Tuple2('Ƣ', 'OI'),
																														_Utils_Tuple2('Ꝏ', 'OO'),
																														_Utils_Tuple2('Ȣ', 'OU'),
																														_Utils_Tuple2('\u008C', 'OE'),
																														_Utils_Tuple2('Œ', 'OE')
																													]),
																												_Utils_ap(
																													_List_fromArray(
																														[
																															_Utils_Tuple2('\u009C', 'oe'),
																															_Utils_Tuple2('œ', 'oe'),
																															_Utils_Tuple2('P', 'P'),
																															_Utils_Tuple2('Ⓟ', 'P'),
																															_Utils_Tuple2('Ｐ', 'P'),
																															_Utils_Tuple2('Ṕ', 'P'),
																															_Utils_Tuple2('Ṗ', 'P'),
																															_Utils_Tuple2('Ƥ', 'P'),
																															_Utils_Tuple2('Ᵽ', 'P'),
																															_Utils_Tuple2('Ꝑ', 'P')
																														]),
																													_Utils_ap(
																														_List_fromArray(
																															[
																																_Utils_Tuple2('Ꝓ', 'P'),
																																_Utils_Tuple2('Ꝕ', 'P'),
																																_Utils_Tuple2('Q', 'Q'),
																																_Utils_Tuple2('Ⓠ', 'Q'),
																																_Utils_Tuple2('Ｑ', 'Q'),
																																_Utils_Tuple2('Ꝗ', 'Q'),
																																_Utils_Tuple2('Ꝙ', 'Q'),
																																_Utils_Tuple2('Ɋ', 'Q'),
																																_Utils_Tuple2('R', 'R'),
																																_Utils_Tuple2('Ⓡ', 'R')
																															]),
																														_Utils_ap(
																															_List_fromArray(
																																[
																																	_Utils_Tuple2('Ｒ', 'R'),
																																	_Utils_Tuple2('Ŕ', 'R'),
																																	_Utils_Tuple2('Ṙ', 'R'),
																																	_Utils_Tuple2('Ř', 'R'),
																																	_Utils_Tuple2('Ȑ', 'R'),
																																	_Utils_Tuple2('Ȓ', 'R'),
																																	_Utils_Tuple2('Ṛ', 'R'),
																																	_Utils_Tuple2('Ṝ', 'R'),
																																	_Utils_Tuple2('Ŗ', 'R'),
																																	_Utils_Tuple2('Ṟ', 'R')
																																]),
																															_Utils_ap(
																																_List_fromArray(
																																	[
																																		_Utils_Tuple2('Ɍ', 'R'),
																																		_Utils_Tuple2('Ɽ', 'R'),
																																		_Utils_Tuple2('Ꝛ', 'R'),
																																		_Utils_Tuple2('Ꞧ', 'R'),
																																		_Utils_Tuple2('Ꞃ', 'R'),
																																		_Utils_Tuple2('S', 'S'),
																																		_Utils_Tuple2('Ⓢ', 'S'),
																																		_Utils_Tuple2('Ｓ', 'S'),
																																		_Utils_Tuple2('ẞ', 'S'),
																																		_Utils_Tuple2('Ś', 'S')
																																	]),
																																_Utils_ap(
																																	_List_fromArray(
																																		[
																																			_Utils_Tuple2('Ṥ', 'S'),
																																			_Utils_Tuple2('Ŝ', 'S'),
																																			_Utils_Tuple2('Ṡ', 'S'),
																																			_Utils_Tuple2('Š', 'S'),
																																			_Utils_Tuple2('Ṧ', 'S'),
																																			_Utils_Tuple2('Ṣ', 'S'),
																																			_Utils_Tuple2('Ṩ', 'S'),
																																			_Utils_Tuple2('Ș', 'S'),
																																			_Utils_Tuple2('Ş', 'S'),
																																			_Utils_Tuple2('Ȿ', 'S')
																																		]),
																																	_Utils_ap(
																																		_List_fromArray(
																																			[
																																				_Utils_Tuple2('Ꞩ', 'S'),
																																				_Utils_Tuple2('Ꞅ', 'S'),
																																				_Utils_Tuple2('T', 'T'),
																																				_Utils_Tuple2('Ⓣ', 'T'),
																																				_Utils_Tuple2('Ｔ', 'T'),
																																				_Utils_Tuple2('Ṫ', 'T'),
																																				_Utils_Tuple2('Ť', 'T'),
																																				_Utils_Tuple2('Ṭ', 'T'),
																																				_Utils_Tuple2('Ț', 'T'),
																																				_Utils_Tuple2('Ţ', 'T')
																																			]),
																																		_Utils_ap(
																																			_List_fromArray(
																																				[
																																					_Utils_Tuple2('Ṱ', 'T'),
																																					_Utils_Tuple2('Ṯ', 'T'),
																																					_Utils_Tuple2('Ŧ', 'T'),
																																					_Utils_Tuple2('Ƭ', 'T'),
																																					_Utils_Tuple2('Ʈ', 'T'),
																																					_Utils_Tuple2('Ⱦ', 'T'),
																																					_Utils_Tuple2('Ꞇ', 'T'),
																																					_Utils_Tuple2('Ꜩ', 'TZ'),
																																					_Utils_Tuple2('U', 'U'),
																																					_Utils_Tuple2('Ⓤ', 'U')
																																				]),
																																			_Utils_ap(
																																				_List_fromArray(
																																					[
																																						_Utils_Tuple2('Ｕ', 'U'),
																																						_Utils_Tuple2('Ù', 'U'),
																																						_Utils_Tuple2('Ú', 'U'),
																																						_Utils_Tuple2('Û', 'U'),
																																						_Utils_Tuple2('Ũ', 'U'),
																																						_Utils_Tuple2('Ṹ', 'U'),
																																						_Utils_Tuple2('Ū', 'U'),
																																						_Utils_Tuple2('Ṻ', 'U'),
																																						_Utils_Tuple2('Ŭ', 'U'),
																																						_Utils_Tuple2('Ü', 'U')
																																					]),
																																				_Utils_ap(
																																					_List_fromArray(
																																						[
																																							_Utils_Tuple2('Ǜ', 'U'),
																																							_Utils_Tuple2('Ǘ', 'U'),
																																							_Utils_Tuple2('Ǖ', 'U'),
																																							_Utils_Tuple2('Ǚ', 'U'),
																																							_Utils_Tuple2('Ủ', 'U'),
																																							_Utils_Tuple2('Ů', 'U'),
																																							_Utils_Tuple2('Ű', 'U'),
																																							_Utils_Tuple2('Ǔ', 'U'),
																																							_Utils_Tuple2('Ȕ', 'U'),
																																							_Utils_Tuple2('Ȗ', 'U')
																																						]),
																																					_Utils_ap(
																																						_List_fromArray(
																																							[
																																								_Utils_Tuple2('Ư', 'U'),
																																								_Utils_Tuple2('Ừ', 'U'),
																																								_Utils_Tuple2('Ứ', 'U'),
																																								_Utils_Tuple2('Ữ', 'U'),
																																								_Utils_Tuple2('Ử', 'U'),
																																								_Utils_Tuple2('Ự', 'U'),
																																								_Utils_Tuple2('Ụ', 'U'),
																																								_Utils_Tuple2('Ṳ', 'U'),
																																								_Utils_Tuple2('Ų', 'U'),
																																								_Utils_Tuple2('Ṷ', 'U')
																																							]),
																																						_Utils_ap(
																																							_List_fromArray(
																																								[
																																									_Utils_Tuple2('Ṵ', 'U'),
																																									_Utils_Tuple2('Ʉ', 'U'),
																																									_Utils_Tuple2('V', 'V'),
																																									_Utils_Tuple2('Ⓥ', 'V'),
																																									_Utils_Tuple2('Ｖ', 'V'),
																																									_Utils_Tuple2('Ṽ', 'V'),
																																									_Utils_Tuple2('Ṿ', 'V'),
																																									_Utils_Tuple2('Ʋ', 'V'),
																																									_Utils_Tuple2('Ꝟ', 'V'),
																																									_Utils_Tuple2('Ʌ', 'V')
																																								]),
																																							_Utils_ap(
																																								_List_fromArray(
																																									[
																																										_Utils_Tuple2('Ꝡ', 'VY'),
																																										_Utils_Tuple2('W', 'W'),
																																										_Utils_Tuple2('Ⓦ', 'W'),
																																										_Utils_Tuple2('Ｗ', 'W'),
																																										_Utils_Tuple2('Ẁ', 'W'),
																																										_Utils_Tuple2('Ẃ', 'W'),
																																										_Utils_Tuple2('Ŵ', 'W'),
																																										_Utils_Tuple2('Ẇ', 'W'),
																																										_Utils_Tuple2('Ẅ', 'W'),
																																										_Utils_Tuple2('Ẉ', 'W')
																																									]),
																																								_Utils_ap(
																																									_List_fromArray(
																																										[
																																											_Utils_Tuple2('Ⱳ', 'W'),
																																											_Utils_Tuple2('X', 'X'),
																																											_Utils_Tuple2('Ⓧ', 'X'),
																																											_Utils_Tuple2('Ｘ', 'X'),
																																											_Utils_Tuple2('Ẋ', 'X'),
																																											_Utils_Tuple2('Ẍ', 'X'),
																																											_Utils_Tuple2('Y', 'Y'),
																																											_Utils_Tuple2('Ⓨ', 'Y'),
																																											_Utils_Tuple2('Ｙ', 'Y'),
																																											_Utils_Tuple2('Ỳ', 'Y')
																																										]),
																																									_Utils_ap(
																																										_List_fromArray(
																																											[
																																												_Utils_Tuple2('Ý', 'Y'),
																																												_Utils_Tuple2('Ŷ', 'Y'),
																																												_Utils_Tuple2('Ỹ', 'Y'),
																																												_Utils_Tuple2('Ȳ', 'Y'),
																																												_Utils_Tuple2('Ẏ', 'Y'),
																																												_Utils_Tuple2('Ÿ', 'Y'),
																																												_Utils_Tuple2('Ỷ', 'Y'),
																																												_Utils_Tuple2('Ỵ', 'Y'),
																																												_Utils_Tuple2('Ƴ', 'Y'),
																																												_Utils_Tuple2('Ɏ', 'Y')
																																											]),
																																										_Utils_ap(
																																											_List_fromArray(
																																												[
																																													_Utils_Tuple2('Ỿ', 'Y'),
																																													_Utils_Tuple2('Z', 'Z'),
																																													_Utils_Tuple2('Ⓩ', 'Z'),
																																													_Utils_Tuple2('Ｚ', 'Z'),
																																													_Utils_Tuple2('Ź', 'Z'),
																																													_Utils_Tuple2('Ẑ', 'Z'),
																																													_Utils_Tuple2('Ż', 'Z'),
																																													_Utils_Tuple2('Ž', 'Z'),
																																													_Utils_Tuple2('Ẓ', 'Z'),
																																													_Utils_Tuple2('Ẕ', 'Z')
																																												]),
																																											_Utils_ap(
																																												_List_fromArray(
																																													[
																																														_Utils_Tuple2('Ƶ', 'Z'),
																																														_Utils_Tuple2('Ȥ', 'Z'),
																																														_Utils_Tuple2('Ɀ', 'Z'),
																																														_Utils_Tuple2('Ⱬ', 'Z'),
																																														_Utils_Tuple2('Ꝣ', 'Z'),
																																														_Utils_Tuple2('a', 'a'),
																																														_Utils_Tuple2('ⓐ', 'a'),
																																														_Utils_Tuple2('ａ', 'a'),
																																														_Utils_Tuple2('ẚ', 'a'),
																																														_Utils_Tuple2('à', 'a')
																																													]),
																																												_Utils_ap(
																																													_List_fromArray(
																																														[
																																															_Utils_Tuple2('á', 'a'),
																																															_Utils_Tuple2('â', 'a'),
																																															_Utils_Tuple2('ầ', 'a'),
																																															_Utils_Tuple2('ấ', 'a'),
																																															_Utils_Tuple2('ẫ', 'a'),
																																															_Utils_Tuple2('ẩ', 'a'),
																																															_Utils_Tuple2('ã', 'a'),
																																															_Utils_Tuple2('ā', 'a'),
																																															_Utils_Tuple2('ă', 'a'),
																																															_Utils_Tuple2('ằ', 'a')
																																														]),
																																													_Utils_ap(
																																														_List_fromArray(
																																															[
																																																_Utils_Tuple2('ắ', 'a'),
																																																_Utils_Tuple2('ẵ', 'a'),
																																																_Utils_Tuple2('ẳ', 'a'),
																																																_Utils_Tuple2('ȧ', 'a'),
																																																_Utils_Tuple2('ǡ', 'a'),
																																																_Utils_Tuple2('ä', 'a'),
																																																_Utils_Tuple2('ǟ', 'a'),
																																																_Utils_Tuple2('ả', 'a'),
																																																_Utils_Tuple2('å', 'a'),
																																																_Utils_Tuple2('ǻ', 'a')
																																															]),
																																														_Utils_ap(
																																															_List_fromArray(
																																																[
																																																	_Utils_Tuple2('ǎ', 'a'),
																																																	_Utils_Tuple2('ȁ', 'a'),
																																																	_Utils_Tuple2('ȃ', 'a'),
																																																	_Utils_Tuple2('ạ', 'a'),
																																																	_Utils_Tuple2('ậ', 'a'),
																																																	_Utils_Tuple2('ặ', 'a'),
																																																	_Utils_Tuple2('ḁ', 'a'),
																																																	_Utils_Tuple2('ą', 'a'),
																																																	_Utils_Tuple2('ⱥ', 'a'),
																																																	_Utils_Tuple2('ɐ', 'a')
																																																]),
																																															_Utils_ap(
																																																_List_fromArray(
																																																	[
																																																		_Utils_Tuple2('ꜳ', 'aa'),
																																																		_Utils_Tuple2('æ', 'ae'),
																																																		_Utils_Tuple2('ǽ', 'ae'),
																																																		_Utils_Tuple2('ǣ', 'ae'),
																																																		_Utils_Tuple2('ꜵ', 'ao'),
																																																		_Utils_Tuple2('ꜷ', 'au'),
																																																		_Utils_Tuple2('ꜹ', 'av'),
																																																		_Utils_Tuple2('ꜻ', 'av'),
																																																		_Utils_Tuple2('ꜽ', 'ay'),
																																																		_Utils_Tuple2('b', 'b')
																																																	]),
																																																_Utils_ap(
																																																	_List_fromArray(
																																																		[
																																																			_Utils_Tuple2('ⓑ', 'b'),
																																																			_Utils_Tuple2('ｂ', 'b'),
																																																			_Utils_Tuple2('ḃ', 'b'),
																																																			_Utils_Tuple2('ḅ', 'b'),
																																																			_Utils_Tuple2('ḇ', 'b'),
																																																			_Utils_Tuple2('ƀ', 'b'),
																																																			_Utils_Tuple2('ƃ', 'b'),
																																																			_Utils_Tuple2('ɓ', 'b'),
																																																			_Utils_Tuple2('c', 'c'),
																																																			_Utils_Tuple2('ⓒ', 'c')
																																																		]),
																																																	_Utils_ap(
																																																		_List_fromArray(
																																																			[
																																																				_Utils_Tuple2('ｃ', 'c'),
																																																				_Utils_Tuple2('ć', 'c'),
																																																				_Utils_Tuple2('ĉ', 'c'),
																																																				_Utils_Tuple2('ċ', 'c'),
																																																				_Utils_Tuple2('č', 'c'),
																																																				_Utils_Tuple2('ç', 'c'),
																																																				_Utils_Tuple2('ḉ', 'c'),
																																																				_Utils_Tuple2('ƈ', 'c'),
																																																				_Utils_Tuple2('ȼ', 'c'),
																																																				_Utils_Tuple2('ꜿ', 'c')
																																																			]),
																																																		_Utils_ap(
																																																			_List_fromArray(
																																																				[
																																																					_Utils_Tuple2('ↄ', 'c'),
																																																					_Utils_Tuple2('d', 'd'),
																																																					_Utils_Tuple2('ⓓ', 'd'),
																																																					_Utils_Tuple2('ｄ', 'd'),
																																																					_Utils_Tuple2('ḋ', 'd'),
																																																					_Utils_Tuple2('ď', 'd'),
																																																					_Utils_Tuple2('ḍ', 'd'),
																																																					_Utils_Tuple2('ḑ', 'd'),
																																																					_Utils_Tuple2('ḓ', 'd'),
																																																					_Utils_Tuple2('ḏ', 'd')
																																																				]),
																																																			_Utils_ap(
																																																				_List_fromArray(
																																																					[
																																																						_Utils_Tuple2('đ', 'd'),
																																																						_Utils_Tuple2('ƌ', 'd'),
																																																						_Utils_Tuple2('ɖ', 'd'),
																																																						_Utils_Tuple2('ɗ', 'd'),
																																																						_Utils_Tuple2('ꝺ', 'd'),
																																																						_Utils_Tuple2('ǳ', 'dz'),
																																																						_Utils_Tuple2('ǆ', 'dz'),
																																																						_Utils_Tuple2('e', 'e'),
																																																						_Utils_Tuple2('ⓔ', 'e'),
																																																						_Utils_Tuple2('ｅ', 'e')
																																																					]),
																																																				_Utils_ap(
																																																					_List_fromArray(
																																																						[
																																																							_Utils_Tuple2('è', 'e'),
																																																							_Utils_Tuple2('é', 'e'),
																																																							_Utils_Tuple2('ê', 'e'),
																																																							_Utils_Tuple2('ề', 'e'),
																																																							_Utils_Tuple2('ế', 'e'),
																																																							_Utils_Tuple2('ễ', 'e'),
																																																							_Utils_Tuple2('ể', 'e'),
																																																							_Utils_Tuple2('ẽ', 'e'),
																																																							_Utils_Tuple2('ē', 'e'),
																																																							_Utils_Tuple2('ḕ', 'e')
																																																						]),
																																																					_Utils_ap(
																																																						_List_fromArray(
																																																							[
																																																								_Utils_Tuple2('ḗ', 'e'),
																																																								_Utils_Tuple2('ĕ', 'e'),
																																																								_Utils_Tuple2('ė', 'e'),
																																																								_Utils_Tuple2('ë', 'e'),
																																																								_Utils_Tuple2('ẻ', 'e'),
																																																								_Utils_Tuple2('ě', 'e'),
																																																								_Utils_Tuple2('ȅ', 'e'),
																																																								_Utils_Tuple2('ȇ', 'e'),
																																																								_Utils_Tuple2('ẹ', 'e'),
																																																								_Utils_Tuple2('ệ', 'e')
																																																							]),
																																																						_Utils_ap(
																																																							_List_fromArray(
																																																								[
																																																									_Utils_Tuple2('ȩ', 'e'),
																																																									_Utils_Tuple2('ḝ', 'e'),
																																																									_Utils_Tuple2('ę', 'e'),
																																																									_Utils_Tuple2('ḙ', 'e'),
																																																									_Utils_Tuple2('ḛ', 'e'),
																																																									_Utils_Tuple2('ɇ', 'e'),
																																																									_Utils_Tuple2('ɛ', 'e'),
																																																									_Utils_Tuple2('ǝ', 'e'),
																																																									_Utils_Tuple2('f', 'f'),
																																																									_Utils_Tuple2('ⓕ', 'f')
																																																								]),
																																																							_Utils_ap(
																																																								_List_fromArray(
																																																									[
																																																										_Utils_Tuple2('ｆ', 'f'),
																																																										_Utils_Tuple2('ḟ', 'f'),
																																																										_Utils_Tuple2('ƒ', 'f'),
																																																										_Utils_Tuple2('ꝼ', 'f'),
																																																										_Utils_Tuple2('g', 'g'),
																																																										_Utils_Tuple2('ⓖ', 'g'),
																																																										_Utils_Tuple2('ｇ', 'g'),
																																																										_Utils_Tuple2('ǵ', 'g'),
																																																										_Utils_Tuple2('ĝ', 'g'),
																																																										_Utils_Tuple2('ḡ', 'g')
																																																									]),
																																																								_Utils_ap(
																																																									_List_fromArray(
																																																										[
																																																											_Utils_Tuple2('ğ', 'g'),
																																																											_Utils_Tuple2('ġ', 'g'),
																																																											_Utils_Tuple2('ǧ', 'g'),
																																																											_Utils_Tuple2('ģ', 'g'),
																																																											_Utils_Tuple2('ǥ', 'g'),
																																																											_Utils_Tuple2('ɠ', 'g'),
																																																											_Utils_Tuple2('ꞡ', 'g'),
																																																											_Utils_Tuple2('ᵹ', 'g'),
																																																											_Utils_Tuple2('ꝿ', 'g'),
																																																											_Utils_Tuple2('h', 'h')
																																																										]),
																																																									_Utils_ap(
																																																										_List_fromArray(
																																																											[
																																																												_Utils_Tuple2('ⓗ', 'h'),
																																																												_Utils_Tuple2('ｈ', 'h'),
																																																												_Utils_Tuple2('ĥ', 'h'),
																																																												_Utils_Tuple2('ḣ', 'h'),
																																																												_Utils_Tuple2('ḧ', 'h'),
																																																												_Utils_Tuple2('ȟ', 'h'),
																																																												_Utils_Tuple2('ḥ', 'h'),
																																																												_Utils_Tuple2('ḩ', 'h'),
																																																												_Utils_Tuple2('ḫ', 'h'),
																																																												_Utils_Tuple2('ẖ', 'h')
																																																											]),
																																																										_Utils_ap(
																																																											_List_fromArray(
																																																												[
																																																													_Utils_Tuple2('ħ', 'h'),
																																																													_Utils_Tuple2('ⱨ', 'h'),
																																																													_Utils_Tuple2('ⱶ', 'h'),
																																																													_Utils_Tuple2('ɥ', 'h'),
																																																													_Utils_Tuple2('ƕ', 'hv'),
																																																													_Utils_Tuple2('i', 'i'),
																																																													_Utils_Tuple2('ⓘ', 'i'),
																																																													_Utils_Tuple2('ｉ', 'i'),
																																																													_Utils_Tuple2('ì', 'i'),
																																																													_Utils_Tuple2('í', 'i')
																																																												]),
																																																											_Utils_ap(
																																																												_List_fromArray(
																																																													[
																																																														_Utils_Tuple2('î', 'i'),
																																																														_Utils_Tuple2('ĩ', 'i'),
																																																														_Utils_Tuple2('ī', 'i'),
																																																														_Utils_Tuple2('ĭ', 'i'),
																																																														_Utils_Tuple2('ï', 'i'),
																																																														_Utils_Tuple2('ḯ', 'i'),
																																																														_Utils_Tuple2('ỉ', 'i'),
																																																														_Utils_Tuple2('ǐ', 'i'),
																																																														_Utils_Tuple2('ȉ', 'i'),
																																																														_Utils_Tuple2('ȋ', 'i')
																																																													]),
																																																												_Utils_ap(
																																																													_List_fromArray(
																																																														[
																																																															_Utils_Tuple2('ị', 'i'),
																																																															_Utils_Tuple2('į', 'i'),
																																																															_Utils_Tuple2('ḭ', 'i'),
																																																															_Utils_Tuple2('ɨ', 'i'),
																																																															_Utils_Tuple2('ı', 'i'),
																																																															_Utils_Tuple2('j', 'j'),
																																																															_Utils_Tuple2('ⓙ', 'j'),
																																																															_Utils_Tuple2('ｊ', 'j'),
																																																															_Utils_Tuple2('ĵ', 'j'),
																																																															_Utils_Tuple2('ǰ', 'j')
																																																														]),
																																																													_Utils_ap(
																																																														_List_fromArray(
																																																															[
																																																																_Utils_Tuple2('ɉ', 'j'),
																																																																_Utils_Tuple2('k', 'k'),
																																																																_Utils_Tuple2('ⓚ', 'k'),
																																																																_Utils_Tuple2('ｋ', 'k'),
																																																																_Utils_Tuple2('ḱ', 'k'),
																																																																_Utils_Tuple2('ǩ', 'k'),
																																																																_Utils_Tuple2('ḳ', 'k'),
																																																																_Utils_Tuple2('ķ', 'k'),
																																																																_Utils_Tuple2('ḵ', 'k'),
																																																																_Utils_Tuple2('ƙ', 'k')
																																																															]),
																																																														_Utils_ap(
																																																															_List_fromArray(
																																																																[
																																																																	_Utils_Tuple2('ⱪ', 'k'),
																																																																	_Utils_Tuple2('ꝁ', 'k'),
																																																																	_Utils_Tuple2('ꝃ', 'k'),
																																																																	_Utils_Tuple2('ꝅ', 'k'),
																																																																	_Utils_Tuple2('ꞣ', 'k'),
																																																																	_Utils_Tuple2('l', 'l'),
																																																																	_Utils_Tuple2('ⓛ', 'l'),
																																																																	_Utils_Tuple2('ｌ', 'l'),
																																																																	_Utils_Tuple2('ŀ', 'l'),
																																																																	_Utils_Tuple2('ĺ', 'l')
																																																																]),
																																																															_Utils_ap(
																																																																_List_fromArray(
																																																																	[
																																																																		_Utils_Tuple2('ľ', 'l'),
																																																																		_Utils_Tuple2('ḷ', 'l'),
																																																																		_Utils_Tuple2('ḹ', 'l'),
																																																																		_Utils_Tuple2('ļ', 'l'),
																																																																		_Utils_Tuple2('ḽ', 'l'),
																																																																		_Utils_Tuple2('ḻ', 'l'),
																																																																		_Utils_Tuple2('ſ', 'l'),
																																																																		_Utils_Tuple2('ł', 'l'),
																																																																		_Utils_Tuple2('ƚ', 'l'),
																																																																		_Utils_Tuple2('ɫ', 'l')
																																																																	]),
																																																																_Utils_ap(
																																																																	_List_fromArray(
																																																																		[
																																																																			_Utils_Tuple2('ⱡ', 'l'),
																																																																			_Utils_Tuple2('ꝉ', 'l'),
																																																																			_Utils_Tuple2('ꞁ', 'l'),
																																																																			_Utils_Tuple2('ꝇ', 'l'),
																																																																			_Utils_Tuple2('ǉ', 'lj'),
																																																																			_Utils_Tuple2('m', 'm'),
																																																																			_Utils_Tuple2('ⓜ', 'm'),
																																																																			_Utils_Tuple2('ｍ', 'm'),
																																																																			_Utils_Tuple2('ḿ', 'm'),
																																																																			_Utils_Tuple2('ṁ', 'm')
																																																																		]),
																																																																	_Utils_ap(
																																																																		_List_fromArray(
																																																																			[
																																																																				_Utils_Tuple2('ṃ', 'm'),
																																																																				_Utils_Tuple2('ɱ', 'm'),
																																																																				_Utils_Tuple2('ɯ', 'm'),
																																																																				_Utils_Tuple2('n', 'n'),
																																																																				_Utils_Tuple2('ⓝ', 'n'),
																																																																				_Utils_Tuple2('ｎ', 'n'),
																																																																				_Utils_Tuple2('ǹ', 'n'),
																																																																				_Utils_Tuple2('ń', 'n'),
																																																																				_Utils_Tuple2('ñ', 'n'),
																																																																				_Utils_Tuple2('ṅ', 'n')
																																																																			]),
																																																																		_Utils_ap(
																																																																			_List_fromArray(
																																																																				[
																																																																					_Utils_Tuple2('ň', 'n'),
																																																																					_Utils_Tuple2('ṇ', 'n'),
																																																																					_Utils_Tuple2('ņ', 'n'),
																																																																					_Utils_Tuple2('ṋ', 'n'),
																																																																					_Utils_Tuple2('ṉ', 'n'),
																																																																					_Utils_Tuple2('ƞ', 'n'),
																																																																					_Utils_Tuple2('ɲ', 'n'),
																																																																					_Utils_Tuple2('ŉ', 'n'),
																																																																					_Utils_Tuple2('ꞑ', 'n'),
																																																																					_Utils_Tuple2('ꞥ', 'n')
																																																																				]),
																																																																			_Utils_ap(
																																																																				_List_fromArray(
																																																																					[
																																																																						_Utils_Tuple2('ǌ', 'nj'),
																																																																						_Utils_Tuple2('o', 'o'),
																																																																						_Utils_Tuple2('ⓞ', 'o'),
																																																																						_Utils_Tuple2('ｏ', 'o'),
																																																																						_Utils_Tuple2('ò', 'o'),
																																																																						_Utils_Tuple2('ó', 'o'),
																																																																						_Utils_Tuple2('ô', 'o'),
																																																																						_Utils_Tuple2('ồ', 'o'),
																																																																						_Utils_Tuple2('ố', 'o'),
																																																																						_Utils_Tuple2('ỗ', 'o')
																																																																					]),
																																																																				_Utils_ap(
																																																																					_List_fromArray(
																																																																						[
																																																																							_Utils_Tuple2('ổ', 'o'),
																																																																							_Utils_Tuple2('õ', 'o'),
																																																																							_Utils_Tuple2('ṍ', 'o'),
																																																																							_Utils_Tuple2('ȭ', 'o'),
																																																																							_Utils_Tuple2('ṏ', 'o'),
																																																																							_Utils_Tuple2('ō', 'o'),
																																																																							_Utils_Tuple2('ṑ', 'o'),
																																																																							_Utils_Tuple2('ṓ', 'o'),
																																																																							_Utils_Tuple2('ŏ', 'o'),
																																																																							_Utils_Tuple2('ȯ', 'o')
																																																																						]),
																																																																					_Utils_ap(
																																																																						_List_fromArray(
																																																																							[
																																																																								_Utils_Tuple2('ȱ', 'o'),
																																																																								_Utils_Tuple2('ö', 'o'),
																																																																								_Utils_Tuple2('ȫ', 'o'),
																																																																								_Utils_Tuple2('ỏ', 'o'),
																																																																								_Utils_Tuple2('ő', 'o'),
																																																																								_Utils_Tuple2('ǒ', 'o'),
																																																																								_Utils_Tuple2('ȍ', 'o'),
																																																																								_Utils_Tuple2('ȏ', 'o'),
																																																																								_Utils_Tuple2('ơ', 'o'),
																																																																								_Utils_Tuple2('ờ', 'o')
																																																																							]),
																																																																						_Utils_ap(
																																																																							_List_fromArray(
																																																																								[
																																																																									_Utils_Tuple2('ớ', 'o'),
																																																																									_Utils_Tuple2('ỡ', 'o'),
																																																																									_Utils_Tuple2('ở', 'o'),
																																																																									_Utils_Tuple2('ợ', 'o'),
																																																																									_Utils_Tuple2('ọ', 'o'),
																																																																									_Utils_Tuple2('ộ', 'o'),
																																																																									_Utils_Tuple2('ǫ', 'o'),
																																																																									_Utils_Tuple2('ǭ', 'o'),
																																																																									_Utils_Tuple2('ø', 'o'),
																																																																									_Utils_Tuple2('ǿ', 'o')
																																																																								]),
																																																																							_Utils_ap(
																																																																								_List_fromArray(
																																																																									[
																																																																										_Utils_Tuple2('ɔ', 'o'),
																																																																										_Utils_Tuple2('ꝋ', 'o'),
																																																																										_Utils_Tuple2('ꝍ', 'o'),
																																																																										_Utils_Tuple2('ɵ', 'o'),
																																																																										_Utils_Tuple2('ƣ', 'oi'),
																																																																										_Utils_Tuple2('ȣ', 'ou'),
																																																																										_Utils_Tuple2('ꝏ', 'oo'),
																																																																										_Utils_Tuple2('p', 'p'),
																																																																										_Utils_Tuple2('ⓟ', 'p'),
																																																																										_Utils_Tuple2('ｐ', 'p')
																																																																									]),
																																																																								_Utils_ap(
																																																																									_List_fromArray(
																																																																										[
																																																																											_Utils_Tuple2('ṕ', 'p'),
																																																																											_Utils_Tuple2('ṗ', 'p'),
																																																																											_Utils_Tuple2('ƥ', 'p'),
																																																																											_Utils_Tuple2('ᵽ', 'p'),
																																																																											_Utils_Tuple2('ꝑ', 'p'),
																																																																											_Utils_Tuple2('ꝓ', 'p'),
																																																																											_Utils_Tuple2('ꝕ', 'p'),
																																																																											_Utils_Tuple2('q', 'q'),
																																																																											_Utils_Tuple2('ⓠ', 'q'),
																																																																											_Utils_Tuple2('ｑ', 'q')
																																																																										]),
																																																																									_Utils_ap(
																																																																										_List_fromArray(
																																																																											[
																																																																												_Utils_Tuple2('ɋ', 'q'),
																																																																												_Utils_Tuple2('ꝗ', 'q'),
																																																																												_Utils_Tuple2('ꝙ', 'q'),
																																																																												_Utils_Tuple2('r', 'r'),
																																																																												_Utils_Tuple2('ⓡ', 'r'),
																																																																												_Utils_Tuple2('ｒ', 'r'),
																																																																												_Utils_Tuple2('ŕ', 'r'),
																																																																												_Utils_Tuple2('ṙ', 'r'),
																																																																												_Utils_Tuple2('ř', 'r'),
																																																																												_Utils_Tuple2('ȑ', 'r')
																																																																											]),
																																																																										_Utils_ap(
																																																																											_List_fromArray(
																																																																												[
																																																																													_Utils_Tuple2('ȓ', 'r'),
																																																																													_Utils_Tuple2('ṛ', 'r'),
																																																																													_Utils_Tuple2('ṝ', 'r'),
																																																																													_Utils_Tuple2('ŗ', 'r'),
																																																																													_Utils_Tuple2('ṟ', 'r'),
																																																																													_Utils_Tuple2('ɍ', 'r'),
																																																																													_Utils_Tuple2('ɽ', 'r'),
																																																																													_Utils_Tuple2('ꝛ', 'r'),
																																																																													_Utils_Tuple2('ꞧ', 'r'),
																																																																													_Utils_Tuple2('ꞃ', 'r')
																																																																												]),
																																																																											_Utils_ap(
																																																																												_List_fromArray(
																																																																													[
																																																																														_Utils_Tuple2('s', 's'),
																																																																														_Utils_Tuple2('ⓢ', 's'),
																																																																														_Utils_Tuple2('ｓ', 's'),
																																																																														_Utils_Tuple2('ß', 's'),
																																																																														_Utils_Tuple2('ś', 's'),
																																																																														_Utils_Tuple2('ṥ', 's'),
																																																																														_Utils_Tuple2('ŝ', 's'),
																																																																														_Utils_Tuple2('ṡ', 's'),
																																																																														_Utils_Tuple2('š', 's'),
																																																																														_Utils_Tuple2('ṧ', 's')
																																																																													]),
																																																																												_Utils_ap(
																																																																													_List_fromArray(
																																																																														[
																																																																															_Utils_Tuple2('ṣ', 's'),
																																																																															_Utils_Tuple2('ṩ', 's'),
																																																																															_Utils_Tuple2('ș', 's'),
																																																																															_Utils_Tuple2('ş', 's'),
																																																																															_Utils_Tuple2('ȿ', 's'),
																																																																															_Utils_Tuple2('ꞩ', 's'),
																																																																															_Utils_Tuple2('ꞅ', 's'),
																																																																															_Utils_Tuple2('ẛ', 's'),
																																																																															_Utils_Tuple2('t', 't'),
																																																																															_Utils_Tuple2('ⓣ', 't')
																																																																														]),
																																																																													_Utils_ap(
																																																																														_List_fromArray(
																																																																															[
																																																																																_Utils_Tuple2('ｔ', 't'),
																																																																																_Utils_Tuple2('ṫ', 't'),
																																																																																_Utils_Tuple2('ẗ', 't'),
																																																																																_Utils_Tuple2('ť', 't'),
																																																																																_Utils_Tuple2('ṭ', 't'),
																																																																																_Utils_Tuple2('ț', 't'),
																																																																																_Utils_Tuple2('ţ', 't'),
																																																																																_Utils_Tuple2('ṱ', 't'),
																																																																																_Utils_Tuple2('ṯ', 't'),
																																																																																_Utils_Tuple2('ŧ', 't')
																																																																															]),
																																																																														_Utils_ap(
																																																																															_List_fromArray(
																																																																																[
																																																																																	_Utils_Tuple2('ƭ', 't'),
																																																																																	_Utils_Tuple2('ʈ', 't'),
																																																																																	_Utils_Tuple2('ⱦ', 't'),
																																																																																	_Utils_Tuple2('ꞇ', 't'),
																																																																																	_Utils_Tuple2('ꜩ', 'tz'),
																																																																																	_Utils_Tuple2('u', 'u'),
																																																																																	_Utils_Tuple2('ⓤ', 'u'),
																																																																																	_Utils_Tuple2('ｕ', 'u'),
																																																																																	_Utils_Tuple2('ù', 'u'),
																																																																																	_Utils_Tuple2('ú', 'u')
																																																																																]),
																																																																															_Utils_ap(
																																																																																_List_fromArray(
																																																																																	[
																																																																																		_Utils_Tuple2('û', 'u'),
																																																																																		_Utils_Tuple2('ũ', 'u'),
																																																																																		_Utils_Tuple2('ṹ', 'u'),
																																																																																		_Utils_Tuple2('ū', 'u'),
																																																																																		_Utils_Tuple2('ṻ', 'u'),
																																																																																		_Utils_Tuple2('ŭ', 'u'),
																																																																																		_Utils_Tuple2('ü', 'u'),
																																																																																		_Utils_Tuple2('ǜ', 'u'),
																																																																																		_Utils_Tuple2('ǘ', 'u'),
																																																																																		_Utils_Tuple2('ǖ', 'u')
																																																																																	]),
																																																																																_Utils_ap(
																																																																																	_List_fromArray(
																																																																																		[
																																																																																			_Utils_Tuple2('ǚ', 'u'),
																																																																																			_Utils_Tuple2('ủ', 'u'),
																																																																																			_Utils_Tuple2('ů', 'u'),
																																																																																			_Utils_Tuple2('ű', 'u'),
																																																																																			_Utils_Tuple2('ǔ', 'u'),
																																																																																			_Utils_Tuple2('ȕ', 'u'),
																																																																																			_Utils_Tuple2('ȗ', 'u'),
																																																																																			_Utils_Tuple2('ư', 'u'),
																																																																																			_Utils_Tuple2('ừ', 'u'),
																																																																																			_Utils_Tuple2('ứ', 'u')
																																																																																		]),
																																																																																	_Utils_ap(
																																																																																		_List_fromArray(
																																																																																			[
																																																																																				_Utils_Tuple2('ữ', 'u'),
																																																																																				_Utils_Tuple2('ử', 'u'),
																																																																																				_Utils_Tuple2('ự', 'u'),
																																																																																				_Utils_Tuple2('ụ', 'u'),
																																																																																				_Utils_Tuple2('ṳ', 'u'),
																																																																																				_Utils_Tuple2('ų', 'u'),
																																																																																				_Utils_Tuple2('ṷ', 'u'),
																																																																																				_Utils_Tuple2('ṵ', 'u'),
																																																																																				_Utils_Tuple2('ʉ', 'u'),
																																																																																				_Utils_Tuple2('v', 'v')
																																																																																			]),
																																																																																		_Utils_ap(
																																																																																			_List_fromArray(
																																																																																				[
																																																																																					_Utils_Tuple2('ⓥ', 'v'),
																																																																																					_Utils_Tuple2('ｖ', 'v'),
																																																																																					_Utils_Tuple2('ṽ', 'v'),
																																																																																					_Utils_Tuple2('ṿ', 'v'),
																																																																																					_Utils_Tuple2('ʋ', 'v'),
																																																																																					_Utils_Tuple2('ꝟ', 'v'),
																																																																																					_Utils_Tuple2('ʌ', 'v'),
																																																																																					_Utils_Tuple2('ꝡ', 'vy'),
																																																																																					_Utils_Tuple2('w', 'w'),
																																																																																					_Utils_Tuple2('ⓦ', 'w')
																																																																																				]),
																																																																																			_Utils_ap(
																																																																																				_List_fromArray(
																																																																																					[
																																																																																						_Utils_Tuple2('ｗ', 'w'),
																																																																																						_Utils_Tuple2('ẁ', 'w'),
																																																																																						_Utils_Tuple2('ẃ', 'w'),
																																																																																						_Utils_Tuple2('ŵ', 'w'),
																																																																																						_Utils_Tuple2('ẇ', 'w'),
																																																																																						_Utils_Tuple2('ẅ', 'w'),
																																																																																						_Utils_Tuple2('ẘ', 'w'),
																																																																																						_Utils_Tuple2('ẉ', 'w'),
																																																																																						_Utils_Tuple2('ⱳ', 'w'),
																																																																																						_Utils_Tuple2('x', 'x')
																																																																																					]),
																																																																																				_Utils_ap(
																																																																																					_List_fromArray(
																																																																																						[
																																																																																							_Utils_Tuple2('ⓧ', 'x'),
																																																																																							_Utils_Tuple2('ｘ', 'x'),
																																																																																							_Utils_Tuple2('ẋ', 'x'),
																																																																																							_Utils_Tuple2('ẍ', 'x'),
																																																																																							_Utils_Tuple2('y', 'y'),
																																																																																							_Utils_Tuple2('ⓨ', 'y'),
																																																																																							_Utils_Tuple2('ｙ', 'y'),
																																																																																							_Utils_Tuple2('ỳ', 'y'),
																																																																																							_Utils_Tuple2('ý', 'y'),
																																																																																							_Utils_Tuple2('ŷ', 'y')
																																																																																						]),
																																																																																					_Utils_ap(
																																																																																						_List_fromArray(
																																																																																							[
																																																																																								_Utils_Tuple2('ỹ', 'y'),
																																																																																								_Utils_Tuple2('ȳ', 'y'),
																																																																																								_Utils_Tuple2('ẏ', 'y'),
																																																																																								_Utils_Tuple2('ÿ', 'y'),
																																																																																								_Utils_Tuple2('ỷ', 'y'),
																																																																																								_Utils_Tuple2('ẙ', 'y'),
																																																																																								_Utils_Tuple2('ỵ', 'y'),
																																																																																								_Utils_Tuple2('ƴ', 'y'),
																																																																																								_Utils_Tuple2('ɏ', 'y'),
																																																																																								_Utils_Tuple2('ỿ', 'y')
																																																																																							]),
																																																																																						_List_fromArray(
																																																																																							[
																																																																																								_Utils_Tuple2('z', 'z'),
																																																																																								_Utils_Tuple2('ⓩ', 'z'),
																																																																																								_Utils_Tuple2('ｚ', 'z'),
																																																																																								_Utils_Tuple2('ź', 'z'),
																																																																																								_Utils_Tuple2('ẑ', 'z'),
																																																																																								_Utils_Tuple2('ż', 'z'),
																																																																																								_Utils_Tuple2('ž', 'z'),
																																																																																								_Utils_Tuple2('ẓ', 'z'),
																																																																																								_Utils_Tuple2('ẕ', 'z'),
																																																																																								_Utils_Tuple2('ƶ', 'z')
																																																																																							])))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))));
var $kuon$elm_string_normalize$String$Normalize$Diacritics$lookupTable = $elm$core$Dict$fromList($kuon$elm_string_normalize$String$Normalize$Diacritics$lookupList);
var $kuon$elm_string_normalize$String$Normalize$removeDiacritics = function (str) {
	var replace = F2(
		function (c, result) {
			var _v0 = A2($elm$core$Dict$get, c, $kuon$elm_string_normalize$String$Normalize$Diacritics$lookupTable);
			if (!_v0.$) {
				var candidate = _v0.a;
				return _Utils_ap(result, candidate);
			} else {
				return _Utils_ap(
					result,
					$elm$core$String$fromChar(c));
			}
		});
	return A3($elm$core$String$foldl, replace, '', str);
};
var $mcordova47$elm_natural_ordering$NaturalOrdering$toComparableString = A2($elm$core$Basics$composeL, $elm$core$String$toLower, $kuon$elm_string_normalize$String$Normalize$removeDiacritics);
var $mcordova47$elm_natural_ordering$NaturalOrdering$compareChunks = F2(
	function (chunk1, chunk2) {
		var _v0 = _Utils_Tuple2(chunk1, chunk2);
		if (!_v0.a.$) {
			if (!_v0.b.$) {
				var str1 = _v0.a.a;
				var str2 = _v0.b.a;
				return A2(
					$elm$core$Basics$compare,
					$mcordova47$elm_natural_ordering$NaturalOrdering$toComparableString(str1),
					$mcordova47$elm_natural_ordering$NaturalOrdering$toComparableString(str2));
			} else {
				return 2;
			}
		} else {
			if (!_v0.b.$) {
				return 0;
			} else {
				var int1 = _v0.a.a;
				var int2 = _v0.b.a;
				return A2($elm$core$Basics$compare, int1, int2);
			}
		}
	});
var $mcordova47$elm_natural_ordering$NaturalOrdering$compareChunkLists = F2(
	function (chunkList1, chunkList2) {
		compareChunkLists:
		while (true) {
			var _v0 = _Utils_Tuple2(chunkList1, chunkList2);
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					return 1;
				} else {
					var _v1 = _v0.b;
					return 0;
				}
			} else {
				if (!_v0.b.b) {
					var _v2 = _v0.a;
					return 2;
				} else {
					var _v3 = _v0.a;
					var chunk1 = _v3.a;
					var chunks1 = _v3.b;
					var _v4 = _v0.b;
					var chunk2 = _v4.a;
					var chunks2 = _v4.b;
					var _v5 = A2($mcordova47$elm_natural_ordering$NaturalOrdering$compareChunks, chunk1, chunk2);
					if (_v5 === 1) {
						var $temp$chunkList1 = chunks1,
							$temp$chunkList2 = chunks2;
						chunkList1 = $temp$chunkList1;
						chunkList2 = $temp$chunkList2;
						continue compareChunkLists;
					} else {
						var ord = _v5;
						return ord;
					}
				}
			}
		}
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {cD: index, cL: match, c3: number, dr: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{ck: false, cV: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $mcordova47$elm_natural_ordering$NaturalOrdering$chunkRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[0-9]+|[^0-9]+'));
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $mcordova47$elm_natural_ordering$NaturalOrdering$StringChunk = function (a) {
	return {$: 0, a: a};
};
var $mcordova47$elm_natural_ordering$NaturalOrdering$IntChunk = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $mcordova47$elm_natural_ordering$NaturalOrdering$intToChunk = function (_int) {
	return $elm$core$Basics$isNaN(_int) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		$mcordova47$elm_natural_ordering$NaturalOrdering$IntChunk(_int));
};
var $mcordova47$elm_natural_ordering$NaturalOrdering$toChunk = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		$mcordova47$elm_natural_ordering$NaturalOrdering$StringChunk(str),
		A2(
			$elm$core$Maybe$andThen,
			$mcordova47$elm_natural_ordering$NaturalOrdering$intToChunk,
			$elm$core$String$toInt(str)));
};
var $mcordova47$elm_natural_ordering$NaturalOrdering$toChunks = function (str) {
	return A2(
		$elm$core$List$map,
		A2(
			$elm$core$Basics$composeL,
			$mcordova47$elm_natural_ordering$NaturalOrdering$toChunk,
			function ($) {
				return $.cL;
			}),
		A2($elm$regex$Regex$find, $mcordova47$elm_natural_ordering$NaturalOrdering$chunkRegex, str));
};
var $mcordova47$elm_natural_ordering$NaturalOrdering$compareOn = F3(
	function (f, x, y) {
		return A2(
			$mcordova47$elm_natural_ordering$NaturalOrdering$compareChunkLists,
			$mcordova47$elm_natural_ordering$NaturalOrdering$toChunks(
				f(x)),
			$mcordova47$elm_natural_ordering$NaturalOrdering$toChunks(
				f(y)));
	});
var $mcordova47$elm_natural_ordering$NaturalOrdering$compare = $mcordova47$elm_natural_ordering$NaturalOrdering$compareOn($elm$core$Basics$identity);
var $author$project$Sensor$mainSensors = function (page) {
	var common = $elm$core$Dict$fromList(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Particulate Matter',
				_List_fromArray(
					['AirBeam-PM10 (µg/m³)', 'AirBeam-PM2.5 (µg/m³)', 'AirBeam-PM1 (µg/m³)'])),
				_Utils_Tuple2(
				'Humidity',
				_List_fromArray(
					['AirBeam-RH (%)'])),
				_Utils_Tuple2(
				'Temperature',
				_List_fromArray(
					['AirBeam-F (F)']))
			]));
	if (page === 1) {
		return A3(
			$elm$core$Dict$insert,
			'Sound Level',
			_List_fromArray(
				['Phone Microphone (dB)']),
			common);
	} else {
		return A3(
			$elm$core$Dict$update,
			'Particulate Matter',
			$elm$core$Maybe$map(
				function (labels) {
					return _Utils_ap(
						labels,
						_List_fromArray(
							['OpenAQ-PM2.5 (µg/m³)', 'PurpleAir-PM2.5 (µg/m³)']));
				}),
			A3(
				$elm$core$Dict$insert,
				'Ozone',
				_List_fromArray(
					['OpenAQ-O3 (ppb)']),
				common));
	}
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm$core$List$sortWith = _List_sortWith;
var $elm$core$String$trim = _String_trim;
var $author$project$Sensor$parameters = F2(
	function (page, sensors) {
		var othersParameters = A2(
			$elm$core$List$sortWith,
			$mcordova47$elm_natural_ordering$NaturalOrdering$compare,
			A2(
				$elm$core$List$filter,
				function (sensor) {
					return !A2(
						$elm$core$List$member,
						sensor,
						$elm$core$Dict$keys(
							$author$project$Sensor$mainSensors(page)));
				},
				$elm$core$Set$toList(
					$elm$core$Set$fromList(
						A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeL,
								$elm$core$String$trim,
								function ($) {
									return $.c8;
								}),
							sensors)))));
		return _Utils_Tuple2(
			$elm$core$Dict$keys(
				$author$project$Sensor$mainSensors(page)),
			othersParameters);
	});
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $author$project$Main$SelectSensorId = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$TogglePopupState = {$: 16};
var $author$project$Popup$MainPart = 0;
var $author$project$Popup$OtherPart = 1;
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Popup$selectableItems = F4(
	function (part, items, onSelect, selectedItem) {
		var _v0 = function () {
			if (!part) {
				return _Utils_Tuple2('filter-popup__list', 'button filter-popup-button');
			} else {
				return _Utils_Tuple2('filter-popup__list--more', 'button filter-popup-secondary-button');
			}
		}();
		var parentClass = _v0.a;
		var childClass = _v0.b;
		var toButton = function (item) {
			return A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						onSelect(item)),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'active',
								_Utils_eq(item, selectedItem)),
								_Utils_Tuple2(childClass, true),
								_Utils_Tuple2('test-filter-popup-button', true)
							]))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(item)
					]));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(parentClass)
				]),
			A2($elm$core$List$map, toButton, items));
	});
var $author$project$Popup$togglePopupStateButton = F2(
	function (name, toggle) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('toggle-popup-button'),
					$elm$html$Html$Attributes$class('filter-popup__toggle-more-button'),
					$author$project$Popup$clickWithoutDefault(toggle)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(name)
				]));
	});
var $author$project$Popup$viewListPopup = F6(
	function (toggle, onSelect, isListExpanded, _v0, itemType, selectedItem) {
		var main = _v0.a;
		var others = _v0.b;
		var _v1 = _Utils_Tuple2(
			$elm$core$List$isEmpty(main),
			$elm$core$List$isEmpty(others));
		if (_v1.a) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('popup'),
						$elm$html$Html$Attributes$class('filter-popup')
					]),
				_List_fromArray(
					[
						A4($author$project$Popup$selectableItems, 0, others, onSelect, selectedItem)
					]));
		} else {
			if (_v1.b) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('popup'),
							$elm$html$Html$Attributes$class('filter-popup')
						]),
					_List_fromArray(
						[
							A4($author$project$Popup$selectableItems, 0, main, onSelect, selectedItem)
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('popup'),
							$elm$html$Html$Attributes$class('filter-popup')
						]),
					_List_fromArray(
						[
							A4($author$project$Popup$selectableItems, 0, main, onSelect, selectedItem),
							$elm$core$List$isEmpty(others) ? $elm$html$Html$text('') : (isListExpanded ? A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('filter-popup__more')
								]),
							_List_fromArray(
								[
									A4($author$project$Popup$selectableItems, 1, others, onSelect, selectedItem),
									A2($author$project$Popup$togglePopupStateButton, 'fewer ' + itemType, toggle)
								])) : A2($author$project$Popup$togglePopupStateButton, 'more ' + itemType, toggle))
						]));
			}
		}
	});
var $author$project$Main$viewListPopup = F6(
	function (isShown, isPopupListExpanded, popup, items, itemType, selectedItem) {
		return isShown(popup) ? A6($author$project$Popup$viewListPopup, $author$project$Main$TogglePopupState, $author$project$Main$SelectSensorId, isPopupListExpanded, items, itemType, selectedItem) : $elm$html$Html$text('');
	});
var $author$project$Main$viewParameterFilter = F5(
	function (page, sensors, selectedSensorId, isPopupListExpanded, popup) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('filters__input-group')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('parameter'),
							$elm$html$Html$Attributes$class('input input--dark input--filters'),
							$elm$html$Html$Attributes$placeholder('parameter'),
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$name('parameter'),
							$author$project$Popup$clickWithoutDefault(
							$author$project$Main$ShowListPopup(0)),
							$elm$html$Html$Attributes$value(
							A2($author$project$Sensor$parameterForId, sensors, selectedSensorId)),
							$elm$html$Html$Attributes$autocomplete(false),
							$elm$html$Html$Attributes$readonly(true)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label label--filters'),
							$elm$html$Html$Attributes$for('parameter')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('parameter:')
						])),
					$author$project$Tooltip$view($author$project$Tooltip$parameterFilter),
					A6(
					$author$project$Main$viewListPopup,
					$author$project$Popup$isParameterPopupShown,
					isPopupListExpanded,
					popup,
					A2($author$project$Sensor$parameters, page, sensors),
					'parameters',
					A2($author$project$Sensor$parameterForId, sensors, selectedSensorId))
				]));
	});
var $author$project$Popup$SensorList = 1;
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Popup$isSensorPopupShown = function (popup) {
	if (popup === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Sensor$labelsForParameter = F3(
	function (page, sensors, sensorId) {
		var mainLabels_ = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Dict$get,
				A2($author$project$Sensor$parameterForId, sensors, sensorId),
				$author$project$Sensor$mainSensors(page)));
		var allLabels = A2(
			$elm$core$List$sortWith,
			$mcordova47$elm_natural_ordering$NaturalOrdering$compare,
			A2(
				$elm$core$List$map,
				$author$project$Sensor$toLabel,
				A2(
					$elm$core$List$filter,
					function (sensor) {
						return _Utils_eq(
							sensor.c8,
							A2($author$project$Sensor$parameterForId, sensors, sensorId));
					},
					sensors)));
		var othersLabels_ = A2(
			$elm$core$List$filter,
			function (label) {
				return !A2($elm$core$List$member, label, mainLabels_);
			},
			allLabels);
		return _Utils_Tuple2(mainLabels_, othersLabels_);
	});
var $author$project$Tooltip$sensorFilter = 'The sensor field describes the specific make and model of the sensor that is creating measurements.';
var $author$project$Sensor$sensorLabelForId = F2(
	function (sensors, sensorId) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				$author$project$Sensor$toLabel,
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (sensor) {
							return _Utils_eq(
								$author$project$Sensor$toId(sensor),
								sensorId);
						},
						sensors))));
	});
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Main$viewSensorFilter = F5(
	function (page, sensors, selectedSensorId, isPopupListExpanded, popup) {
		var sensorLabel = A2($author$project$Sensor$sensorLabelForId, sensors, selectedSensorId);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('filters__input-group')
				]),
			_List_fromArray(
				[
					function () {
					if (sensorLabel === 'PurpleAir-PM2.5 (µg/m³)') {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('purpleair-link')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('https://www.purpleair.com'),
											$elm$html$Html$Attributes$target('_blank')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('www.purpleair.com')
										]))
								]));
					} else {
						return $elm$html$Html$text('');
					}
				}(),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('sensor'),
							$elm$html$Html$Attributes$class('input input--dark input--filters'),
							$elm$html$Html$Attributes$placeholder('sensor'),
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$name('sensor'),
							$author$project$Popup$clickWithoutDefault(
							$author$project$Main$ShowListPopup(1)),
							$elm$html$Html$Attributes$value(sensorLabel),
							$elm$html$Html$Attributes$autocomplete(false),
							$elm$html$Html$Attributes$readonly(true)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label label--filters'),
							$elm$html$Html$Attributes$for('sensor')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('sensor:')
						])),
					$author$project$Tooltip$view($author$project$Tooltip$sensorFilter),
					A6(
					$author$project$Main$viewListPopup,
					$author$project$Popup$isSensorPopupShown,
					isPopupListExpanded,
					popup,
					A3($author$project$Sensor$labelsForParameter, page, sensors, selectedSensorId),
					'sensors',
					sensorLabel)
				]));
	});
var $author$project$Main$viewToggleButton = F3(
	function (label, isPressed, callback) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Attributes$class('input input--filters'),
					isPressed ? $elm$html$Html$Attributes$class('toggle-button toggle-button--pressed') : $elm$html$Html$Attributes$class('toggle-button'),
					$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel(label),
					$elm$html$Html$Events$onClick(callback)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				]));
	});
var $author$project$Main$viewFixedFilters = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('filters__form')
			]),
		_List_fromArray(
			[
				A6($elm$html$Html$Lazy$lazy5, $author$project$Main$viewParameterFilter, model.m, model.n, model.i, model.J, model.v),
				A6($elm$html$Html$Lazy$lazy5, $author$project$Main$viewSensorFilter, model.m, model.n, model.i, model.J, model.v),
				A2($author$project$Main$viewLocationFilter, model.bx, model.br),
				A2($author$project$TimeRange$view, $author$project$Main$RefreshTimeRange, model.M),
				A2(
				$elm$html$Html$map,
				$author$project$Main$ProfileLabels,
				A7($author$project$LabelsInput$view, model.F, 'profile names:', 'profile-names', 'js--profile-names-input', '+ add profile name', model.br, $author$project$Tooltip$profilesFilter)),
				A2(
				$elm$html$Html$map,
				$author$project$Main$TagsLabels,
				A7($author$project$LabelsInput$view, model.H, 'tags:', 'tags', 'js--tags-input', '+ add tag', false, $author$project$Tooltip$tagsFilter)),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('filters__toggle-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('label label--filters')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('placement:')
							])),
						$author$project$Tooltip$view($author$project$Tooltip$typeToggleFilter),
						A3(
						$author$project$Main$viewToggleButton,
						'outdoor',
						!model.br,
						$author$project$Main$ToggleIndoor(false)),
						A3(
						$author$project$Main$viewToggleButton,
						'indoor',
						model.br,
						$author$project$Main$ToggleIndoor(true))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('filters__toggle-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('label label--filters')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('status:')
							])),
						$author$project$Tooltip$view($author$project$Tooltip$activeToggleFilter),
						A3(
						$author$project$Main$viewToggleButton,
						'active',
						!model.aa,
						$author$project$Main$ToggleStatus(0)),
						A3(
						$author$project$Main$viewToggleButton,
						'dormant',
						model.aa === 1,
						$author$project$Main$ToggleStatus(1))
					]))
			]));
};
var $krisajenkins$remotedata$RemoteData$isSuccess = function (data) {
	if (data.$ === 3) {
		var x = data.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Main$MaybeUpdateResolution = function (a) {
	return {$: 45, a: a};
};
var $author$project$Main$UpdateCrowdMapResolution = function (a) {
	return {$: 4, a: a};
};
var $author$project$Data$BoundedInteger$addOne = function (existingValue) {
	return A2(
		$author$project$Data$BoundedInteger$updateValue,
		function (x) {
			return x + 1;
		},
		existingValue);
};
var $author$project$Data$BoundedInteger$getLowerBound = function (_v0) {
	var existingValue = _v0;
	return existingValue.aW;
};
var $author$project$Data$BoundedInteger$getUpperBound = function (_v0) {
	var existingValue = _v0;
	return existingValue.a5;
};
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $author$project$Main$onChange = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue));
};
var $author$project$Data$BoundedInteger$subOne = function (existingValue) {
	return A2(
		$author$project$Data$BoundedInteger$updateValue,
		function (x) {
			return x - 1;
		},
		existingValue);
};
var $author$project$Main$viewCrowdMapSlider = function (boundedInteger) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('crowd-map-slider')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('label label--filters')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						'grid cell size: ' + $elm$core$String$fromInt(
							$author$project$Data$BoundedInteger$getValue(boundedInteger)))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('crowd-map-slider-container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('minus'),
								$elm$html$Html$Events$onClick(
								$author$project$Main$MaybeUpdateResolution($author$project$Data$BoundedInteger$subOne))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('-')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('crowd-map-slider'),
								$author$project$Main$onChange(
								A2(
									$elm$core$Basics$composeR,
									$elm$core$String$toInt,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Maybe$withDefault(25),
										$author$project$Main$UpdateCrowdMapResolution))),
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromInt(
									$author$project$Data$BoundedInteger$getValue(boundedInteger))),
								$elm$html$Html$Attributes$max(
								$elm$core$String$fromInt(
									$author$project$Data$BoundedInteger$getUpperBound(boundedInteger))),
								$elm$html$Html$Attributes$min(
								$elm$core$String$fromInt(
									$author$project$Data$BoundedInteger$getLowerBound(boundedInteger))),
								$elm$html$Html$Attributes$type_('range')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('plus'),
								$elm$html$Html$Events$onClick(
								$author$project$Main$MaybeUpdateResolution($author$project$Data$BoundedInteger$addOne))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('+')
							]))
					]))
			]));
};
var $author$project$Main$ToggleCrowdMap = function (a) {
	return {$: 3, a: a};
};
var $author$project$Tooltip$crowdMap = 'The CrowdMap averages together all the measurements from all the sessions listed on the sessions list and displays these averages as colored grid cells. The color of each grid cell corresponds to the average intensity of all the measurements recorded in that area. Click on a grid cell to view the underlying data.';
var $author$project$Main$viewCrowdMapToggle = function (isCrowdMapOn) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('filters__toggle-group')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('label label--filters')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('CrowdMap:')
					])),
				A3(
				$author$project$Main$viewToggleButton,
				'off',
				!isCrowdMapOn,
				$author$project$Main$ToggleCrowdMap(false)),
				A3(
				$author$project$Main$viewToggleButton,
				'on',
				isCrowdMapOn,
				$author$project$Main$ToggleCrowdMap(true)),
				$author$project$Tooltip$view($author$project$Tooltip$crowdMap)
			]));
};
var $author$project$Main$viewCrowdMapOptions = F3(
	function (isCrowdMapOn, crowdMapResolution, selectedSession) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'disabled-area',
							$krisajenkins$remotedata$RemoteData$isSuccess(selectedSession))
						]))
				]),
			_List_fromArray(
				[
					$author$project$Main$viewCrowdMapToggle(isCrowdMapOn),
					isCrowdMapOn ? $author$project$Main$viewCrowdMapSlider(crowdMapResolution) : $elm$html$Html$text('')
				]));
	});
var $author$project$Main$viewMobileFilters = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('filters__form')
			]),
		_List_fromArray(
			[
				A6($elm$html$Html$Lazy$lazy5, $author$project$Main$viewParameterFilter, model.m, model.n, model.i, model.J, model.v),
				A6($elm$html$Html$Lazy$lazy5, $author$project$Main$viewSensorFilter, model.m, model.n, model.i, model.J, model.v),
				A2($author$project$Main$viewLocationFilter, model.bx, model.br),
				A2($author$project$TimeRange$view, $author$project$Main$RefreshTimeRange, model.M),
				A2(
				$elm$html$Html$map,
				$author$project$Main$ProfileLabels,
				A7($author$project$LabelsInput$view, model.F, 'profile names:', 'profile-names', 'js--profile-names-input', '+ add profile name', false, $author$project$Tooltip$profilesFilter)),
				A2(
				$elm$html$Html$map,
				$author$project$Main$TagsLabels,
				A7($author$project$LabelsInput$view, model.H, 'tags:', 'tags', 'js--tags-input', '+ add tag', false, $author$project$Tooltip$tagsFilter)),
				A3($author$project$Main$viewCrowdMapOptions, model.I, model.y, model.g)
			]));
};
var $author$project$Main$viewFilters = function (model) {
	var _v0 = model.m;
	if (_v0 === 1) {
		return $author$project$Main$viewMobileFilters(model);
	} else {
		return $author$project$Main$viewFixedFilters(model);
	}
};
var $author$project$Main$ExportSessions = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$ShowCopyLinkTooltip = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$ShowExportPopup = {$: 10};
var $author$project$Main$UpdateEmailFormValue = function (a) {
	return {$: 12, a: a};
};
var $author$project$Popup$isEmailFormPopupShown = function (popup) {
	if (popup === 2) {
		return true;
	} else {
		return false;
	}
};
var $rtfeldman$elm_validate$Validate$Validator = $elm$core$Basics$identity;
var $elm$regex$Regex$contains = _Regex_contains;
var $rtfeldman$elm_validate$Validate$validEmail = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	A2(
		$elm$regex$Regex$fromStringWith,
		{ck: true, cV: false},
		'^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'));
var $rtfeldman$elm_validate$Validate$isValidEmail = function (email) {
	return A2($elm$regex$Regex$contains, $rtfeldman$elm_validate$Validate$validEmail, email);
};
var $rtfeldman$elm_validate$Validate$ifInvalidEmail = F2(
	function (subjectToEmail, errorFromEmail) {
		var getErrors = function (subject) {
			var email = subjectToEmail(subject);
			return $rtfeldman$elm_validate$Validate$isValidEmail(email) ? _List_Nil : _List_fromArray(
				[
					errorFromEmail(email)
				]);
		};
		return getErrors;
	});
var $author$project$Data$EmailForm$emailValidator = A2(
	$rtfeldman$elm_validate$Validate$ifInvalidEmail,
	function ($) {
		return $.aN;
	},
	function (_v0) {
		return 'Please enter a valid email address.';
	});
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$p = _VirtualDom_node('p');
var $rtfeldman$elm_validate$Validate$Valid = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$validate = F2(
	function (_v0, subject) {
		var getErrors = _v0;
		var _v1 = getErrors(subject);
		if (!_v1.b) {
			return $elm$core$Result$Ok(subject);
		} else {
			var errors = _v1;
			return $elm$core$Result$Err(errors);
		}
	});
var $author$project$Data$EmailForm$view = F4(
	function (emailForm, onSubmit, noOp, updateValue) {
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('email-popup tippy-box tippy-content'),
					A2($elm$html$Html$Attributes$attribute, 'data-theme', 'light-border')
				]),
			_List_fromArray(
				[
					function () {
					var _v0 = emailForm.ag;
					if (!_v0.$) {
						var flashMessage = _v0.a;
						return $elm$html$Html$text(flashMessage);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('input email-popup__input'),
											$elm$html$Html$Attributes$placeholder('email address'),
											$author$project$Popup$clickWithoutDefault(noOp),
											$elm$html$Html$Attributes$value(emailForm.aN),
											$elm$html$Html$Events$onInput(updateValue)
										]),
									_List_Nil),
									_Utils_eq(emailForm.af, _List_Nil) ? $elm$html$Html$text('') : A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('email-popup__error-message')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($elm$core$String$join, ' ', emailForm.af))
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button button--primary email-popup__button'),
											$author$project$Popup$clickWithoutDefault(
											onSubmit(
												A2($rtfeldman$elm_validate$Validate$validate, $author$project$Data$EmailForm$emailValidator, emailForm)))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('export')
										]))
								]));
					}
				}()
				]));
	});
var $author$project$Main$viewFiltersButtons = F4(
	function (selectedSession, linkIcon, popup, emailForm) {
		if (!selectedSession.$) {
			var tooltipId = 'copy-link-tooltip';
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('filters__actions action-buttons')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('button button--primary action-button action-button--export'),
								$author$project$Popup$clickWithoutDefault($author$project$Main$ShowExportPopup)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('export sessions')
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('button button--primary action-button action-button--copy-link'),
								$elm$html$Html$Events$onClick(
								$author$project$Main$ShowCopyLinkTooltip(tooltipId)),
								$elm$html$Html$Attributes$id(tooltipId)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$Data$Path$toString(linkIcon)),
										$elm$html$Html$Attributes$alt('Link icon')
									]),
								_List_Nil)
							])),
						$author$project$Popup$isEmailFormPopupShown(popup) ? A4($author$project$Data$EmailForm$view, emailForm, $author$project$Main$ExportSessions, $author$project$Main$NoOp, $author$project$Main$UpdateEmailFormValue) : $elm$html$Html$text('')
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Tooltip$fixedTab = 'The fixed tab displays measurements from fixed sessions. When recording fixed sessions, geocoordinates are fixed to a set location. The most recent measurement is displayed inside the session map markers.';
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Tooltip$mobileTab = 'The mobile tab displays measurements from mobile sessions. When recording mobile sessions, measurements are created, timestamped, and geolocated once per second. Average values for the duration of the session are displayed inside the session map markers.';
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Main$viewSessionTypeNav = function (model) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('session-type-nav')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('session-type-nav__item', true),
								_Utils_Tuple2('selected', model.m === 1)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(
								A2(
									$elm$url$Url$Builder$absolute,
									_List_fromArray(
										['mobile_map#']),
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'keepFiltersExpanded', 'true'),
											A2(
											$elm$url$Url$Builder$string,
											'theme',
											$author$project$Data$Theme$toString(model.E))
										])))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('mobile')
							])),
						$author$project$Tooltip$view($author$project$Tooltip$mobileTab)
					])),
				A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('session-type-nav__item', true),
								_Utils_Tuple2('selected', !model.m)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(
								A2(
									$elm$url$Url$Builder$absolute,
									_List_fromArray(
										['fixed_map#']),
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'keepFiltersExpanded', 'true'),
											A2(
											$elm$url$Url$Builder$string,
											'theme',
											$author$project$Data$Theme$toString(model.E))
										])))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('fixed')
							])),
						$author$project$Tooltip$view($author$project$Tooltip$fixedTab)
					]))
			]));
};
var $author$project$Main$viewFiltersForDesktop = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('filters filters--desktop')
			]),
		_List_fromArray(
			[
				$author$project$Main$viewSessionTypeNav(model),
				$author$project$Main$viewFilters(model),
				A4($author$project$Main$viewFiltersButtons, model.g, model.K, model.v, model.u)
			]));
};
var $elm$virtual_dom$VirtualDom$lazy7 = _VirtualDom_lazy7;
var $elm$html$Html$Lazy$lazy7 = $elm$virtual_dom$VirtualDom$lazy7;
var $author$project$Data$Overlay$view = function (_v0) {
	var overlays = _v0;
	var _v1 = $elm$core$List$head(overlays);
	if (!_v1.$) {
		var overlay = _v1.a;
		switch (overlay) {
			case 0:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('overlay'),
							$elm$html$Html$Attributes$id('overlay--httping')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('spinner')
								]),
							_List_Nil)
						]));
			case 3:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('overlay'),
							$elm$html$Html$Attributes$id('overlay--time-frame')
						]),
					_List_Nil);
			case 2:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('overlay'),
							$elm$html$Html$Attributes$id('overlay--popup')
						]),
					_List_Nil);
			default:
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('overlay overlay--indoor'),
									$elm$html$Html$Attributes$id('overlay--indoor')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('overlay-info')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Indoor sessions aren\'t mapped.')
										]))
								]))
						]));
		}
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Main$FitHeatMap = {$: 33};
var $author$project$Main$ResetHeatMapToDefaults = {$: 32};
var $author$project$Main$ToggleTheme = {$: 48};
var $author$project$Main$UpdateHeatMapMaximum = function (a) {
	return {$: 31, a: a};
};
var $author$project$Main$UpdateHeatMapMinimum = function (a) {
	return {$: 30, a: a};
};
var $author$project$Data$HeatMapThresholds$extremes = function (_v0) {
	var threshold1 = _v0.dw;
	var threshold5 = _v0.dx;
	return _Utils_Tuple2(threshold1.aN, threshold5.aN);
};
var $author$project$Data$Theme$getIcon = F2(
	function (theme, _v0) {
		var icons = _v0;
		if (!theme) {
			return icons.aP;
		} else {
			return icons.z;
		}
	});
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $author$project$Main$viewHeatMapInput = F4(
	function (text_, value_, sensorUnit, toMsg) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('heatmap-input')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(text_)
						])),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('visuallyhidden'),
							$elm$html$Html$Attributes$for('heatmap-' + text_)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Heatmap ' + (text_ + ' value'))
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('heatmap-' + text_),
							$elm$html$Html$Attributes$class('input'),
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$value(
							$elm$core$String$fromInt(value_)),
							$author$project$Main$onChange(toMsg)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('heatmap-unit-' + text_)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(sensorUnit)
						]))
				]));
	});
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$Main$viewHeatMap = F7(
	function (heatMapThresholds, sensorUnit, fitScaleIcon, resetIcon, icons, theme, selectedSession) {
		var _v0 = A2(
			$krisajenkins$remotedata$RemoteData$withDefault,
			_Utils_Tuple2(0, 0),
			A2($krisajenkins$remotedata$RemoteData$map, $author$project$Data$HeatMapThresholds$extremes, heatMapThresholds));
		var threshold1 = _v0.a;
		var threshold5 = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('heatmap')
				]),
			_List_fromArray(
				[
					A4($author$project$Main$viewHeatMapInput, 'min', threshold1, sensorUnit, $author$project$Main$UpdateHeatMapMinimum),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('heatmap'),
							$elm$html$Html$Attributes$class('heatmap-slider')
						]),
					_List_Nil),
					A4($author$project$Main$viewHeatMapInput, 'max', threshold5, sensorUnit, $author$project$Main$UpdateHeatMapMaximum),
					function () {
					if (selectedSession.$ === 3) {
						return A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('Fit scale to stream measurements'),
									$elm$html$Html$Attributes$class('heatmap-button heatmap-button--fit-scale'),
									$elm$html$Html$Events$onClick($author$project$Main$FitHeatMap)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											$author$project$Data$Path$toString(fitScaleIcon)),
											$elm$html$Html$Attributes$alt('Fit scale to stream measurements icon'),
											$elm$html$Html$Attributes$class('fit-scale-icon')
										]),
									_List_Nil)
								]));
					} else {
						return $elm$html$Html$text('');
					}
				}(),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('Reset heatmap'),
							$elm$html$Html$Attributes$class('heatmap-button'),
							$elm$html$Html$Events$onClick($author$project$Main$ResetHeatMapToDefaults)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									$author$project$Data$Path$toString(resetIcon)),
									$elm$html$Html$Attributes$alt('Reset icon')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('Switch colours'),
							$elm$html$Html$Attributes$class('heatmap-button'),
							$elm$html$Html$Events$onClick($author$project$Main$ToggleTheme)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									$author$project$Data$Path$toString(
										A2($author$project$Data$Theme$getIcon, theme, icons))),
									$elm$html$Html$Attributes$alt('Switch theme icon')
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Main$FetchSessions = {$: 37};
var $author$project$Main$ToggleIsSearchOn = {$: 35};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$Main$viewSearchAsIMove = function (model) {
	var _v0 = model.g;
	if (_v0.$ === 3) {
		return $elm$html$Html$text('');
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-control')
				]),
			_List_fromArray(
				[
					model.ax ? A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('button button--primary search-control__button'),
							$elm$html$Html$Events$onClick($author$project$Main$FetchSessions)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Redo Search in Map'),
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									$author$project$Data$Path$toString(model.M)),
									$elm$html$Html$Attributes$alt('Reset icon')
								]),
							_List_Nil)
						])) : A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('search-control__switch')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('checkbox-search-as-i-move'),
									$elm$html$Html$Attributes$type_('checkbox'),
									$elm$html$Html$Attributes$checked(model.G),
									$elm$html$Html$Events$onClick($author$project$Main$ToggleIsSearchOn)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$for('checkbox-search-as-i-move')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Redo search when map is moved')
								]))
						]))
				]));
	}
};
var $author$project$Main$DeselectSession = {$: 24};
var $author$project$Data$HeatMapThresholds$Default = 4;
var $author$project$Data$HeatMapThresholds$Range1 = 0;
var $author$project$Data$HeatMapThresholds$Range2 = 1;
var $author$project$Data$HeatMapThresholds$Range3 = 2;
var $author$project$Data$HeatMapThresholds$Range4 = 3;
var $author$project$Data$HeatMapThresholds$rangeFor = F2(
	function (i, thresholds) {
		return (_Utils_cmp(i, thresholds.dw.aN) < 0) ? 4 : ((_Utils_cmp(i, thresholds.o.aN) < 1) ? 0 : ((_Utils_cmp(i, thresholds.p.aN) < 1) ? 1 : ((_Utils_cmp(i, thresholds.q.aN) < 1) ? 2 : ((_Utils_cmp(i, thresholds.dx.aN) < 1) ? 3 : 4))));
	});
var $elm$core$Basics$round = _Basics_round;
var $author$project$Data$Session$classByValue = F2(
	function (average, heatMapThresholds) {
		var _v0 = _Utils_Tuple2(average, heatMapThresholds);
		if ((!_v0.a.$) && (_v0.b.$ === 3)) {
			var avg = _v0.a.a;
			var thresholds = _v0.b.a;
			var _v1 = A2(
				$author$project$Data$HeatMapThresholds$rangeFor,
				$elm$core$Basics$round(avg),
				thresholds);
			switch (_v1) {
				case 0:
					return 'level1-bg';
				case 1:
					return 'level2-bg';
				case 2:
					return 'level3-bg';
				case 3:
					return 'level4-bg';
				default:
					return 'grey-bg';
			}
		} else {
			return 'grey-bg';
		}
	});
var $author$project$Data$Times$monthToString = function (month) {
	switch (month) {
		case 0:
			return '01';
		case 1:
			return '02';
		case 2:
			return '03';
		case 3:
			return '04';
		case 4:
			return '05';
		case 5:
			return '06';
		case 6:
			return '07';
		case 7:
			return '08';
		case 8:
			return '09';
		case 9:
			return '10';
		case 10:
			return '11';
		default:
			return '12';
	}
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.dl, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		bd: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		bD: month,
		cc: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bd;
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bD;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cc;
	});
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Data$Times$format = F2(
	function (start, end) {
		var toYear = A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				$elm$core$String$right(2),
				$elm$core$String$fromInt),
			$elm$time$Time$toYear($elm$time$Time$utc));
		var toMonth = A2(
			$elm$core$Basics$composeL,
			$author$project$Data$Times$monthToString,
			$elm$time$Time$toMonth($elm$time$Time$utc));
		var toMinute = A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$String$padLeft, 2, '0'),
				$elm$core$String$fromInt),
			$elm$time$Time$toMinute($elm$time$Time$utc));
		var toHour = A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$String$padLeft, 2, '0'),
				$elm$core$String$fromInt),
			$elm$time$Time$toHour($elm$time$Time$utc));
		var toTime = function (p) {
			return toHour(p) + (':' + toMinute(p));
		};
		var toDay = A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$String$padLeft, 2, '0'),
				$elm$core$String$fromInt),
			$elm$time$Time$toDay($elm$time$Time$utc));
		var toFullDate = function (p) {
			return toMonth(p) + ('/' + (toDay(p) + ('/' + (toYear(p) + (' ' + toTime(p))))));
		};
		var areOnSameDate = F2(
			function (p1, p2) {
				return _Utils_eq(
					toYear(p1),
					toYear(p2)) && (_Utils_eq(
					toMonth(p1),
					toMonth(p2)) && _Utils_eq(
					toDay(p1),
					toDay(p2)));
			});
		return _Utils_ap(
			toFullDate(start),
			A2(areOnSameDate, start, end) ? ('-' + toTime(end)) : (' - ' + toFullDate(end)));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Data$SelectedSession$view = F7(
	function (session, heatMapThresholds, linkIcon, toMsg, showExportPopup, popup, emailForm) {
		var tooltipId = 'graph-copy-link-tooltip';
		var measurements = A2($author$project$Data$SelectedSession$selectedMeasurements, session.bA, session.al);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('single-session__info')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('session-data')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('session-data__left')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('single-session__name')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(session.b8)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('single-session__username')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(session.cb)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('single-session__sensor')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(session.a_)
										]))
								])),
							function () {
							if (!measurements.b) {
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('single-session__placeholder')
										]),
									_List_Nil);
							} else {
								var min = A2(
									$elm$core$Maybe$withDefault,
									-1,
									$elm$core$List$minimum(measurements));
								var max = A2(
									$elm$core$Maybe$withDefault,
									-1,
									$elm$core$List$maximum(measurements));
								var average = $elm$core$List$sum(measurements) / $elm$core$List$length(measurements);
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('session-data__right')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('single-session__avg-color'),
															$elm$html$Html$Attributes$class(
															A2(
																$author$project$Data$Session$classByValue,
																$elm$core$Maybe$Just(average),
																heatMapThresholds))
														]),
													_List_Nil),
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('avg. ')
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('single-session__avg')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$elm$core$String$fromInt(
																$elm$core$Basics$round(average)))
														])),
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(' ' + session.a$)
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('session-numbers-container')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('session-min-max-container')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('single-session__color'),
																	$elm$html$Html$Attributes$class(
																	A2(
																		$author$project$Data$Session$classByValue,
																		$elm$core$Maybe$Just(min),
																		heatMapThresholds))
																]),
															_List_Nil),
															A2(
															$elm$html$Html$span,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('min. ')
																])),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('single-session__min')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$elm$core$String$fromInt(
																		$elm$core$Basics$round(min)))
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('session-min-max-container')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('single-session__color'),
																	$elm$html$Html$Attributes$class(
																	A2(
																		$author$project$Data$Session$classByValue,
																		$elm$core$Maybe$Just(max),
																		heatMapThresholds))
																]),
															_List_Nil),
															A2(
															$elm$html$Html$span,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('max. ')
																])),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('single-session__max')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$elm$core$String$fromInt(
																		$elm$core$Basics$round(max)))
																]))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('single-session__date')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													A2($author$project$Data$Times$format, session.a1, session.aR))
												]))
										]));
							}
						}()
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('action-buttons')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('button button--primary action-button action-button--export'),
									$author$project$Popup$clickWithoutDefault(showExportPopup)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('export session')
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('button button--primary action-button action-button--copy-link'),
									$elm$html$Html$Events$onClick(
									toMsg(tooltipId)),
									$elm$html$Html$Attributes$id(tooltipId)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											$author$project$Data$Path$toString(linkIcon)),
											$elm$html$Html$Attributes$alt('Link icon')
										]),
									_List_Nil)
								])),
							$author$project$Popup$isEmailFormPopupShown(popup) ? emailForm : $elm$html$Html$text('')
						]))
				]));
	});
var $author$project$Main$viewSelectedSession = F5(
	function (heatMapThresholds, maybeSession, linkIcon, popup, emailForm) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('single-session-container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('single-session__aside')
						]),
					function () {
						if (maybeSession.$ === 1) {
							return _List_fromArray(
								[
									$elm$html$Html$text('loading')
								]);
						} else {
							var session = maybeSession.a;
							return _List_fromArray(
								[
									A7($author$project$Data$SelectedSession$view, session, heatMapThresholds, linkIcon, $author$project$Main$ShowCopyLinkTooltip, $author$project$Main$ShowExportPopup, popup, emailForm)
								]);
						}
					}()),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('single-session__graph'),
							$elm$html$Html$Attributes$id('graph-box')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('graph')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('close-button single-session__close-button'),
							$elm$html$Html$Events$onClick($author$project$Main$DeselectSession)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('×')
						]))
				]));
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Main$LoadMoreSessions = {$: 20};
var $author$project$Main$viewLoadMore = F2(
	function (fetchableSessionsCount, sessionCount) {
		return (_Utils_cmp(sessionCount, fetchableSessionsCount) < 0) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('more-sessions-button-container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('more-sessions-button'),
							$elm$html$Html$Attributes$class('button button--primary action-button action-button--more-sessions'),
							$elm$html$Html$Events$onClick($author$project$Main$LoadMoreSessions)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('more sessions →')
						]))
				])) : $elm$html$Html$text('');
	});
var $author$project$Main$HighlightSessionMarker = function (a) {
	return {$: 38, a: a};
};
var $author$project$Main$SelectSession = function (a) {
	return {$: 26, a: a};
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Data$HeatMapThresholds$rangeIntFor = F2(
	function (maybeValue, webDataThresholds) {
		var _v0 = _Utils_Tuple2(maybeValue, webDataThresholds);
		if ((!_v0.a.$) && (_v0.b.$ === 3)) {
			var value = _v0.a.a;
			var thresholds = _v0.b.a;
			return (_Utils_cmp(value, thresholds.dw.aN) < 0) ? 0 : ((_Utils_cmp(value, thresholds.o.aN) < 1) ? 1 : ((_Utils_cmp(value, thresholds.p.aN) < 1) ? 2 : ((_Utils_cmp(value, thresholds.q.aN) < 1) ? 3 : ((_Utils_cmp(value, thresholds.dx.aN) < 1) ? 4 : 0))));
		} else {
			return 0;
		}
	});
var $author$project$Data$Markers$toSessionMarkerData = F4(
	function (location, streamId, maybeAverage, heatMapThresholds) {
		return {
			bm: A2(
				$author$project$Data$HeatMapThresholds$rangeIntFor,
				A2($elm$core$Maybe$map, $elm$core$Basics$round, maybeAverage),
				heatMapThresholds),
			bx: location,
			b2: streamId
		};
	});
var $author$project$Main$viewSessionCard = F2(
	function (heatMapThresholds, session) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('session-card'),
					$elm$html$Html$Attributes$class(
					A2($author$project$Data$Session$classByValue, session.cg, heatMapThresholds)),
					$elm$html$Html$Events$onClick(
					$author$project$Main$SelectSession(session.b2)),
					$elm$html$Html$Events$onMouseEnter(
					$author$project$Main$HighlightSessionMarker(
						$elm$core$Maybe$Just(
							A4($author$project$Data$Markers$toSessionMarkerData, session.bx, session.b2, session.cg, heatMapThresholds)))),
					$elm$html$Html$Events$onMouseLeave(
					$author$project$Main$HighlightSessionMarker($elm$core$Maybe$Nothing))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('session-card__color'),
							$elm$html$Html$Attributes$class(
							A2($author$project$Data$Session$classByValue, session.cg, heatMapThresholds))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$h3,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('session-card__name')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(session.b8)
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('session-card__owner')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(session.cb)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('session-card__dates')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Data$Times$format, session.a1, session.aR))
						]))
				]));
	});
var $author$project$Main$viewSessions = F3(
	function (fetchableSessionsCount, sessions, heatMapThresholds) {
		var sessionsCount = $elm$core$String$fromInt(
			$elm$core$List$length(sessions));
		return (!$elm$core$List$length(sessions)) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('session-list')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('session-list__header')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sessions')
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('session-list__number')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							'showing ' + (sessionsCount + (' of ' + ($elm$core$String$fromInt(fetchableSessionsCount) + ' results'))))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('session-cards-container'),
							$elm$html$Html$Attributes$id('session-cards-container')
						]),
					_Utils_ap(
						A2(
							$elm$core$List$map,
							$author$project$Main$viewSessionCard(heatMapThresholds),
							sessions),
						_List_fromArray(
							[
								A2(
								$author$project$Main$viewLoadMore,
								fetchableSessionsCount,
								$elm$core$List$length(sessions))
							])))
				]));
	});
var $author$project$Main$viewSessionsOrSelectedSession = F7(
	function (selectedSession, fetchableSessionsCount, sessions, heatMapThresholds, linkIcon, popup, emailForm) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sessions')
						]),
					_List_fromArray(
						[
							function () {
							switch (selectedSession.$) {
								case 0:
									return A3($author$project$Main$viewSessions, fetchableSessionsCount, sessions, heatMapThresholds);
								case 3:
									var session = selectedSession.a;
									return A5(
										$author$project$Main$viewSelectedSession,
										heatMapThresholds,
										$elm$core$Maybe$Just(session),
										linkIcon,
										popup,
										A4($author$project$Data$EmailForm$view, emailForm, $author$project$Main$ExportSessions, $author$project$Main$NoOp, $author$project$Main$UpdateEmailFormValue));
								case 1:
									return A5(
										$author$project$Main$viewSelectedSession,
										heatMapThresholds,
										$elm$core$Maybe$Nothing,
										linkIcon,
										popup,
										A4($author$project$Data$EmailForm$view, emailForm, $author$project$Main$ExportSessions, $author$project$Main$NoOp, $author$project$Main$UpdateEmailFormValue));
								default:
									return A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('error!')
											]));
							}
						}()
						]))
				]));
	});
var $author$project$Main$UpdateZoomLevel = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$viewZoomSlider = function (boundedInteger) {
	var updateOnClick = function (updateValue) {
		return $author$project$Main$UpdateZoomLevel(
			$author$project$Data$BoundedInteger$getValue(
				updateValue(boundedInteger)));
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('zoom-slider-container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('zoom__plus'),
						$elm$html$Html$Events$onClick(
						updateOnClick($author$project$Data$BoundedInteger$addOne))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('+')
					])),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('zoom-slider'),
						$author$project$Main$onChange(
						A2(
							$elm$core$Basics$composeR,
							$elm$core$String$toInt,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$withDefault(25),
								$author$project$Main$UpdateZoomLevel))),
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromInt(
							$author$project$Data$BoundedInteger$getValue(boundedInteger))),
						$elm$html$Html$Attributes$max(
						$elm$core$String$fromInt(
							$author$project$Data$BoundedInteger$getUpperBound(boundedInteger))),
						$elm$html$Html$Attributes$min(
						$elm$core$String$fromInt(
							$author$project$Data$BoundedInteger$getLowerBound(boundedInteger))),
						$elm$html$Html$Attributes$type_('range')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('zoom__minus'),
						$elm$html$Html$Events$onClick(
						updateOnClick($author$project$Data$BoundedInteger$subOne))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('-')
					]))
			]));
};
var $author$project$Main$viewMap = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('maps-content-container')
			]),
		_List_fromArray(
			[
				$author$project$Data$Overlay$view(model.d),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('map-container')
					]),
				_List_fromArray(
					[
						$author$project$Main$viewSearchAsIMove(model),
						$author$project$Main$viewZoomSlider(model.ac),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('map'),
								$elm$html$Html$Attributes$id('map11')
							]),
						_List_Nil),
						A8($elm$html$Html$Lazy$lazy7, $author$project$Main$viewSessionsOrSelectedSession, model.g, model.aB, model.am, model.s, model.K, model.v, model.u)
					])),
				A8(
				$elm$html$Html$Lazy$lazy7,
				$author$project$Main$viewHeatMap,
				model.s,
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($author$project$Sensor$unitForSensorId, model.i, model.n)),
				model.T,
				model._,
				model.aJ,
				model.E,
				model.g)
			]));
};
var $author$project$Main$viewMain = function (model) {
	return A2(
		$elm$html$Html$main_,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('maps-page-container', true),
								_Utils_Tuple2('with-filters-collapsed', !model.O)
							]))
					]),
				_List_fromArray(
					[
						$author$project$Main$viewFiltersForDesktop(model),
						$author$project$Main$viewMap(model)
					]))
			]));
};
var $author$project$Main$ToggleFiltersExpanded = {$: 46};
var $author$project$ExternalUrl$habitatMap = 'https://habitatmap.org';
var $author$project$ExternalUrl$aircasting = $author$project$ExternalUrl$habitatMap + '/aircasting';
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$enableBackground = _VirtualDom_attribute('enable-background');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $elm$svg$Svg$node = $elm$virtual_dom$VirtualDom$nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Svgs$habitatMapLogo = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$class('habitatmap-logo'),
			$elm$svg$Svg$Attributes$enableBackground('new 0 0 200 50'),
			$elm$svg$Svg$Attributes$height('50'),
			$elm$svg$Svg$Attributes$viewBox('0 0 200 50'),
			$elm$svg$Svg$Attributes$width('200')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_Nil,
			_List_fromArray(
				[
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m58.011 15.459v19.234h-3.627v-8.106h-8.078v8.106h-3.627v-19.234h3.627v7.859h8.078v-7.859z')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m69.358 28.73-3.078.33c-1.264.137-2.225.659-2.225 1.731 0 1.099.852 1.482 1.951 1.482 1.482 0 3.352-.714 3.352-2.885zm4.836 3.326v2.638h-1.484c-1.291 0-2.309-.385-2.775-1.648-.934 1.181-2.363 1.923-4.533 1.923-2.803 0-4.863-1.319-4.863-4.039 0-2.638 2.006-3.929 4.533-4.176l4.287-.44v-.576c0-1.512-.963-2.254-2.309-2.254-1.484 0-2.252.824-2.473 2.061h-3.517c.275-2.857 2.473-4.863 5.99-4.863 3.215 0 5.826 1.621 5.826 5.303v5.029c0 .714.33 1.044.988 1.044h.33z')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m86.612 27.797c0-2.418-1.4-4.067-3.488-4.067s-3.436 1.649-3.436 4.067c0 2.473 1.348 4.121 3.436 4.121s3.488-1.648 3.488-4.121m3.518 0c0 4.369-2.775 7.172-6.457 7.172-2.143 0-3.436-1.018-4.094-1.896h-.246l-.305 1.621h-2.855v-19.509h3.516v7.062c.742-.825 2.115-1.566 3.984-1.566 3.599 0 6.457 2.636 6.457 7.116')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m92.556 20.955h3.516v13.738h-3.516zm-.412-3.874c0-1.209 1.018-2.145 2.17-2.145 1.154 0 2.117.936 2.117 2.145 0 1.126-.963 2.115-2.117 2.115-1.153 0-2.17-.989-2.17-2.115')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m100.964 30.956v-7.226h-2.941v-2.776h.936c1.676 0 2.336-.604 2.336-2.088v-1.649h3.186v3.737h3.602v2.776h-3.602v6.759c0 .769.193 1.703 1.842 1.703.412 0 .934-.082 1.346-.192v2.666c-.604.137-1.428.303-2.416.303-3.85 0-4.289-2.502-4.289-4.013')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m118.247 28.73-3.078.33c-1.264.137-2.227.659-2.227 1.731 0 1.099.854 1.482 1.951 1.482 1.484 0 3.354-.714 3.354-2.885zm4.836 3.326v2.638h-1.484c-1.291 0-2.309-.385-2.775-1.648-.934 1.181-2.363 1.923-4.533 1.923-2.805 0-4.863-1.319-4.863-4.039 0-2.638 2.006-3.929 4.533-4.176l4.287-.44v-.576c0-1.512-.961-2.254-2.309-2.254-1.484 0-2.254.824-2.473 2.061h-3.518c.275-2.857 2.473-4.863 5.99-4.863 3.215 0 5.824 1.621 5.824 5.303v5.029c0 .714.33 1.044.99 1.044h.331z')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m126.337 30.956v-7.226h-2.939v-2.776h.934c1.676 0 2.336-.604 2.336-2.088v-1.649h3.188v3.737h3.6v2.776h-3.6v6.759c0 .769.193 1.703 1.84 1.703.412 0 .936-.082 1.348-.192v2.666c-.605.137-1.43.303-2.418.303-3.85 0-4.289-2.502-4.289-4.013')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m156.562 34.693h-3.545v-12.777h-.219l-4.781 10.579h-3.655l-4.808-10.579h-.219v12.777h-3.545v-19.234h4.451l5.852 13.189h.22l5.825-13.189h4.424z')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m167.909 28.73-3.076.33c-1.266.137-2.227.659-2.227 1.731 0 1.099.852 1.482 1.951 1.482 1.484 0 3.352-.714 3.352-2.885zm4.836 3.326v2.638h-1.484c-1.291 0-2.307-.385-2.775-1.648-.934 1.181-2.361 1.923-4.533 1.923-2.803 0-4.863-1.319-4.863-4.039 0-2.638 2.006-3.929 4.535-4.176l4.285-.44v-.576c0-1.512-.963-2.254-2.309-2.254-1.482 0-2.252.824-2.473 2.061h-3.518c.275-2.857 2.475-4.863 5.99-4.863 3.215 0 5.826 1.621 5.826 5.303v5.029c0 .714.33 1.044.988 1.044h.331z')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m185.165 27.852c0-2.473-1.402-4.122-3.49-4.122s-3.436 1.649-3.436 4.122c0 2.418 1.348 4.066 3.436 4.066s3.49-1.648 3.49-4.066m3.516 0c0 4.479-2.857 7.117-6.457 7.117-1.869 0-3.242-.742-3.984-1.567v6.787h-3.516v-19.235h2.857l.303 1.621h.246c.66-.879 1.951-1.896 4.094-1.896 3.681.001 6.457 2.803 6.457 7.173')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'path',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('m21.593 17.222c-2.57 0-4.008 1.027-5.035 2.363h-.275v-9.213h-4.833v24.317h4.832 3.965l-3.334-6.086h.004c-.41-.627-.652-1.377-.652-2.183 0-2.209 1.791-3.999 4-3.999s4 1.79 4 3.999c0 .806-.24 1.556-.652 2.183h.004l-3.332 6.086h3.604 4.383v-10.377c-.001-4.487-2.603-7.09-6.679-7.09')
						]),
					_List_Nil)
				]))
		]));
var $elm$html$Html$header = _VirtualDom_node('header');
var $author$project$Data$Page$toString = function (page) {
	if (!page) {
		return 'Fixed';
	} else {
		return 'Mobile';
	}
};
var $author$project$Main$viewNav = F6(
	function (navLogo, isNavExpanded, sensors, selectedSensorId, page, isIframe) {
		return A2(
			$elm$html$Html$header,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('menu-collapsed', !isNavExpanded)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('filters-info u--show-on-mobile'),
							$elm$html$Html$Events$onClick($author$project$Main$ToggleFiltersExpanded)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('filters-info__session-type')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Data$Page$toString(page)),
									$elm$html$Html$text(' sessions')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('header__logo')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('AirCasting Page'),
											$elm$html$Html$Attributes$href($author$project$ExternalUrl$aircasting),
											$elm$html$Html$Attributes$class('u--hide-on-mobile')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src(
													$author$project$Data$Path$toString(navLogo)),
													$elm$html$Html$Attributes$alt('Aircasting Logo')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('Homepage'),
											$elm$html$Html$Attributes$href($author$project$ExternalUrl$habitatMap),
											$elm$html$Html$Attributes$class('u--show-on-mobile')
										]),
									_List_fromArray(
										[$author$project$Svgs$habitatMapLogo]))
								]))
						])),
					isIframe ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('logo')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('Homepage'),
									$elm$html$Html$Attributes$href('https://kidsmakingsense.org')
								]),
							_List_Nil)
						])) : A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('logo')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$fapian$elm_html_aria$Html$Attributes$Aria$ariaLabel('Homepage'),
									$elm$html$Html$Attributes$href('https://kidsmakingsense.org')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											$author$project$Data$Path$toString(navLogo)),
											$elm$html$Html$Attributes$alt('Kids Making Sense Logo')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Main$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('elm-app'),
				$elm$html$Html$Attributes$class(
				$author$project$Data$Theme$toString(model.E))
			]),
		_List_fromArray(
			[
				A7($elm$html$Html$Lazy$lazy6, $author$project$Main$viewNav, model.Y, model.aD, model.n, model.i, model.m, model.V),
				$author$project$Main$viewMain(model)
			]));
};
var $author$project$Main$viewDocument = function (model) {
	return {
		ci: _List_fromArray(
			[
				$author$project$Main$snippetGoogleTagManager,
				$author$project$Main$view(model)
			]),
		b8: 'Kids Making Sense'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{cE: $author$project$Main$init, c4: $author$project$Main$UrlChange, c5: $author$project$Main$UrlRequest, ds: $author$project$Main$subscriptions, dD: $author$project$Main$update, dF: $author$project$Main$viewDocument});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (timeRange) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (themeSwitchIconDefault) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (themeSwitchIconBlue) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (theme) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (tags) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (sensors) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (selectedStreamId) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (selectedSensorId) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (scrollPosition) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (resetIconWhite) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (resetIconBlack) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (profiles) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (navLogo) {
																											return A2(
																												$elm$json$Json$Decode$andThen,
																												function (location) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (linkIcon) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (keepFiltersExpanded) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (isSearchAsIMoveOn) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (isIndoor) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (isIframe) {
																																							return A2(
																																								$elm$json$Json$Decode$andThen,
																																								function (isCrowdMapOn) {
																																									return A2(
																																										$elm$json$Json$Decode$andThen,
																																										function (isActive) {
																																											return A2(
																																												$elm$json$Json$Decode$andThen,
																																												function (heatMapThresholdValues) {
																																													return A2(
																																														$elm$json$Json$Decode$andThen,
																																														function (fitScaleIcon) {
																																															return A2(
																																																$elm$json$Json$Decode$andThen,
																																																function (crowdMapResolution) {
																																																	return $elm$json$Json$Decode$succeed(
																																																		{y: crowdMapResolution, T: fitScaleIcon, aT: heatMapThresholdValues, aU: isActive, I: isCrowdMapOn, V: isIframe, br: isIndoor, G: isSearchAsIMoveOn, aV: keepFiltersExpanded, K: linkIcon, bx: location, Y: navLogo, F: profiles, _: resetIconBlack, M: resetIconWhite, R: scrollPosition, i: selectedSensorId, aI: selectedStreamId, n: sensors, H: tags, E: theme, a3: themeSwitchIconBlue, a4: themeSwitchIconDefault, N: timeRange});
																																																},
																																																A2($elm$json$Json$Decode$field, 'crowdMapResolution', $elm$json$Json$Decode$int));
																																														},
																																														A2($elm$json$Json$Decode$field, 'fitScaleIcon', $elm$json$Json$Decode$string));
																																												},
																																												A2(
																																													$elm$json$Json$Decode$field,
																																													'heatMapThresholdValues',
																																													$elm$json$Json$Decode$oneOf(
																																														_List_fromArray(
																																															[
																																																$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																																																A2(
																																																$elm$json$Json$Decode$map,
																																																$elm$core$Maybe$Just,
																																																A2(
																																																	$elm$json$Json$Decode$andThen,
																																																	function (threshold5) {
																																																		return A2(
																																																			$elm$json$Json$Decode$andThen,
																																																			function (threshold4) {
																																																				return A2(
																																																					$elm$json$Json$Decode$andThen,
																																																					function (threshold3) {
																																																						return A2(
																																																							$elm$json$Json$Decode$andThen,
																																																							function (threshold2) {
																																																								return A2(
																																																									$elm$json$Json$Decode$andThen,
																																																									function (threshold1) {
																																																										return $elm$json$Json$Decode$succeed(
																																																											{dw: threshold1, o: threshold2, p: threshold3, q: threshold4, dx: threshold5});
																																																									},
																																																									A2($elm$json$Json$Decode$field, 'threshold1', $elm$json$Json$Decode$int));
																																																							},
																																																							A2($elm$json$Json$Decode$field, 'threshold2', $elm$json$Json$Decode$int));
																																																					},
																																																					A2($elm$json$Json$Decode$field, 'threshold3', $elm$json$Json$Decode$int));
																																																			},
																																																			A2($elm$json$Json$Decode$field, 'threshold4', $elm$json$Json$Decode$int));
																																																	},
																																																	A2($elm$json$Json$Decode$field, 'threshold5', $elm$json$Json$Decode$int)))
																																															]))));
																																										},
																																										A2($elm$json$Json$Decode$field, 'isActive', $elm$json$Json$Decode$bool));
																																								},
																																								A2($elm$json$Json$Decode$field, 'isCrowdMapOn', $elm$json$Json$Decode$bool));
																																						},
																																						A2($elm$json$Json$Decode$field, 'isIframe', $elm$json$Json$Decode$bool));
																																				},
																																				A2($elm$json$Json$Decode$field, 'isIndoor', $elm$json$Json$Decode$bool));
																																		},
																																		A2($elm$json$Json$Decode$field, 'isSearchAsIMoveOn', $elm$json$Json$Decode$bool));
																																},
																																A2($elm$json$Json$Decode$field, 'keepFiltersExpanded', $elm$json$Json$Decode$bool));
																														},
																														A2($elm$json$Json$Decode$field, 'linkIcon', $elm$json$Json$Decode$string));
																												},
																												A2($elm$json$Json$Decode$field, 'location', $elm$json$Json$Decode$string));
																										},
																										A2($elm$json$Json$Decode$field, 'navLogo', $elm$json$Json$Decode$string));
																								},
																								A2(
																									$elm$json$Json$Decode$field,
																									'profiles',
																									$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
																						},
																						A2($elm$json$Json$Decode$field, 'resetIconBlack', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'resetIconWhite', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'scrollPosition', $elm$json$Json$Decode$float));
																},
																A2($elm$json$Json$Decode$field, 'selectedSensorId', $elm$json$Json$Decode$string));
														},
														A2(
															$elm$json$Json$Decode$field,
															'selectedStreamId',
															$elm$json$Json$Decode$oneOf(
																_List_fromArray(
																	[
																		$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int)
																	]))));
												},
												A2($elm$json$Json$Decode$field, 'sensors', $elm$json$Json$Decode$value));
										},
										A2(
											$elm$json$Json$Decode$field,
											'tags',
											$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
								},
								A2($elm$json$Json$Decode$field, 'theme', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'themeSwitchIconBlue', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'themeSwitchIconDefault', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'timeRange', $elm$json$Json$Decode$value)))(0)}});}(this));